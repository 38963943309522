import styled from 'styled-components';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

interface IFlexColumnProps {
  width?: string;
  filingStatus: 'TRACKED' | 'REJECTED' | 'RAW';
  isHidden?: boolean;
  padding?: string;
}

const getBackColor = (props: IFlexColumnProps) => {
  if (props.filingStatus === 'TRACKED') return '#F7FFED';

  if (props.filingStatus === 'REJECTED') return '#F3F3F3';

  return '#FFFFFF';
};

export const StyledFlexColumn = styled.div<IFlexColumnProps>`
  position: relative;
  box-sizing: border-box;
  flex: ${(props: IFlexColumnProps) => props.width ? `1 1 ${props.width}` : '1 1 100%'};
  background-color: ${(props: IFlexColumnProps) => getBackColor(props)};
  display: ${(props: IFlexColumnProps) => props.isHidden ? 'none' : 'flex'};
  align-items: center;
  position: relative;
  border-right: 1px solid #D7D7D7;
  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
  padding: ${(props: IFlexColumnProps) => props.padding ? props.padding : '20px 10px 20px 30px'};

  &:last-child {
    border-right: none;    
  }
`;

export const StyledLinksFlexColumn = styled.div<IFlexColumnProps>`  
  box-sizing: border-box;
  flex: ${(props: IFlexColumnProps) => props.width ? `1 1 ${props.width}` : '1 1 100%'};
  background-color: ${(props: IFlexColumnProps) => getBackColor(props)};
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;  
  border-right: 1px solid #D7D7D7;
  border-top: 1px solid #D7D7D7;
  border-bottom: 1px solid #D7D7D7;
  padding: 20px 10px 20px 10px;  

  &:last-child {
    border-right: none;    
  }
`;

export const StyledLink = styled.a`
  padding: 0;  
  margin: 0;
  margin-right: 5px;
  color: #8DC63F;
`;

interface IRowTitleProps {
  textAlign?: string;
  textColor? : string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  isALink?: boolean;
}

export const StyledRowTitle = styled.h4<IRowTitleProps>`

  padding: 0;
  margin: 0;
  width: 100%;  
  text-align: ${(props: IRowTitleProps) => props.textAlign ? props.textAlign : 'center'};
  color: ${(props: IRowTitleProps) => props.textColor ? props.textColor : '#304F5A'};
  font-size: ${(props: IRowTitleProps) => props.fontSize ? props.fontSize : '14px'};
  font-weight: ${(props: IRowTitleProps) => props.fontWeight ? props.fontWeight : 'bold'};
  font-family: ${(props: IRowTitleProps) => props.fontFamily ? props.fontFamily : 'arial'};
  text-decoration: ${(props: IRowTitleProps) => props.isALink ? 'underline' : 'none'};
  line-height: 16px;  
`;

interface IExpandableAreaProps {
  $isOpen: boolean;
}

export const StyledArrowIcon = styled(PlayArrowIcon)<IExpandableAreaProps>`

  && {      
      color: black;      
      transform: ${(props:IExpandableAreaProps) => props.$isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'};
      font-size: 16px;
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -8px;
    }  
    
    &:hover {
      cursor: pointer;
    }

`;

export const StyledKeywordsRowTitle = styled.h4<IRowTitleProps>`

  padding: 0;
  margin: 0;
  width: 100%;  
  text-align: ${(props: IRowTitleProps) => props.textAlign ? props.textAlign : 'center'};
  color: ${(props: IRowTitleProps) => props.textColor ? props.textColor : '#304F5A'};
  font-size: ${(props: IRowTitleProps) => props.fontSize ? props.fontSize : '14px'};
  font-weight: ${(props: IRowTitleProps) => props.fontWeight ? props.fontWeight : 'bold'};
  font-family: ${(props: IRowTitleProps) => props.fontFamily ? props.fontFamily : 'arial'};
  text-decoration: ${(props: IRowTitleProps) => props.isALink ? 'underline' : 'none'};
  line-height: 16px;  
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  
`;
