import React from 'react';

import {
  StyledContainer,
  StyledButtonContainer,
  StyledAddButton,
  StyledAddIcon,
} from './style';

const CourtCaseFooter: React.FC = () => {
  return (
    <StyledContainer>
      <StyledButtonContainer>
        <StyledAddButton disabled>
          <StyledAddIcon />
          ADD AS A PROSPECT
        </StyledAddButton>
      </StyledButtonContainer>
    </StyledContainer>
  );
};

export default CourtCaseFooter;
