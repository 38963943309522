import styled from 'styled-components';

export const StyledContainer = styled.ul`

  padding: 0;
  margin: 0;
  list-style-type: none;
  width: 100%;
  background-color: #E5E5E5;
`;
