import React, { useState, useContext } from 'react';

import {
  StyledContainer,
  StyledToolbar,
  StyledButtonContainer,
  StyledTrackedCasesIcon,
  StyledActivityIcon,
  StyledArrowDown,
} from './style';

import ArrowDown from '../../../../assets/svg/ArrowDown.svg';

import { FilingContext, FilingContextType } from '../../../../context/filingContext';

import TrackedCases from './trackedCases';

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

enum EnumContentToShow { ACTIVITY, TRACKED_CASES }

const ProspectContent: React.FC<IProps> = (props: IProps) => {
  const filingState = useContext<FilingContextType>(FilingContext);

  const [contentToShow, setContentToShow] = useState<EnumContentToShow>(EnumContentToShow.ACTIVITY);

  const handleActivityButtonClick = () => {
    props.setIsOpen(true);

    setContentToShow(EnumContentToShow.ACTIVITY);
  };

  const handleTrackedCasesButtonClick = () => {
    props.setIsOpen(true);

    setContentToShow(EnumContentToShow.TRACKED_CASES);
  };
  const isActivityButtonSelected = props.isOpen && contentToShow === EnumContentToShow.ACTIVITY;

  const isTrackedCasesButtonSelected = props.isOpen && contentToShow === EnumContentToShow.TRACKED_CASES;

  return (
    <StyledContainer>
      <StyledToolbar $isOpen={props.isOpen}>
        <StyledButtonContainer
          $isOpen={props.isOpen}
          disabled={false}
          $selected={isActivityButtonSelected}
          onClick={handleActivityButtonClick}
        >
          <StyledActivityIcon
            disabled={false}
            $selected={isActivityButtonSelected}
          />
          <StyledArrowDown src={ArrowDown} $visible={isActivityButtonSelected} />
        </StyledButtonContainer>
        <StyledButtonContainer
          $isOpen={props.isOpen}
          $selected={isTrackedCasesButtonSelected}
          onClick={handleTrackedCasesButtonClick}
        >
          <StyledTrackedCasesIcon $selected={isTrackedCasesButtonSelected} />
          <StyledArrowDown src={ArrowDown} $visible={isTrackedCasesButtonSelected} />
        </StyledButtonContainer>
      </StyledToolbar>
      { isTrackedCasesButtonSelected
       && (
       <TrackedCases
         isOpen={props.isOpen}
         companyFeeds={filingState.companyFeeds}
         filingStatuses={filingState.filingStatuses}
         isLoading={filingState.isLoadingTrackedFilings}
         selectedTrackedCase={filingState.selectedTrackedCase}
         setSelectedTrackedCase={filingState.setSelectedTrackedCase}
       />
       ) }
    </StyledContainer>
  );
};

export default ProspectContent;
