import React from 'react';
import FeaturedList from '../../../../../../components/featuredList';

import {
  StyledFirmSection,
  StyledSectionTitle,
} from '../style';

interface IProps {
  firm: string[];
}

const SectionFirm: React.FC<IProps> = (props: IProps) => {
  const firmsWithIds = props.firm.map((item: string) => {
    return {
      _id: item,
      name: item,
    };
  });

  return (
    <StyledFirmSection>
      <StyledSectionTitle>Firm</StyledSectionTitle>
      <FeaturedList
        items={firmsWithIds}
        handleDelete={() => {}}
        shouldHideIcons
      />
    </StyledFirmSection>
  );
};

export default SectionFirm;
