import React from 'react';
import { StyledIcon } from './style';
import LogoGrid from '../../../../assets/svg/LogoGrid.svg';

function HomeIcon() {
  return (
    <StyledIcon src={LogoGrid} />
  );
}

export default HomeIcon;
