import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 97%;
  margin: 0 auto;
  height: 100%;  
`;

export const StyledCourtCaseInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  display: grid;
  grid-template-areas: "header header" "body body" "footer footer";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.1fr 1.5fr 0.2fr;
  gap: 0 0;
  background-color: #E5E5E5;  
`;
