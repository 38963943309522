import React from 'react';
import ExpertRow from '../../expertRow';
import {
  StyledContainer,
  StyledCheckCircleIcon,
} from './style';

interface IProps {
  items: ExpertType[];
  checkedList: string[];
  setCheckedList: (list: string[]) => void;
  selectedExpertIds: string[];
}

function ExpertList(props: IProps) {
  const handleExpertSelected = (expertId: string) => {
    if (props.selectedExpertIds.includes(expertId)) return;
    if (!props.checkedList.includes(expertId)) {
      props.setCheckedList(props.checkedList.concat(expertId));
      return;
    }
    props.setCheckedList(props.checkedList.filter((c) => c !== expertId));
  };

  return (
    <StyledContainer>
      {props.items.map((expertRow: ExpertType) => (
        <ExpertRow
          key={expertRow._id}
          item={expertRow}
          view="modal"
          handleExpertSelected={handleExpertSelected}
          isDisabled={props.selectedExpertIds.includes(expertRow._id)}
          leftIcon={(
            <StyledCheckCircleIcon
              disabled={props.selectedExpertIds.includes(expertRow._id)}
              color={props.checkedList.includes(expertRow._id)
                || props.selectedExpertIds.includes(expertRow._id) ? 'primary' : 'secondary'}
            />
          )}
        />
      ))}
    </StyledContainer>
  );
}

export default ExpertList;
