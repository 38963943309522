import { gql } from '@apollo/client';

export const GET_ALL_EXPERT_SEARCH_BY_PROJECT = gql`
  query GetAllExpertSearchByProject($projectId: ID!) {
    expertSearch_getAllByProject(projectId: $projectId) {
      _id
      projectId
      expertiseIds
      idealExpertIds
      description
      position
      name
    }
  }
`;

export const GET_ALL_EXPERT_SEARCH = gql`
  query GetAllExpertSearch {
    expertSearch_getAll {
      _id
      projectId
      expertiseIds
      idealExpertIds
      description
      position
      name
    }
  }
`;
