import styled from 'styled-components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: start;
  align-items: center;
  height: 45px;
`;

export const StyledTagContainer = styled.div`
  align-self: end;
  margin-top: 4px;
  max-height: 78px;
  overflow-y: auto;
  display: flex;
  flex-flow: wrap;
  max-width: 35vw;
`;

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  && {
    color: #757575;
    margin-top: 2px;
  }
`;

export const StyledLabel = styled.h3`
    font-weight: bold;
    margin: 0px 14px 0px 0px;
`;

export const searchExpertiseStyles: ISearchExpertiseStyles = {
  input: {
    maxWidth: '250px',
    margin: '0px 0px 0px 0px',
  },
  listContainer: {
    width: '100%',
    maxHeight: '200px',
    backgroundColor: '#FFFFFF',
    borderRadius: '0',
    boxShadow: 'none',
    fontFamily: 'Cairo, Verdana, Geneva, Tahoma, sans-serif',
    fontSize: 12,
    border: '2px solid #8DC63F',
  },
  listItems: {
    padding: '8px 0 8px 20px',
    margin: '0 2% 0 2%',
    textColor: '#000000',
    hoverBackgroundColor: '#304F5A',
    hoverTextColor: '#FFFFFF',
    firstChildPaddingTop: '10px',
    firstChildPaddingBottom: '10px',
    borderBottom: '1px solid #F0F0F0',
    width: '90%',
  },
};

interface ISearchExpertiseStyles {
  input: {
    maxWidth?: string;
    margin?: string;
    searchIconSize?: string;
    closeIconSize?: string;
  };
  listContainer: {
    width?: string;
    maxHeight?: string;
    backgroundColor?: string;
    borderRadius?: string;
    boxShadow?: string;
    fontFamily?: string;
    fontSize?: number;
    border?: string;
  };
  listItems: {
    padding?: string;
    margin?: string;
    textColor?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    hoverTextColor?: string;
    firstChildPaddingTop?: string;
    firstChildPaddingBottom?: string;
    borderBottom?: string;
    width?: string;
  };
}
