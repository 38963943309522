import * as ENV_CONFIG from './env';
import * as SENTRY_CONFIG from './sentry';

export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_EXPERT_GRID_API_AUDIENCE = process.env.REACT_APP_AUTH0_EXPERT_GRID_API_AUDIENCE;

const getApiUrl = () => {
  if (!!process.env.REACT_APP_REVIEW_ID && process.env.REACT_APP_REVIEW_ID !== 'undefined') {
    return `https://grid-api-review-pr-${process.env.REACT_APP_REVIEW_ID}.herokuapp.com`;
  }

  if (!process.env.REACT_APP_API_URI) return 'http://localhost:7200';

  return process.env.REACT_APP_API_URI;
};

export const API_URL = getApiUrl();

export const ENV = ENV_CONFIG;

export const SENTRY = SENTRY_CONFIG;
