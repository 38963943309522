import React from 'react';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useAuth0 } from '../../react-auth0-spa';
import LoginPage from '../../views/login';
import Experts from '../../views/experts';
import Prospects from '../../views/prospects';
import Home from '../../views/home';
import ProjectView from '../../views/project';
import ExpertView from '../../views/expert';

const ProtectedRoute = ({ children }:any) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: '/login' }}
      />
    );
  }

  return children;
};

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    </Route>
    <Route exact path="/experts">
      <ProtectedRoute>
        <Experts />
      </ProtectedRoute>
    </Route>
    <Route exact path="/projects/:selectedProjectId">
      <ProtectedRoute>
        <ProjectView />
      </ProtectedRoute>
    </Route>
    <Route exact path="/projects/:selectedProjectId/searches/:selectedSearchId">
      <ProtectedRoute>
        <ProjectView />
      </ProtectedRoute>
    </Route>
    <Route exact path="/experts/:expert_id">
      <ProtectedRoute>
        <ExpertView />
      </ProtectedRoute>
    </Route>
    <Route exact path="/prospector">
      <ProtectedRoute>
        <Prospects />
      </ProtectedRoute>
    </Route>
    <Route>
      <LoginPage />
    </Route>
  </Switch>
);

export default Routes;
