import React, { useState } from 'react';

export interface FilingContextType {
  companyFeeds: SecCompanyFeedType[];
  setCompanyFeeds: (feeds: SecCompanyFeedType[]) => void;
  filingStatuses: FilingStatusType[];
  setFilingStatuses: (statuses: FilingStatusType[]) => void;
  isLoadingTrackedFilings: boolean;
  setIsLoadingTrackedFilings: (value: boolean) => void;
  selectedTrackedCase: FilingStatusType | null;
  setSelectedTrackedCase: (filingStatus: FilingStatusType | null) => void;
}

export const FilingContext = React.createContext<FilingContextType>({
  companyFeeds: [],
  setCompanyFeeds: () => null,
  filingStatuses: [],
  setFilingStatuses: () => null,
  isLoadingTrackedFilings: false,
  setIsLoadingTrackedFilings: () => false,
  selectedTrackedCase: null,
  setSelectedTrackedCase: () => null,
});

interface IProps {
  children: React.ReactNode;
}

const FilingContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [companyFeeds, setCompanyFeeds] = useState<SecCompanyFeedType[]>([]);
  const [filingStatuses, setFilingStatuses] = useState<FilingStatusType[]>([]);
  const [isLoadingTrackedFilings, setIsLoadingTrackedFilings] = useState<boolean>(false);
  const [selectedTrackedCase, setSelectedTrackedCase] = useState<FilingStatusType | null>(null);

  const contextValue = {
    companyFeeds,
    setCompanyFeeds,
    filingStatuses,
    setFilingStatuses,
    isLoadingTrackedFilings,
    setIsLoadingTrackedFilings,
    selectedTrackedCase,
    setSelectedTrackedCase,
  };

  return (
    <FilingContext.Provider value={contextValue}>
      {children}
    </FilingContext.Provider>
  );
};

export default FilingContextProvider;
