import _ from 'lodash';
import React, { useContext, useState, useEffect } from 'react';
import Fuse from 'fuse.js';

import {
  StyledContainer,
  StyledResultsCount,
} from './style';

import SearchBar from './searchBar';

import { SidebarsContext, SidebarsContextType } from '../../../context/sidebarsContext';
import * as secCompanyFeedsUtils from '../../../entities/secCompanyFeed/utils';

import SecFilingsTable from './secFilingsTable';

interface IProps {
  secCompanyFeeds: SecCompanyFeedType[];
  filingStatuses: FilingStatusType[];
  setFilingStatuses: (statuses: FilingStatusType[]) => void;
  selectedTrackedCase: FilingStatusType | null;
  fetchSecCompanyFeeds: (daysBefore: number) => Promise<void>;
  isLoading: boolean;
}

enum EnumPeriodOption { LAST24H, LAST7D, LAST14D, LAST30D, LAST90D }

enum EnumStatusOption { TRACKED, REJECTED, NONE, ALL }

enum EnumFilingTypeOption { PRIMARY, EXHIBIT, ALL }

const periodToDayNumberMap = new Map([
  [EnumPeriodOption.LAST7D, 7],
  [EnumPeriodOption.LAST14D, 14],
  [EnumPeriodOption.LAST24H, 1],
  [EnumPeriodOption.LAST30D, 30],
  [EnumPeriodOption.LAST90D, 90],
]);

const SecFilings: React.FC<IProps> = (props: IProps) => {
  const [filterByCompanyName, setFilterByCompanyName] = useState<string>('');
  const [selectedPeriod, setSelectedPeriod] = useState<EnumPeriodOption>(EnumPeriodOption.LAST30D);
  const [selectedStatus, setSelectedStatus] = useState<EnumStatusOption>(EnumStatusOption.ALL);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [selectedFilingType, setSelectedFilingType] = useState<EnumFilingTypeOption>(EnumFilingTypeOption.ALL);

  const sidebarsState = useContext<SidebarsContextType>(SidebarsContext);

  useEffect(() => {
    if (props.secCompanyFeeds.length > 0) setSelectedKeywords(getKeywordsList());
  }, [props.secCompanyFeeds]);

  useEffect(() => {
    props.fetchSecCompanyFeeds(periodToDayNumberMap.get(EnumPeriodOption.LAST30D)!);
  }, []);

  const onChangeSelectedPeriod = async (newSelectedPeriod: EnumPeriodOption) => {
    setSelectedPeriod(newSelectedPeriod);
    await props.fetchSecCompanyFeeds(periodToDayNumberMap.get(newSelectedPeriod)!);
  };

  const filingsList = secCompanyFeedsUtils.getFilingsFromSecCompanyFeeds(props.secCompanyFeeds);

  const compareRows = (a: DisplayFilingType, b: DisplayFilingType) => {
    if (a.company_display_name < b.company_display_name) return -1;
    if (a.company_display_name > b.company_display_name) return 1;
    return 0;
  };

  const getSelectedTrackedCase = () => {
    const selectedFiling = filingsList.find((item: DisplayFilingType) => {
      return (item.companyId === props.selectedTrackedCase?.companyId)
      && (item.filingId === props.selectedTrackedCase?.filingId);
    });
    return selectedFiling as DisplayFilingType;
  };

  const filterFilingsByStatus = (filings: DisplayFilingType[], status: 'TRACKED' | 'REJECTED' | 'RAW') => {
    const filteredStatuses = props.filingStatuses.filter((item: FilingStatusType) => {
      return item.status === status;
    });

    const filteredFilings = filings.filter((item: DisplayFilingType) => {
      return filteredStatuses.some((item2: FilingStatusType) => {
        return item2.companyId === item.companyId && item2.filingId === item.filingId;
      });
    });

    return filteredFilings;
  };

  const getKeywordsFromFiling = (filing: DisplayFilingType) => {
    const keywords = filing.filingDisplayMatchingKeywords.split(' + ');

    return keywords ? keywords : [];
  };

  const filterFilingsByKeywords = (filings: DisplayFilingType[], keywords: string[]) => {
    const filteredFilings = filings.filter((item: DisplayFilingType) => {
      return getKeywordsFromFiling(item).some((item2: string) => {
        return keywords.some((item3: string) => item3.toLowerCase() === item2.toLowerCase());
      });
    });

    return filteredFilings;
  };

  const filterFilingsByType = (filings: DisplayFilingType[], filingType: 'primary' | 'exhibit' | 'all') => {
    if (filingType === 'all') return filings;
    const filteredTypes = filings.filter((item: DisplayFilingType) => {
      return item.filingType === filingType;
    });
    return filteredTypes;
  };

  const getFilteredByStatus = (filings: DisplayFilingType[], status: EnumStatusOption) => {
    if (status === EnumStatusOption.ALL) return filingsList;

    if (status === EnumStatusOption.TRACKED) {
      return filterFilingsByStatus(filings, 'TRACKED');
    }
    if (selectedStatus === EnumStatusOption.REJECTED) {
      return filterFilingsByStatus(filings, 'REJECTED');
    }
    if (selectedStatus === EnumStatusOption.NONE) {
      return filterFilingsByStatus(filings, 'RAW');
    }

    return [...filingsList];
  };

  const filingTypeMapToString = new Map([
    [EnumFilingTypeOption.ALL, 'all'],
    [EnumFilingTypeOption.PRIMARY, 'primary'],
    [EnumFilingTypeOption.EXHIBIT, 'exhibit'],
  ]);

  const getFilteredByType = (filings: DisplayFilingType[], filingType: EnumFilingTypeOption) => {
    return filterFilingsByType(
      filings, filingTypeMapToString.get(filingType as EnumFilingTypeOption) as 'primary' | 'exhibit' | 'all',
    );
  };

  const getFilteredFilings = () => {
    if (props.selectedTrackedCase) {
      return [getSelectedTrackedCase()];
    }

    const filteredFilingsByStatus = getFilteredByStatus(filingsList, selectedStatus);

    const filteredFilingsByKeywords = filterFilingsByKeywords(filteredFilingsByStatus, selectedKeywords);

    const filteredFilingsByType = getFilteredByType(filteredFilingsByKeywords, selectedFilingType);

    const filteredFilingsGlobally = filterFilingsGlobally(filteredFilingsByType);
    return [...filteredFilingsGlobally].sort(compareRows);
  };

  const getKeywordsList = () => {
    const keywordsList: string[] = _.flatMap(filingsList, (filing: DisplayFilingType) => {
      return getKeywordsFromFiling(filing);
    });
    return _.uniq(keywordsList);
  };

  const filterFilingsGlobally = (filings: DisplayFilingType[]) => {
    if (!filterByCompanyName) return filings;

    const fuseOptions = {
      keys: [
        'company_display_name',
        'tickers',
        'filingUrl',
        'filingDisplayMatchingKeywords',
      ],
      includeScore: true,
      shouldSort: true,
      threshold: 0,
    };
    const fuse = new Fuse(filings, fuseOptions);

    const fuseResults = fuse.search(filterByCompanyName);

    const filingResults = fuseResults.map((fuseResult: any) => {
      return fuseResult.item;
    });

    return filingResults;
  };

  const filteredFilings = getFilteredFilings();
  return (
    <StyledContainer>
      <SearchBar
        areBothSidebarsOpen={sidebarsState.isLeftSidebarOpen && sidebarsState.isRightSidebarOpen}
        filterByCompanyName={filterByCompanyName}
        setFilterByCompanyName={setFilterByCompanyName}
        selectedPeriod={selectedPeriod}
        onChangeSelectedPeriod={onChangeSelectedPeriod}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        selectedKeywords={selectedKeywords}
        setSelectedKeywords={setSelectedKeywords}
        selectedFilingType={selectedFilingType}
        setSelectedFilingType={setSelectedFilingType}
        selectedTrackedCase={props.selectedTrackedCase}
        secCompanyFeeds={props.secCompanyFeeds}
        filingStatuses={props.filingStatuses}
        keywordsList={getKeywordsList()}
      />
      <StyledResultsCount
        data-testid="prospects-secFilings-resultsCount"
      >
        {`Results Found: ${props.isLoading ? '-' : filteredFilings.length}`}
      </StyledResultsCount>
      <SecFilingsTable
        filteredFilings={filteredFilings}
        filingStatuses={props.filingStatuses}
        setFilingStatuses={props.setFilingStatuses}
        selectedTrackedCase={props.selectedTrackedCase}
        isLoading={props.isLoading}
      />
    </StyledContainer>
  );
};

export default SecFilings;
