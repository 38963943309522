import React, { useState } from 'react';

export interface SidebarsContextType {
  isLeftSidebarOpen: boolean;
  isRightSidebarOpen: boolean;
  setIsOpenLeftSidebar: (value: boolean) => void;
  setIsOpenRightSidebar: (value: boolean) => void;
}

export const SidebarsContext = React.createContext<SidebarsContextType>({
  isLeftSidebarOpen: false,
  isRightSidebarOpen: false,
  setIsOpenLeftSidebar: (value: boolean) => value,
  setIsOpenRightSidebar: (value: boolean) => value,
});

interface IProps {
  children: React.ReactNode
}

const SidebarsContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [isLeftSidebarOpen, setIsLeftSidebarOpen] = useState<boolean>(true);

  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState<boolean>(false);

  const setIsOpenLeftSidebar = (value: boolean) => {
    return setIsLeftSidebarOpen(value);
  };

  const setIsOpenRightSidebar = (value: boolean) => {
    return setIsRightSidebarOpen(value);
  };

  const contextValue = {
    isLeftSidebarOpen,
    isRightSidebarOpen,
    setIsOpenLeftSidebar,
    setIsOpenRightSidebar,
  };

  return (
    <SidebarsContext.Provider value={contextValue}>
      {children}
    </SidebarsContext.Provider>
  );
};

export default SidebarsContextProvider;
