import { gql } from '@apollo/client';

export const UPDATE_PROJECT = gql`
  mutation project_edit($input: ProjectAbout!) {
    project_edit(input: $input) {
      _id
      name
      billable
      projectCode
      StageName
      CloseDate
      client
      Matter_Name
      Budget__c
      Client_Sponsor__Name
      Law_Firm_Name
      Industry__c
      Practice_Order__c
      Affiliated_Expert__c
      Project_Type__c
      about
    }
  }
`;
