import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  backgroundColor?: string;
  color?: string;
  border?: string;
  padding?: string;
  margin?: string;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: string;
  boxShadow?: string;
  borderRadius?: number;
}

export const useStyles = makeStyles({
  root: {
    backgroundColor: (props: IProps) => props.backgroundColor ? props.backgroundColor : 'blue',
    borderRadius: (props: IProps) => props.borderRadius ? props.borderRadius : 0,
    color: (props: IProps) => props.color ? props.color : 'white',
    margin: (props: IProps) => props.margin ? props.margin : '0',
    padding: (props: IProps) => props.padding ? props.padding : '0',
    fontSize: (props: IProps) => props.fontSize ? props.fontSize : 10,
    fontFamily: (props: IProps) => props.fontFamily ? props.fontFamily : 'Verdana, Arial',
    letterSpacing: '1px',
  },
  deleteIcon: {
    color: (props: IProps) => props.color ? props.color : 'white',
    width: 13,
    paddingBottom: '3px',
  },
  label: {
    paddingBottom: '3px',
  },
});
