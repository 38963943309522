import { GET_MY_FAVORITE_EXPERTS } from './query';
import { ADD_EXPERT_FAVORITE, REMOVE_EXPERT_FAVORITE } from './mutation';
import client from '../../../communication/client';

export const fetchExpertsFavorites = async () => {
  const response = await client.query({
    query: GET_MY_FAVORITE_EXPERTS,
    fetchPolicy: 'network-only',
  });

  return {
    favorites: response.data ? response.data.expertFavorite_getMine.favorites : [],
    experts: response.data ? response.data.expertFavorite_getMine.experts : [],
  };
};

export const addExpertFavorite = async (expertId: string) => {
  const response = await client.mutate({
    mutation: ADD_EXPERT_FAVORITE,
    variables: {
      expertId,
    },
  });

  return response.data.expertFavorite_add;
};

export const removeExpertFavorite = async (expertId: string) => {
  const response = await client.mutate({
    mutation: REMOVE_EXPERT_FAVORITE,
    variables: {
      expertId,
    },
  });

  return response.data.expertFavorite_remove;
};
