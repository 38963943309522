import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { useApolloClient } from '@apollo/client';
import {
  StyledContainer,
  StyledToolbar,
  StyledButtonContainer,
  StyledSearchIcon,
  StyledContentContainer,
  StyledSearchContainer,
  searchProjectStyles,
} from './style';

import CustomAutocomplete from '../../../../components/customAutocomplete';
import ExpertSearch from './expertSearch';
import { ExpertiseContext, ExpertiseContextType } from '../../../../context/expertisesContext';
import { ExpertContext, ExpertContextType } from '../../../../context/expertContext';
import { SidebarsContext, SidebarsContextType } from '../../../../context/sidebarsContext';
import Alert from '../../../../components/alert';
import { EditedExpertSearchContext, EditedExpertSearchContextType } from '../../../../context/editedExpertSearch';
import * as expertSearchService from '../../../../entities/expertSearch/service';
import { ProjectContext, ProjectContextType } from '../../../../context/projectContext';

interface IProps {
  isOpen: boolean;
  projects: ProjectType[];
  selectedProjectId: string | null;
  setSelectedProjectId: (id: string | null) => void;
  selectedExpertSearches: ExpertSearchType[];
  setSelectedExpertSearches: (searchList: ExpertSearchType[]) => void;
  selectedExpertSearchId: string | null;
  setSelectedExpertSearchId: (id: string | null) => void;
  setSelectedExpertSearchResults: (expertSearchResults: ExpertSearchResultType[]) => void;
  expertsShortlisted: ExpertShortlistedType[]
  setExpertsShortlisted: (expertsShortlisted: ExpertShortlistedType[]) => void
  setUpdateProject: (value: boolean) => void;
}

const LeftSidebarContent: React.FC<IProps> = (props: IProps) => {
  const client = useApolloClient();
  const history = useHistory();

  const projectState = useContext<ProjectContextType>(ProjectContext);
  const expertState = useContext<ExpertContextType>(ExpertContext);
  const sidebarsState = useContext<SidebarsContextType>(SidebarsContext);
  const editedExpertSearchState = useContext<EditedExpertSearchContextType>(EditedExpertSearchContext);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const expertiseState = useContext<ExpertiseContextType>(ExpertiseContext);

  const handleShowError = (message: string) => {
    setErrorMessage(message);

    setTimeout(() => {
      setErrorMessage(null);
    }, 2000);
  };

  const createSearch = async (newSearch: NewExpertSearchEditType) => {
    try {
      const response = await expertSearchService.createOneExpertSearch(client, newSearch.projectId);

      const {
        search: newExpertSearch,
      } = response;

      projectState.setSelectedExpertSearches([...projectState.selectedExpertSearches, newExpertSearch]);

      editedExpertSearchState.setEditedExpertSearch(newExpertSearch);
      editedExpertSearchState.setEditedExpertSearch(null);

      projectState.setSelectedExpertSearchId(newExpertSearch._id);
      projectState.setSelectedExpertSearchResults([]);

      expertState.setExperts([]);
    } catch (e) {
      handleShowError(`ERROR: ${e.message}`);
    }
  };

  const handleProjectSelectionChange = async (projectId: string | null) => {
    props.setSelectedProjectId(projectId);

    if (!projectId) {
      props.setSelectedExpertSearchId(null);
      editedExpertSearchState.setEditedExpertSearch(null);
      props.setSelectedExpertSearches([]);
      expertState.setExpertsShortlisted([]);
      sidebarsState.setIsOpenRightSidebar(false);
      history.push('/experts');
      return;
    }

    try {
      sidebarsState.setIsOpenRightSidebar(false);
    } catch (e) {
      handleShowError(`ERROR: ${e.message}`);
    }
  };

  return (
    <StyledContainer
      $isOpen={props.isOpen}
      onClick={() => props.setUpdateProject(true)}
    >
      <StyledToolbar $isOpen={props.isOpen}>
        <StyledButtonContainer $isOpen={props.isOpen}>
          <StyledSearchIcon />
        </StyledButtonContainer>
      </StyledToolbar>
      <StyledContentContainer $isOpen={props.isOpen}>
        <StyledSearchContainer>
          <CustomAutocomplete
            optionsList={props.projects}
            optionKeyName="_id"
            optionLabelName="name"
            entityName="Project"
            handleChange={handleProjectSelectionChange}
            placeholder={`Select one project (${props.projects.length})`}
            inputStyles={searchProjectStyles.input}
            listContainerStyles={searchProjectStyles.listContainer}
            listItemsStyles={searchProjectStyles.listItems}
            selectedValue={props.selectedProjectId}
            dataTestId="project-selector"
          />
          <ExpertSearch
            visible={!!props.selectedProjectId}
            selectedProjectId={props.selectedProjectId}
            expertises={expertiseState.expertises}
            setEditedExpertSearch={editedExpertSearchState.setEditedExpertSearch}
            selectedExpertSearches={props.selectedExpertSearches}
            setSelectedExpertSearches={props.setSelectedExpertSearches}
            selectedExpertSearchId={props.selectedExpertSearchId}
            setSelectedExpertSearchId={props.setSelectedExpertSearchId}
            setSelectedExpertSearchResults={props.setSelectedExpertSearchResults}
            expertsShortlisted={props.expertsShortlisted}
            setExpertsShortlisted={props.setExpertsShortlisted}
            createSearch={createSearch}
          />
        </StyledSearchContainer>
      </StyledContentContainer>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ transform: 'translateY(70px)', zIndex: 9999999999 }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default LeftSidebarContent;
