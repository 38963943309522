import React from 'react';

import {
  StyledContainer,
} from './style';

import SectionDocketNumber from './sectionDocketNumber';
import SectionDescription from './sectionDescription';
import SectionAssignedTo from './sectionAssignedTo';
import SectionDates from './sectionDates';
import SectionAttorney from './sectionAttorney';
import SectionFirm from './sectionFirm';
import SectionJurisdictionType from './sectionJurisdictionType';
import SectionParty from './sectionParty';

interface IProps {
  courtCase: CourtCaseType | null;
}

const CourtCaseBody: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      <SectionDocketNumber courtCase={props.courtCase} />
      <SectionDescription caseDescription={props.courtCase?.description || ''} />
      <SectionAssignedTo courtCase={props.courtCase} />
      <SectionDates courtCase={props.courtCase} />
      <SectionAttorney attorney={props.courtCase?.attorney || []} />
      <SectionFirm firm={props.courtCase?.firm || []} />
      <SectionJurisdictionType jurisdictionType={props.courtCase?.jurisdictionType || ''} />
      <SectionParty party={props.courtCase?.party || []} />
    </StyledContainer>
  );
};

export default CourtCaseBody;
