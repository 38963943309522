import React, { useRef } from 'react';

import { useOutsideAlerter } from '../../../../utils/hooks';

import {
  StyledContainer,
  StyledTitle,
  StyledInput,
} from './style';

interface IProps {
  isSelectedSearchEditable: boolean;
  updateSearch: (expertSearch: ExpertSearchType) => void;
  expertSearch: ExpertSearchType | null;
}

const ExpertType: React.FC<IProps> = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const saveExpertTypeName = async () => {
    const EXPERT_TYPE_NAME = inputRef?.current?.value ?? '';
    if (props.expertSearch && props.expertSearch.name !== EXPERT_TYPE_NAME && !!EXPERT_TYPE_NAME) {
      props.updateSearch({ ...props.expertSearch, name: EXPERT_TYPE_NAME });
    }
  };

  useOutsideAlerter(inputRef, [inputRef, props.expertSearch], saveExpertTypeName);

  return (
    <>
      {props.isSelectedSearchEditable ? (
        <StyledInput
          ref={inputRef}
          defaultValue={props.expertSearch?.name ?? ''}
          data-testid="expert-search-name-input"
        />
      ) : (
        <StyledContainer>
          <StyledTitle data-testid="expert-search-name-title">{props.expertSearch?.name}</StyledTitle>
        </StyledContainer>
      )}
    </>
  );
};

export default ExpertType;
