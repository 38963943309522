import styled from 'styled-components';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

interface ContainerProps {
  readonly $isOpen: boolean
}

export const StyledContainer = styled.div<ContainerProps>`
  flex: 0 0 400px;
  background-color: white;
  height: 92vh;
  border-right: 8px solid black;
  position: relative;  
  margin-left: ${(props) => props.$isOpen ? '0' : '-318px'}; // WHEN CLOSE, IT MUST LEAVE 82px
  transition: all .1s linear;    
`;

export const StyledButtonContainer = styled.span`
  position: absolute;
  right: 0;
  top: 50%;
  padding: 0;
  margin: 0;
  display: block;
  background-color: black;
  width: 20px;
  height: 130px; 
  display: flex;
  justify-content: center;
  align-items: center; 
  &:hover {
    cursor: pointer;
  }
`;

export const StyledDoubleArrowIcon = styled(DoubleArrowIcon)<ContainerProps>`

  && {
    color: white;
    margin: 0;
    margin-left: 5px;
    font-size: 20px;
    transform: ${(props) => props.$isOpen ? 'rotate(180deg)' : ''};
  }  
  

`;
