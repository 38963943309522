import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
} from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';

export const StyledContainer = styled.div`

  flex: 0 0 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  margin-right: 5px;
`;

export const StyledFormControl = styled(FormControl)`
  && {
    width: 120px; 
    background: 'white',     
  }  

  & > div.MuiOutlinedInput-root {   
      border-radius: 0; 
      background: 'white',
  }    
    
`;

interface ISelectProps {
  disabled: boolean;
}

export const useStyles = makeStyles({
  select: {
    minWidth: 120,
    background: (props: ISelectProps) => props.disabled ? '#DEDEDE' : 'white',
    color: (props: ISelectProps) => props.disabled ? '#A1A1A1' : '#304F5A',
    border: 'none',
    borderRadius: 0,
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    fontSize: 12,
    fontFamily: 'Cairo',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    '&:focus': {
      background: 'white',
      borderRadius: 0,
      border: '1px solid #8DC63F',
    },
  },
  icon: {
    color: (props: ISelectProps) => props.disabled ? '#A1A1A1' : '#304F5A',
    marginTop: 0,
    right: 2,
    position: 'absolute',
    flexShrink: 0,
    userSelect: 'none',
    pointerEvents: 'none',
    fontSize: 22,
  },
  paper: {
    borderRadius: 0,
    marginTop: 8,
    border: '1px solid #8DC63F',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      fontSize: 12,
      color: '#304F5A',
      fontFamily: 'Cairo',
      fontWeight: 'normal',
    },
    '& li:hover': {
      background: '#F0F0F0',
    },
    /* '& li.Mui-selected': {
      color: '#304F5A',
      background: '#F7FFED',
    },
    '& li.Mui-selected:hover': {
      background: '#F0F0F0',
    }, */
  },
});

interface IIconProps {
  $disabled: boolean;
}

export const StyledDecorativeIcon = styled(TuneIcon)`

  && {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    margin-top: -7.5px;
    left: 10px;
    color: ${(props: IIconProps) => props.$disabled ? '#A1A1A1' : '#304F5A'};
  }

 

`;

interface IMenuItemProps {
  $title: string;
  $count: number;
  $backColor: string;
  $dataTestId?: string;
}
interface IMenuItemContainerProps {
  $backColor: string;
}

const StyledMenuItemContainer = styled.span<IMenuItemContainerProps>`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 10px 15px;
  background-color: ${(props: IMenuItemContainerProps) => props.$backColor};
  transition: background-color .2s linear;

  &:hover {

    background-color: #F0F0F0;

  }

`;

const StyledMenuItemName = styled.span`
  font-family: Cairo;
  color: black;
  font-size: 12px;
  font-weight: normal;
`;

const StyledMenuItemCount = styled.span`
  font-family: Cairo;
  color: black;
  font-size: 10px;
  font-weight: normal;
`;

export const StyledMenuItemContent: React.FC<IMenuItemProps> = (props: IMenuItemProps) => {
  return (
    <StyledMenuItemContainer
      $backColor={props.$backColor}
      data-testid={props.$dataTestId}
    >
      <StyledMenuItemName>{props.$title}</StyledMenuItemName>
      <StyledMenuItemCount>{`(${props.$count})`}</StyledMenuItemCount>
    </StyledMenuItemContainer>
  );
};
