import React, { ReactNode, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/client';
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import GlobalStyles from '../globalStyles';
import client from '../communication/client';
import theme from './theme';
import SidebarsContextProvider from '../context/sidebarsContext';
import ProjectContextProvider, { ProjectContext, ProjectContextType } from '../context/projectContext';
import ExpertContextProvider from '../context/expertContext';
import FilingContextProvider from '../context/filingContext';
import ProspectorContextProvider from '../context/prospector';
import { useAuth0 } from '../react-auth0-spa';
import Routes from './routes';
import { GET_PROJECTS } from '../entities/project/service/query';
import { GET_COURT_CASES } from '../entities/courtCase/service/query';
import AppLayout from './layout';

import ErrorComponent from '../components/errorComponent';
import ExpertiseContextProvider, { ExpertiseContext, ExpertiseContextType } from '../context/expertisesContext';
import CourtCaseContextProvider, { CourtCaseContext, CourtCaseContextType } from '../context/courtCaseContext';

import * as expertiseService from '../entities/expertise/service';
import * as expertTypeService from '../entities/expertType/service';
import EditedExpertSearchContextProvider from '../context/editedExpertSearch';

const AppSetup = () => {
  const { loading: isLoading } = useAuth0();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Router>
          <SidebarsContextProvider>
            <ProjectContextProvider>
              <CourtCaseContextProvider>
                <ExpertiseContextProvider>
                  <ExpertContextProvider>
                    <FilingContextProvider>
                      <ProspectorContextProvider>
                        <EditedExpertSearchContextProvider>
                          <GlobalStyles />
                          { isLoading ? (
                            <Loading />
                          )
                            : (
                              <DataLoad>
                                <AppLayout>
                                  <Routes />
                                </AppLayout>
                              </DataLoad>
                            )}
                        </EditedExpertSearchContextProvider>
                      </ProspectorContextProvider>
                    </FilingContextProvider>
                  </ExpertContextProvider>
                </ExpertiseContextProvider>
              </CourtCaseContextProvider>
            </ProjectContextProvider>
          </SidebarsContextProvider>
        </Router>
      </ThemeProvider>
    </ApolloProvider>
  );
};

interface IProps {
  children: ReactNode;
}
const DataLoad = ({ children }: IProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const { isAuthenticated } = useAuth0();

  const projectState = useContext<ProjectContextType>(ProjectContext);
  const courtCaseState = useContext<CourtCaseContextType>(CourtCaseContext);
  const expertiseState = useContext<ExpertiseContextType>(ExpertiseContext);

  React.useEffect(() => {
    fetchProjects();
    fetchCourtCases();
    fetchExpertisesAndExpertTypes();
  }, []);

  const fetchProjects = async () => {
    if (!isAuthenticated) return setIsLoading(false);
    try {
      const result = await client.query({
        query: GET_PROJECTS,
        fetchPolicy: 'network-only',
      });

      if (!result || !result.data || !result.data.project_getAll) {
        setIsLoading(false);
        return setError('Error');
      }

      projectState.setProjects(result.data.project_getAll);
    } catch (err) {
      setError('Error');
    }

    return setIsLoading(false);
  };

  const fetchExpertisesAndExpertTypes = async () => {
    try {
      const fetchedExpertises = await expertiseService.getExpertises();
      const fetchedExpertTypes = await expertTypeService.getExpertTypes();

      expertiseState.setExpertises(fetchedExpertises);
      expertiseState.setExpertTypes(fetchedExpertTypes);
    } catch (e:any) {
      expertiseState.setFetchError(e);
    }
  };

  const fetchCourtCases = async () => {
    if (!isAuthenticated) return;
    const result = await client.query({
      query: GET_COURT_CASES,
      fetchPolicy: 'network-only',
    });
    const courtCases = _.get(result, 'data.courtCase_getAll', []);
    courtCaseState.setCourtCases(courtCases);
    courtCaseState.setIsLoadingCourtCases(false);
  };

  if (isLoading) return Loading();
  if (error) return Error();
  return (
    <>
      { children }
    </>
  );
};

const Loading = () => <Typography style={{ padding: '1em' }}>Loading...</Typography>;

const Error = () => <ErrorComponent title="" />;

export default AppSetup;
