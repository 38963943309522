import React, { useRef } from 'react';
import InputBase from '@material-ui/core/InputBase';

import {
  useCustomSearchStyles,
  SearchButton,
  StyledDeleteIcon,
  ButtonContainer,
} from './style';

interface IProps {
  value: string;
  handleChange: (value: string) => void;
  handleClear?: () => void;
  handleClick?: () => void;
  onExecute?: () => void;
  styles?: {
    maxWidth?: string;
    margin?: string;
    searchIconSize?: string;
    closeIconSize?: string;
  };
  placeholder?: string;
  'data-testid'?: string;
}

const CustomSearch: React.FC<IProps> = (props: IProps) => {
  const classes = useCustomSearchStyles(props);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClearButtonClick = () => {
    props.handleClear?.();
    inputRef?.current?.focus();
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (props.value) {
      props.onExecute?.();
    }
  };

  return (
    <form className={classes.container} onSubmit={handleSubmit}>
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        placeholder={props.placeholder || ''}
        value={props.value}
        onChange={(e: any) => props.handleChange?.(e.target.value)}
        onClick={() => props.handleClick?.()}
        autoFocus
        inputProps={{
          'data-testid': `${props['data-testid']}-input`,
        }}
        endAdornment={(
          <ButtonContainer $isActiveSearch={!props.onExecute}>
            <StyledDeleteIcon
              onClick={handleClearButtonClick}
              $iconSize={props.styles?.closeIconSize}
              $iconVisible={!!props.value}
              $isActiveSearch={!props.onExecute}
            />
            <SearchButton
              $iconSize={props.styles?.searchIconSize}
              $clearVisible={!!props.value}
              $isActiveSearch={!props.onExecute}
              disabled={!props.value}
            />
          </ButtonContainer>
        )}
      />
    </form>
  );
};

export default CustomSearch;
