import React from 'react';

import {
  StyledContainer,
  StyledLabelIcon,
} from './style';

import ExpertRow from '../../../../../../components/expertRow';
import LabelIconGreen from '../../../../../../assets/svg/LabelIconGreen.svg';

interface IProps {
  expertsShortlisted: ExpertShortlistedType[]
  handleUnshortListExpert: (expertId: string, searchId: string) => void;
  getExpert: (expertId: string) => ExpertType | null
}

const ExpertsList: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      {props.expertsShortlisted.map((expertShortlisted) => {
        const item = props.getExpert(expertShortlisted.expertId);
        if (!item) return null;
        return (
          <ExpertRow
            key={expertShortlisted._id}
            item={item}
            view="right"
            rightIcon={(
              <StyledLabelIcon
                src={LabelIconGreen}
                onClick={() => props.handleUnshortListExpert(item?._id ?? '', expertShortlisted.searchId)}
              />
            )}
          />
        );
      })}
    </StyledContainer>
  );
};

export default ExpertsList;
