import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface IContainerProps {
  $isOpen: boolean;
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
}

const StyledMainContainer = styled.div<IContainerProps>`
  box-sizing: border-box;
  width: 100%;  
  height: 100%;
  padding: 0 20px;
  opacity: ${(props:IContainerProps) => props.$isOpen ? '1' : '0'};  
  flex-flow: column nowrap;
  justify-content: center;
  transition: opacity .2s linear;  
`;

const StyledFooterContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledContainer: React.FC<IContainerProps> = (props: IContainerProps) => {
  return (
    <StyledMainContainer $isOpen={props.$isOpen} onClick={props.onClick}>
      <StyledFooterContainer>
        {props.children}
      </StyledFooterContainer>
    </StyledMainContainer>
  );
};

export const StyledDisputeContainer = styled.div`

  flex: 0 0 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

export const StyledDisputeLabel = styled.label`
  width: 80px;
  color: black;
  font-size: 12px;
  font-weight: normal;
  font-family: Cairo;
  
`;

export const StyledDisputeValue = styled.input`
  width: 90px;
  padding: 0;
  margin: 0;
  color: black;
  font-size: 12px;
  font-weight: bold;
  font-family: Cairo;
  padding: 5px 0 5px 20px;
  text-align: left;
  box-shadow: 0px 0px 3px gray;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const StyledButtonsContainer = styled.div`
  flex: 0 1 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;

`;

export const StyledResetButton = styled(Button)`

  width: 75px;
  color: #9F9F9F;
  background-color: white;
  text-transform: none;
  font-size: 12px;
  font-weight: bold;
  font-family: Cairo;
  margin-right: 5px;
  border-radius: 0;
  box-shadow: 0px 0px 3px lightgray;
`;

export const StyledRejectButton = styled(Button)`

  width: 75px;
  color: #9F9F9F;
  background-color: #F3F3F3;
  text-transform: none;
  font-size: 12px;
  font-weight: bold;
  font-family: Cairo;
  margin-right: 5px;
  border-radius: 0;
  box-shadow: 0px 0px 3px lightgray;
`;

export const StyledTrackButton = styled(Button)`

  width: 100px;
  color: white;
  background-color: #8DC63F;
  text-transform: none;
  font-size: 12px;
  font-weight: bold;
  font-family: Cairo;
  border-radius: 0;
  box-shadow: 0px 0px 3px lightgray;
`;

export const StyledResetIcon = styled(AutorenewIcon)`

  && {
    color: #9F9F9F;
    margin-right: 5px;
    font-size: 16px;
  }

`;

export const StyledRejectIcon = styled(CancelIcon)`

  && {
    color: #9F9F9F;
    margin-right: 5px;
    font-size: 16px;
  }

`;

export const StyledTrackIcon = styled(CheckCircleIcon)`

&& {
    color: white;
    margin-right: 5px;
    font-size: 16px;
  }

`;
