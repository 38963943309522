import React, { useState, useMemo } from 'react';

export interface EditedExpertSearchContextType {
  editedExpertSearch: ExpertSearchEditType | null
  setEditedExpertSearch: (expertSearch: ExpertSearchEditType | null) => void
}

export const EditedExpertSearchContext = React.createContext<EditedExpertSearchContextType>({
  editedExpertSearch: null,
  setEditedExpertSearch: (value: ExpertSearchEditType | null) => value,
});

interface IProps {
  children: React.ReactNode;
}

const EditedExpertSearchContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [editedExpertSearch, setEditedExpertSearch] = useState<ExpertSearchEditType | null>(null);

  const contextValue = useMemo(() => ({
    editedExpertSearch,
    setEditedExpertSearch,
  }), [
    editedExpertSearch,
  ]);

  return (
    <EditedExpertSearchContext.Provider value={contextValue}>
      {children}
    </EditedExpertSearchContext.Provider>
  );
};

export default EditedExpertSearchContextProvider;
