import React from 'react';

import {
  StyledContainer,
  StyledLeftPanelContainer,
  StyledRightPanelContainer,
} from './style';

import DaysSelect from './daysSelect';
import CustomSearch from '../../../../components/customSearch';

enum EnumPeriodOption { LAST24H, LAST7D, LAST14D, LAST30D, LAST90D }

interface IProps {
  selectedPeriod: EnumPeriodOption;
  onChangeSelectedPeriod: (newSelectedPeriod: EnumPeriodOption) => Promise<void>;
  filterByCaseName: string;
  setFilterByCaseName: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      <StyledLeftPanelContainer>
        <CustomSearch
          value={props.filterByCaseName}
          handleChange={(value: string) => props.setFilterByCaseName(value)}
          handleClear={() => props.setFilterByCaseName('')}
          styles={{
            margin: '0 10px 0 0',
          }}
          placeholder="Search"
          data-testid="prospects-courtCases-searchBar-globalSearchInput"
        />
      </StyledLeftPanelContainer>
      <StyledRightPanelContainer>
        <DaysSelect
          selectedValue={props.selectedPeriod}
          handleChange={(value: EnumPeriodOption) => props.onChangeSelectedPeriod(value)}
          disabled={false}
        />
      </StyledRightPanelContainer>
    </StyledContainer>
  );
};

export default SearchBar;
