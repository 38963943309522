import React from 'react';
import { Li, UL, ListWrap } from './style';

interface EducationProps {
  education_or_background: string[];
}

function Education(props: EducationProps) {
  return (
    <ListWrap>
      <UL>
        {props.education_or_background.length > 0
          ? props.education_or_background.map((item) => (
            <Li key={item}>{item}</Li>
          )) : '-'}
      </UL>
    </ListWrap>
  );
}

export default Education;
