export const getExpertSearchObject = (searchId: string | null, searchList: ExpertSearchType[]) => {
  return searchList.find(
    (item: ExpertSearchType) => (item._id === searchId),
  ) as ExpertSearchType;
};

export const cleanExpertSearches = (expertSearches: any[]) => {
  const cleanedResults = expertSearches.map((result: any) => {
    const {
      _id: id,
      projectId,
      expertiseIds,
      idealExpertIds,
      description,
      position,
      name,
    } = result;

    return {
      _id: id,
      projectId,
      expertiseIds,
      idealExpertIds,
      description,
      position,
      name,
    };
  });

  return cleanedResults;
};
