import React from 'react';

import ExpandableGrid from '../../../../components/expandableGrid';

import CellRenderer from './cellRenderer';

import RowCollapser from './rowCollapser';

import * as filingStatusService from '../../../../entities/filingStatus/service';

interface IProps {
  filteredFilings: DisplayFilingType[];
  filingStatuses: FilingStatusType[];
  setFilingStatuses: (statuses: FilingStatusType[]) => void;
  selectedTrackedCase: FilingStatusType | null;
  isLoading: boolean;
}

const SecFilingsTable: React.FC<IProps> = (props: IProps) => {
  const getFilingStatus = (secFiling: DisplayFilingType) => {
    const filingStatusObject = props.filingStatuses.find((item: FilingStatusType) => {
      return (item.companyId === secFiling.companyId) && (item.filingId === secFiling.filingId);
    });

    if (!filingStatusObject) return 'RAW';

    return filingStatusObject.status;
  };

  const upsertSecFilingLocally = (filingStatus: FilingStatusType) => {
    if (!props.filingStatuses.find(
      (item: FilingStatusType) => (item.companyId === filingStatus.companyId)
       && (item.filingId === filingStatus.filingId),
    )) {
      return props.setFilingStatuses([...props.filingStatuses, filingStatus]);
    }

    const editedFilingStatuses = props.filingStatuses.map((item: FilingStatusType) => {
      if ((item.companyId === filingStatus.companyId) && (item.filingId === filingStatus.filingId)) {
        return filingStatus;
      }

      return item;
    });

    return props.setFilingStatuses(editedFilingStatuses);
  };

  const handleSetFilingStatus = async (
    companyId: string,
    filingId: string,
    status: 'TRACKED' | 'REJECTED' | 'RAW',
  ) => {
    await filingStatusService.upsertFilingStatus(companyId, filingId, status);

    upsertSecFilingLocally({
      companyId,
      filingId,
      status,
    });
  };

  const selectedTrackedCase = props.selectedTrackedCase;

  const columns = [
    {
      headerProps: {
        label: 'Company',
        width: '30%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="company_display_name"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          getFilingStatus={getFilingStatus}
          selectedTrackedCase={selectedTrackedCase}
          fieldName="company_display_name"
          styleProps={{
            width: '30%',
            padding: '20px 10px 20px 30px',
            textAlign: 'left',
            textColor: 'black',
            fontSize: '12px',
            fontWeight: 'normal',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        if (a.company_display_name < b.company_display_name) return -1;

        if (a.company_display_name > b.company_display_name) return 1;

        return 0;
      },
    },
    {
      headerProps: {
        label: 'Tickers',
        width: '20%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="tickers"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          getFilingStatus={getFilingStatus}
          selectedTrackedCase={selectedTrackedCase}
          fieldName="tickers"
          styleProps={{
            width: '20%',
            padding: '20px 10px 20px 10px',
            textAlign: 'center',
            textColor: 'black',
            fontSize: '12px',
            fontWeight: 'normal',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        const tickersValueA = (a.tickers as string[]).join(', ');

        const tickersValueB = (b.tickers as string[]).join(', ');

        if (tickersValueA < tickersValueB) return -1;

        if (tickersValueA > tickersValueB) return 1;

        return 0;
      },
    },
    {
      headerProps: {
        label: 'Filings',
        width: '15%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="filingForm"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          getFilingStatus={getFilingStatus}
          selectedTrackedCase={selectedTrackedCase}
          fieldName="filingForm"
          styleProps={{
            width: '15%',
            padding: '20px 10px 20px 10px',
            textAlign: 'center',
            textColor: '#8DC63F',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        if (a.filingForm < b.filingForm) return -1;

        if (a.filingForm > b.filingForm) return 1;

        return 0;
      },
    },
    {
      headerProps: {
        label: 'Last Filed',
        width: '20%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="latest_filed"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          getFilingStatus={getFilingStatus}
          selectedTrackedCase={selectedTrackedCase}
          fieldName="latest_filed"
          styleProps={{
            width: '20%',
            padding: '20px 10px 20px 10px',
            textAlign: 'center',
            textColor: 'black',
            fontSize: '12px',
            fontWeight: 'normal',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        if (a.latest_filed < b.latest_filed) return -1;

        if (a.latest_filed > b.latest_filed) return 1;

        return 0;
      },
    },
    {
      headerProps: {
        label: 'Keywords',
        width: '15%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="filingDisplayMatchingKeywords"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          getFilingStatus={getFilingStatus}
          selectedTrackedCase={selectedTrackedCase}
          fieldName="filingDisplayMatchingKeywords"
          styleProps={{
            width: '15%',
            padding: '20px 10px 20px 10px',
            textAlign: 'center',
            textColor: '#6BC2E1',
            fontSize: '8px',
            fontWeight: 'bold',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        if (a.filingDisplayMatchingKeywords < b.filingDisplayMatchingKeywords) return -1;

        if (a.filingDisplayMatchingKeywords > b.filingDisplayMatchingKeywords) return 1;

        return 0;
      },
    },
  ];

  return (
    <ExpandableGrid
      dataTestIdPrefix="propects-secFilings"
      isLoading={props.isLoading}
      data={props.filteredFilings}
      getDataIdentifier={(dataItem: DisplayFilingType) => `${dataItem.companyId}-${dataItem.filingId}`}
      columns={columns}
      rowCollapser={(
        dataItem: any,
        isRowOpen: boolean,
      ) => (
        <RowCollapser
          dataItem={dataItem}
          isRowOpen={isRowOpen}
          handleSetFilingStatus={handleSetFilingStatus}
          selectedTrackedCase={props.selectedTrackedCase}
        />
      )}
    />
  );
};

export default SecFilingsTable;
