import _ from 'lodash';

export const getFilingsFromSecCompanyFeeds = (companyFeeds: SecCompanyFeedType[]) => {
  const filingsList: DisplayFilingType[] = _.flatMap(companyFeeds, (companyFeed: SecCompanyFeedType) => {
    return companyFeed.filings.map((filing: SecFilingType) => {
      return {
        companyId: companyFeed.id,
        company_display_name: companyFeed.company_display_name,
        tickers: companyFeed.tickers,
        filingId: filing.id,
        filingForm: filing.form,
        filingUrl: filing.url,
        filingFileDate: filing.file_date,
        filingMatchingKeywords: filing.matching_keywords,
        filingDisplayMatchingKeywords: filing.display_matching_keywords,
        filingType: filing.filing_type,
        latest_filed: companyFeed.latest_filed,
        source_display_name: companyFeed.source_display_name,
      };
    });
  });

  return filingsList;
};
