import styled from 'styled-components';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Button from '@material-ui/core/Button';

interface IExpandible {
  $isOpen: boolean;
  $listElements: number;
}
export const StyledContainer = styled.div<IExpandible>`
  padding: 20px 20px 20px 15px;      
  background-color: #F0F0F0;  
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap; 
  overflow-y: hidden; 
  height: ${(props) => props.$isOpen ? '45vh' : '75vh'};
  min-height: ${(props) => props?.$listElements >= 8 ? '687px' : `${246 + (54 * (props.$listElements))}px`};
`;

export const StyledInnerContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  margin: 0;
  flex: 1 1 auto;
  min-height: 0px;   
  display: flex;
  flex-flow: column nowrap;  
  overflow-y: hidden;   
`;

export const StyledButtonsContainer = styled(ToggleButtonGroup)`
  min-width: 130px;
`;

export const StyledToggleButton = styled(ToggleButton)`
  && {    
    margin: 0;    
    text-transform: capitalize;
    font-size: 15px;
    font-weight: bold;
    font-family: Cairo;
    border-radius: 0;
    border-width: 0;
    color: #000;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #E5E5E5;
    margin-right: 8px;
  }

  &&.Mui-selected {
    background-color: #9F9F9F;
  }
`;

export const StyledButtonCountSpan = styled.span`
  font-weight: normal;
  margin-left: 6px;
`;

export const StyledTotalResults = styled.span`
  font-size: 15px;
  color: #000;
`;

export const StyledAddExpertButton = styled(Button)`
  && {
    text-transform: none;
    font-family: Cairo;
    font-weight: bold;
  }
`;

export const LoadingWithSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200000;
  opacity: 1;
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;  
  margin-top: 20%;
`;

export const BoldTitle = styled.h3`

  color: #304F5A;
  font-size: 14px;
  font-family: Cairo;
  font-weight: bold;
  text-align: center;
  margin: 0px;
`;
