import styled from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

interface IArrowIcon {
  disabled?: boolean;
}

export const StyledArrowBackIcon = styled(ArrowBackIcon)<IArrowIcon>`
  cursor: ${(props) => props.disabled ? 'initial' : 'pointer'};
`;

export const StyledArrowForwardIcon = styled(ArrowForwardIcon)<IArrowIcon>`
  cursor: ${(props) => props.disabled ? 'initial' : 'pointer'};
`;

export const StyledContainer = styled.div`
  display: flex;
  gap: 4px;
  justify-content: start;
  align-items: center;
  width: 50%;
`;

export const StyledH3 = styled.h3`
  margin: 0px;
`;
