import React from 'react';
import styled from 'styled-components';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { EnumSortOrder } from '../types';

const StyledMainContainer = styled.div`
  width: 100%;
`;

const StyledFlexContainer = styled.div`

  width: 100%;
  display: flex; 
`;

interface IProps {
  children: React.ReactNode;
}

export const StyledContainer: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledMainContainer>
      <StyledFlexContainer>
        {props.children}
      </StyledFlexContainer>
    </StyledMainContainer>
  );
};

interface IFlexColumnProps {
  width?: string;
  backColor?: string;
}

export const StyledFlexColumn = styled.div<IFlexColumnProps>`
  box-sizing: border-box;
  flex: ${(props: IFlexColumnProps) => props.width ? `1 1 ${props.width}` : '1 1 100%'};
  background-color: ${(props: IFlexColumnProps) => props.backColor ? props.backColor : 'white'};  
  position: relative;
  border-right: 1px solid #D7D7D7;

  &:last-child {
    border-right: none;
  }
`;

interface IHeaderTitleProps {
  textAlign?: string;
  textColor? : string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
}

export const StyledHeaderTitle = styled.h2<IHeaderTitleProps>`
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: ${(props: IHeaderTitleProps) => props.textAlign ? props.textAlign : 'center'};
  color: ${(props: IHeaderTitleProps) => props.textColor ? props.textColor : '#304F5A'};
  font-size: ${(props: IHeaderTitleProps) => props.fontSize ? props.fontSize : '14px'};
  font-weight: ${(props: IHeaderTitleProps) => props.fontWeight ? props.fontWeight : 'bold'};
  font-family: ${(props: IHeaderTitleProps) => props.fontFamily ? props.fontFamily : 'arial'};
  padding: 15px 0;
`;

interface IArrowIcon {
  $isSorted?: boolean;
  $sortOrder?: EnumSortOrder;
}

const getTransform = (props: IArrowIcon) => {
  if (!props.$isSorted) return 'rotate(90deg)';

  if (props.$sortOrder === EnumSortOrder.ASC) {
    return 'rotate(90deg)';
  }

  return 'rotate(-90deg)';
};

export const StyledSortArrow = styled(PlayArrowIcon)<IArrowIcon>`
  
  && {
    display: 'inline-block';
    color: #B6B6B6;
    margin-left: 5px;
    transform: ${(props: IArrowIcon) => getTransform(props)};
    font-size: 16px;
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -8px;
  }  
  
  &:hover {
    cursor: pointer;
  }
`;
