import _ from 'lodash';
import {
  GET_FILING_DISPUTE_VALUE_BY_FILING,
  GET_FILING_DISPUTE_VALUES_BY_FILINGS,
} from './query';
import { UPSERT_FILING_DISPUTE_VALUE_BY_FILING } from './mutation';
import client from '../../../communication/client';

export const fetchFilingDisputeValueByFiling = async (companyId: string, filingId: string) => {
  const response = await client.query({
    query: GET_FILING_DISPUTE_VALUE_BY_FILING,
    variables: {
      companyId,
      filingId,
    },
    fetchPolicy: 'network-only',
  });

  return _.get(response, 'data.filingDisputeValue_getByFiling', null);
};

export const fetchFilingDisputeValuesByFilings = async (
  filingsList: {companyId: string, filingId: string }[],
) => {
  const response = await client.query({
    query: GET_FILING_DISPUTE_VALUES_BY_FILINGS,
    variables: {
      input: filingsList,
    },
    fetchPolicy: 'network-only',
  });

  return _.get(response, 'data.filingDisputeValues_getByFilings', null);
};

export const upsertFilingDisputeValue = async (companyId: string, filingId: string, value: number) => {
  const response = await client.mutate({
    mutation: UPSERT_FILING_DISPUTE_VALUE_BY_FILING,
    variables: {
      companyId,
      filingId,
      value,
    },
  });

  return _.get(response, 'data.filingDisputeValue_upsertByFiling', false);
};
