import React, { useState, useEffect } from 'react';

import {
  StyledContainer,
  StyledDisputeContainer,
  StyledDisputeLabel,
  StyledDisputeValue,
  StyledButtonsContainer,
  StyledResetButton,
  StyledRejectButton,
  StyledTrackButton,
  StyledResetIcon,
  StyledRejectIcon,
  StyledTrackIcon,
} from './style';

import {
  fetchFilingDisputeValueByFiling,
  upsertFilingDisputeValue,
} from '../../../../../entities/filingDisputeValue/service';

interface IProps {
  dataItem: DisplayFilingType;
  isRowOpen: boolean;
  handleSetFilingStatus: (companyId: string, filingId: string, status: 'TRACKED' | 'REJECTED' | 'RAW') => Promise<void>;
  selectedTrackedCase: FilingStatusType | null;
}

const formatNum = (amount: number) => {
  return new Intl.NumberFormat().format(amount);
};

const RowCollapser: React.FC<IProps> = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (!props.selectedTrackedCase) return;

    if (props.selectedTrackedCase.companyId === props.dataItem.companyId
      && props.selectedTrackedCase.filingId === props.dataItem.filingId
    ) {
      fetchDisputeValue();
    }
  }, [props.selectedTrackedCase]);

  useEffect(() => {
    if (props.isRowOpen) {
      fetchDisputeValue();
    }
  }, [props.isRowOpen]);

  const fetchDisputeValue = async () => {
    setIsLoading(true);

    const disputeValue = await fetchFilingDisputeValueByFiling(props.dataItem.companyId, props.dataItem.filingId);

    if (!disputeValue) {
      setInputValue('-');
    } else {
      setInputValue(`$${formatNum(disputeValue.value)}`);
    }

    setIsLoading(false);
  };

  const handleInputBlur = async () => {
    if (!inputValue || !Number.parseInt(inputValue, 10)) return;
    setIsLoading(true);
    const newFilingDisputValue = {
      companyId: props.dataItem.companyId,
      filingId: props.dataItem.filingId,
      value: Number.parseInt(inputValue, 10),
    };

    await upsertFilingDisputeValue(
      newFilingDisputValue.companyId,
      newFilingDisputValue.filingId,
      newFilingDisputValue.value,
    );

    setInputValue(`$${formatNum(newFilingDisputValue.value)}`);

    setIsLoading(false);
  };

  const handleInputFocus = () => {
    if (!inputValue || !Number.parseInt(inputValue, 10)) setInputValue('');
  };

  const getInitialDisputeValue = () => {
    if (isLoading) return '-';

    return inputValue;
  };

  const hasOnlyNumbers = (text: string) => {
    if (!text) return true;

    const charList = Array.from(text);

    return charList.every((item: string) => {
      if (Number.parseInt(item, 10) || item === '0') return true;

      return false;
    });
  };

  const handleInputChange = (newValue: string) => {
    if (!hasOnlyNumbers(newValue)) return;
    setInputValue(newValue);
  };

  const handleResetButtonClick = () => {
    props.handleSetFilingStatus(props.dataItem.companyId, props.dataItem.filingId, 'RAW');
  };

  const handleRejectButtonClick = () => {
    props.handleSetFilingStatus(props.dataItem.companyId, props.dataItem.filingId, 'REJECTED');
  };

  const handleTrackButtonClick = () => {
    props.handleSetFilingStatus(props.dataItem.companyId, props.dataItem.filingId, 'TRACKED');
  };

  // eslint-disable-next-line
   const trackButtonCaseTestId = `propects-secFilings-expandablerGrid-item-itemContent-trackCaseButton-${props.dataItem.companyId}-${props.dataItem.filingId}`;

  // eslint-disable-next-line
   const rejectButtonTestId = `propects-secFilings-expandablerGrid-item-itemContent-rejectButton-${props.dataItem.companyId}-${props.dataItem.filingId}`;

  // eslint-disable-next-line
   const resetButtonTestId = `propects-secFilings-expandablerGrid-item-itemContent-resetButton-${props.dataItem.companyId}-${props.dataItem.filingId}`;

  return (
    <StyledContainer $isOpen={props.isRowOpen} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <StyledDisputeContainer>
        <StyledDisputeLabel>Dispute Value:</StyledDisputeLabel>
        <StyledDisputeValue
          value={`${getInitialDisputeValue()}`}
          onChange={(e) => handleInputChange(e.target.value)}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
        />
      </StyledDisputeContainer>
      <StyledButtonsContainer>
        <StyledResetButton
          onClick={handleResetButtonClick}
          data-testid={resetButtonTestId}
        >
          <StyledResetIcon />
          Reset
        </StyledResetButton>
        <StyledRejectButton
          onClick={handleRejectButtonClick}
          data-testid={rejectButtonTestId}
        >
          <StyledRejectIcon />
          Reject
        </StyledRejectButton>
        <StyledTrackButton
          onClick={handleTrackButtonClick}
          data-testid={trackButtonCaseTestId}
        >
          <StyledTrackIcon />
          Track Case
        </StyledTrackButton>
      </StyledButtonsContainer>
    </StyledContainer>
  );
};

export default RowCollapser;
