import React, { useState } from 'react';

import {
  StyledContainer,
  StyledFlexContainer,
  StyledExpandableArea,
} from './style';

interface IProps {
  dataItem: any;
  columns: {
    headerProps: {
      label: string;
      width: string;
    };
    cellRenderer: (
      item: any,
      setRowOpen: (value: boolean) => void,
      isRowOpen: boolean,
    ) => JSX.Element;
    compareTo: (a: any, b: any) => void;
  }[];
  rowCollapser?: (
    item: any,
    isRowOpen: boolean
  ) => JSX.Element;
  dataTestIdPrefix: string;
  getDataIdentifier: (dataItem: any) => string;
}

const GridRow: React.FC<IProps> = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const setRowOpen = (value: boolean) => {
    setIsOpen(value);
  };

  const displayRow = () => {
    return props.columns.map((column) => {
      return column.cellRenderer(
        props.dataItem,
        setRowOpen,
        isOpen,
      );
    });
  };

  const itemTestId = `${props.dataTestIdPrefix}-expandablerGrid-item-${props.getDataIdentifier(props.dataItem)}`;

  return (
    <StyledContainer
      data-testid={itemTestId}
    >
      <StyledFlexContainer>
        {displayRow()}
      </StyledFlexContainer>
      {
        props.rowCollapser && isOpen
         && (
         <StyledExpandableArea>
           {props.rowCollapser(
             props.dataItem,
             isOpen,
           )}
         </StyledExpandableArea>
         )
      }
    </StyledContainer>
  );
};

export default GridRow;
