import styled from 'styled-components';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface IInputProps {
  width?: string;
  margin?: string;
}
interface IDetailedInputProps {
  inputStyles?: {
    padding? : string;
    border? : string;
    borderRadius?: string;
    backgroundColor?: string;
    textAlign?: 'left' | 'right' | 'center';
    textColor?: string;
    fontSize?: number;
    fontWeight?: string;
    focusBorder?: string;
    focusTextColor?: string;
    fontFamily?: string;
    boxShadow?: string;
  }
}

interface IListContainerProps {
  listContainerStyles?: {
    width?: string;
    maxHeight?: string;
    fontSize?: number;
  }
  openList: boolean;
  scrollbarPosition?: string;
}

interface IListItemsProps {
  listItemsStyles?: {
    padding?: string;
    margin?: string;
    textColor?: string;
    hoverBackgroundColor?: string;
    hoverTextColor?: string;
    firstChildPaddingTop?: string;
    firstChildPaddingBottom?: string;
    borderBottom?: string;
    width?: string;
    backgroundColor?: string;
  }
  scrollbarPosition?: string;
}

interface IArrowIconProps {
  openlist: string;
}

interface IClearIconProps {
  show: string;
}

export const StyledSection = styled.div<IInputProps>`
  width: ${(props) => props.width ? props.width : '100%'};
  margin: ${(props) => props.margin ? props.margin : '10px 0'};
  position: relative; 
  display: flex;  
`;

export const StyledSectionInput = styled.input<IDetailedInputProps>`
  
  margin: 0;
  padding: ${(props) => (props.inputStyles && props.inputStyles.padding) ? props.inputStyles.padding : '5px 10px'};
  width: 100%;  
  border: ${({ inputStyles }) => inputStyles?.border || '2px solid #304F5A'};
  border-radius: ${(props) => (props.inputStyles && props.inputStyles.borderRadius)
    ? props.inputStyles.borderRadius : '0'};   
  font-family: ${(props) => (props.inputStyles && props.inputStyles.fontFamily)
    ? props.inputStyles.fontFamily : 'Cairo, Verdana, Geneva, Tahoma, sans-serif'};
  font-size: ${(props) => (props.inputStyles && props.inputStyles.fontSize) ? props.inputStyles.fontSize : 14}px; 
  color: ${(props) => (props.inputStyles && props.inputStyles.textColor) ? props.inputStyles.textColor : '#707070'};
  background-color: ${(props) => (props.inputStyles && props.inputStyles.backgroundColor)
    ? props.inputStyles.backgroundColor : 'lightgray'};
  text-align: ${(props) => (props.inputStyles && props.inputStyles.textAlign) ? props.inputStyles.textAlign : 'left'};
  transition: background-color .2s linear; 
  font-weight: ${(props) => (props.inputStyles && props.inputStyles.fontWeight)
    ? props.inputStyles.fontWeight : 'normal'};  
  box-shadow: ${(props) => (props.inputStyles && props.inputStyles.boxShadow)
    ? props.inputStyles.boxShadow : 'none'};
  
  &:focus {
    outline: none;
    background-color: #FFFFFF; 
    border: ${({ inputStyles }) => inputStyles?.focusBorder || '2px solid #8DC63F'};  
    color: ${(props) => (props.inputStyles && props.inputStyles.focusTextColor)
    ? props.inputStyles.focusTextColor : '#707070'};
  }

  &::selection {
      background-color: #FFFFFF;
      color: white;      
  }    

  &::placeholder {
    font-weight: ${(props) => (props.inputStyles && props.inputStyles.fontWeight)
    ? props.inputStyles.fontWeight : 'normal'};
  }
  
`;

const getMaxHeight = (props: IListContainerProps) => {
  if (props.listContainerStyles && props.listContainerStyles.maxHeight) return props.listContainerStyles.maxHeight;

  return 'none';
};

const getContainerDirection = (props: IListContainerProps) => {
  if (props.scrollbarPosition === 'left') return 'rtl';

  return 'ltr';
};

export const StyledListContainer = styled.ul<IListContainerProps>`

  position: absolute;
  top: 100%;
  left: 0;
  width: calc(${({ listContainerStyles }) => listContainerStyles?.width || '380px'} - 2px);
  max-height: ${(props) => getMaxHeight(props)};
  background-color: white;
  font-family: Cairo, Verdana, Geneva, Tahoma, sans-serif;
  font-size: ${({ listContainerStyles }) => listContainerStyles?.fontSize || 14}px; 
  z-index: 30;
  display: ${({ openList }) => openList ? 'block' : 'none'};
  overflow-y: scroll;
  padding: 0;  
  margin: 0;
  list-style: none;  
  direction: ${(props: IListContainerProps) => getContainerDirection(props)};
  scrollbar-width: 5px;
  scrollbar-track-color: #E4E4E4;
  scrollbar-base-color: #9F9F9F;    
  font-weight: bold;
  border: 1px solid #8DC63F;
  border-top: none;
`;

const getItemDirection = (props: IListItemsProps) => {
  if (props.scrollbarPosition === 'left') return 'ltr';

  return 'rtl';
};

export const StyledListItem = styled.li<IListItemsProps>`
  font-weight: 500;
  font-family: Cairo;
  padding: 0;
  margin: ${(props) => (props.listItemsStyles && props.listItemsStyles.margin) ? props.listItemsStyles.margin : '0'};
  padding: ${(props) => (props.listItemsStyles && props.listItemsStyles.padding)
    ? props.listItemsStyles.padding : '5px 0 5px 20px'};
  color: ${(props) => (props.listItemsStyles && props.listItemsStyles.textColor)
    ? props.listItemsStyles.textColor : '#707070'};
  border-bottom: ${(props) => (props.listItemsStyles && props.listItemsStyles.borderBottom)
    ? props.listItemsStyles.borderBottom : 'none'};
  width: ${(props) => (props.listItemsStyles && props.listItemsStyles.width) ? props.listItemsStyles.width : '100%'};
  direction: ${(props) => getItemDirection(props)};
  text-align: left;
  background-color: ${(props) => (props.listItemsStyles && props.listItemsStyles.backgroundColor)
    ? props.listItemsStyles.backgroundColor : 'transparent'};
  
  &:hover {

    background-color: ${(props) => (props.listItemsStyles && props.listItemsStyles.hoverBackgroundColor)
    ? props.listItemsStyles.hoverBackgroundColor : '#707070'};
    color: ${(props) => (props.listItemsStyles && props.listItemsStyles.hoverTextColor)
    ? props.listItemsStyles.hoverTextColor : 'white'};
    cursor: pointer;

  }

  &:first-child {
    padding-top: ${(props) => (props.listItemsStyles && props.listItemsStyles.firstChildPaddingTop)
    ? props.listItemsStyles.firstChildPaddingTop : '10px'};
  }

  &:last-child {
    padding-bottom: ${(props) => (props.listItemsStyles && props.listItemsStyles.firstChildPaddingBottom)
    ? props.listItemsStyles.firstChildPaddingBottom : '10px'};
    border-bottom: none;
  }

`;

export const StyledNoResultsItem = styled.li<IListItemsProps>`
  padding: 0;
  margin: ${({ listItemsStyles }) => listItemsStyles?.margin || '0'};
  padding: ${({ listItemsStyles }) => listItemsStyles?.padding || '5px 0 5px 20px'};
  padding-top:  ${({ listItemsStyles }) => listItemsStyles?.firstChildPaddingTop || '10px'};
  padding-bottom: ${({ listItemsStyles }) => listItemsStyles?.firstChildPaddingBottom || '10px'};
  color: ${({ listItemsStyles }) => listItemsStyles?.textColor || '#000'};
  direction: ${(props) => getItemDirection(props)};
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  background-color: ${({ listItemsStyles }) => listItemsStyles?.backgroundColor || 'transparent'};
`;

export const StyledArrowIcon = styled(ArrowDropDownIcon)<IArrowIconProps | any>`

  && {
    position: absolute;
    right: 10px;
    top: 7px;  
    color: #707070;
    transform: ${(props) => (props.openlist === 'true') ? 'rotate(180deg)' : 'none'};
  } 

  &:hover {

    cursor: pointer;
  }
`;

export const StyledClearIcon = styled(HighlightOffIcon)<IClearIconProps | any>`

  && {

    position: absolute;
    right: 50px;
    top: 9px;
    color: gray;  
    display: ${(props) => props.show === 'true' ? 'block' : 'none'};

  }

  &:hover {

    cursor: pointer;
  }

  
`;
