import styled from 'styled-components';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Popover, Divider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

interface CardProps {
  selected: boolean;
}

export const StyledContainer = styled.div<CardProps>`
  
  && {
    margin: 0;  
    width:100%;
    box-sizing: border-box;
    padding: 10px;
    background-color: ${(props) => props.selected ? '#304F5A' : '#FFFFFF'};
    color: ${(props) => props.selected ? '#FFFFFF' : '#000000'};
    font-family: Cairo;
    position: relative;
    cursor: ${(props) => props.selected ? 'initial' : 'pointer'};
    border: 1px solid #F0F0F0;
    border-bottom: 2px solid #F0F0F0;
    position: ${(props) => props.selected ? 'sticky' : 'relative'};
    position: ${(props) => props.selected ? '-webkit-sticky;' : 'relative'};
    top: 0;
    bottom: 0;
    z-index: ${(props) => props.selected ? 1 : 0};
  }

  &:hover {
    background-color: ${(props) => props.selected ? '#304F5A' : '#E5E5E5'};
    color: ${(props) => props.selected ? '#FFFFFF' : '#000000'};
  }
`;

export const StyledTypeTitle = styled.h5`

  padding: 0;
  margin: 0;
  padding-left: 5px;
  width: 100%;
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 28px;
  text-transform: capitalize;
`;

export const StyledArrow = styled(PlayArrowIcon)`

  && {    
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    width: 20px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {    
    position: absolute;
    padding: 4px;
    top: 8px;
    right: 10px;
    color: ${(props) => props.color === 'primary' ? 'white' : 'black'};
  }

  &:hover{
    cursor: pointer;
    background-color: ${(props) => props.color === 'primary' ? '#FFFFFF5C' : '#0000004d'}
  }

`;

export const StyledTagsContainer = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 5px;
  width: 100%;
  min-height: 30px;
  max-height: 60px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  box-sizing: border-box;
  position: relative;
`;

export const StyledTag = styled.span<CardProps>`
  display: block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  color: ${(props) => props.selected ? '#FFFFFF' : '#000000'};
  padding: 0px 5px;
  margin: 2px 2px;
  font-family: Cairo;
  font-style: initial;
  font-weight: normal;
  font-size: 12px;
`;

export const RightClickMessage = styled(Popover)`

  .MuiPopover-paper {
      background: #757575;
      color: white;
      padding: 5px 10px;
  }

  div{
    display: flex;
    align-items: center;
  }

  span{
    font-size: 1rem;
    margin-left: 5px;
  }

  &:hover{
    cursor: pointer;
  }
`;

export const DeleteConfirmDlg = styled(Popover)`
  .MuiPopover-paper {    
    padding:10px 5px;
  }
`;

export const Question = styled.div`
  font-size: 12px;
  color: #9F9F9F;
  margin: 5px;
`;
export const PositionName = styled.div`
  background: #9F9F9F;
  color: white;
  padding: 5px;
  font-size: 0.8rem;
`;

export const BtnWrap = styled.div`
  float: right;
  color: #9F9F9F;
  margin: 5px;
  font-weight: bold;

  span{
    margin: 0px 5px;
    &:hover{
      cursor: pointer;
    }
  }
  #confirm{
    color: #8DC63F;
  }
`;

export const StyledCircle = styled.div<CardProps>`
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5em;
  padding: 3px;
  background-color: ${(props) => props.selected ? 'white' : 'black'};
  color: ${(props) => props.selected ? 'black' : 'white'};
`;

export const StyledLabelInCircle = styled.div`
  text-align-last: center;
`;

export const StyledDivider = styled(Divider)`
  background-color: ${(props) => props.color === 'primary' ? 'white' : '#304F5A'};
  height: 1rem;
  margin-top: 6px;
`;
