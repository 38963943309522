import React from 'react';
import {
  LSN, UL, Border, ListWrap,
} from './style';

function Institution(props: any) {
  return (
    <ListWrap>
      <UL>
        {props.institutions_or_positions_or_affiliations.length > 0
          ? props.institutions_or_positions_or_affiliations.map((item: any) => (
            <div key={item}>
              <LSN>{item}</LSN>
              <Border />
            </div>
          )) : '-'}
      </UL>
    </ListWrap>
  );
}

export default Institution;
