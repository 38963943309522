import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';

interface IProps {
  $isOpen: boolean
}

export const StyledContainer = styled.div<IProps>`
  width: 100%;
  background-color: white;
  height: 100%;
`;

export const StyledToolbar = styled.div<IProps>`
  width: 100%;  
  display: flex;
  flex-flow: ${(props) => props.$isOpen ? 'row nowrap' : 'column nowrap'};
  align-items: ${(props) => props.$isOpen ? 'flex-start' : 'flex-end'};   
`;

export const StyledButtonContainer = styled.span<IProps>`

  flex: ${(props) => props.$isOpen ? '1 1 100%' : '0 0 82px'};
  width: ${(props) => props.$isOpen ? 'auto' : '82px'};
  display: ${(props) => props.$isOpen ? 'none' : 'flex'};  
  height: 82px;
  justify-content: center;
  align-items: center;
  background-color: #D7D7D7;   
  

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSearchIcon = styled(SearchIcon)`

  && {
    font-size: 34px;
    color: #757575;
  }
`;

export const StyledContentContainer = styled.div<IProps>`

  width: 100%;
  background-color: white;
  height: 100%;
  display: ${(props) => props.$isOpen ? 'block' : 'none'};  
`;

export const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
  height: 90%;
  width: 90%;
  margin: 28px auto;
`;
interface SearchProjectStyles {
  input: {
    maxWidth?: string;
    margin?: string;
    searchIconSize?: string;
    closeIconSize?: string;
  };
  listContainer: {
    width?: string;
    maxHeight?: string;
    backgroundColor?: string;
    borderRadius?: string;
    boxShadow?: string;
    fontFamily?: string;
    fontSize?: number;
    border?: string;
  };
  listItems: {
    padding?: string;
    margin?: string;
    textColor?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    hoverTextColor?: string;
    firstChildPaddingTop?: string;
    firstChildPaddingBottom?: string;
    borderBottom?: string;
    width?: string;
  };
}

export const searchProjectStyles: SearchProjectStyles = {
  input: {
    maxWidth: '90%',
    margin: '10px auto 0 auto',
  },
  listContainer: {
    width: '100%',
    maxHeight: '200px',
    backgroundColor: '#FFFFFF',
    borderRadius: '0',
    boxShadow: 'none',
    fontFamily: 'Cairo, Verdana, Geneva, Tahoma, sans-serif',
    fontSize: 12,
    border: '2px solid #8DC63F',
  },
  listItems: {
    padding: '8px 0 8px 20px',
    margin: '0 2% 0 2%',
    textColor: '#000000',
    hoverBackgroundColor: '#304F5A',
    hoverTextColor: '#FFFFFF',
    firstChildPaddingTop: '10px',
    firstChildPaddingBottom: '10px',
    borderBottom: '1px solid #F0F0F0',
    width: '90%',
  },
};
