import React, { useState } from 'react';

export interface CourtCaseContextType {
  courtCases: CourtCaseType[];
  setCourtCases: (courtCases: CourtCaseType[]) => void;
  isLoadingCourtCases: boolean
  setIsLoadingCourtCases: (isLoading: boolean) => void
  selectedCourtCaseId: string | null;
  setSelectedCourtCaseId: (projectId: string | null) => void;
}

export const CourtCaseContext = React.createContext<CourtCaseContextType>({
  courtCases: [],
  setCourtCases: (courtCases: CourtCaseType[]) => courtCases,
  isLoadingCourtCases: true,
  setIsLoadingCourtCases: (isLoading: boolean) => isLoading,
  selectedCourtCaseId: null,
  setSelectedCourtCaseId: (id: string | null) => id,
});

interface IProps {
  children: React.ReactNode;
}

const CourtCaseContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [courtCases, setCourtCases] = useState<CourtCaseType[]>([]);
  const [isLoadingCourtCases, setIsLoadingCourtCases] = useState<boolean>(true);
  const [selectedCourtCaseId, setSelectedCourtCaseId] = useState<string | null>(null);

  const contextValue = {
    courtCases,
    setCourtCases,
    isLoadingCourtCases,
    setIsLoadingCourtCases,
    selectedCourtCaseId,
    setSelectedCourtCaseId,
  };

  return (
    <CourtCaseContext.Provider value={contextValue}>
      {children}
    </CourtCaseContext.Provider>
  );
};

export default CourtCaseContextProvider;
