import React, { useContext, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import stc from 'string-to-color';
import KeystoneLogo from '../../assets/svg/KeystoneLogo.svg';
import { SidebarsContext, SidebarsContextType } from '../../context/sidebarsContext';
import { useResize } from '../../utils/hooks';

import {
  StyledContainer,
  StyledLeftSideContainer,
  StyledName,
  StyledKeystoneIcon,
  StyledArea2Container,
  IItemStyleProps,
  StyledTagContainer,
  StyledTag,
} from './style';

interface IProps {
  item: ExpertType;
  handleExpertSelected?: (expertId: string) => void;
  itemStyles?: IItemStyleProps;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  view: 'right' | 'modal' | 'center';
  isDisabled?: boolean;
}

const ExpertRow: React.FC<IProps> = (props: IProps) => {
  const tagContainerRef = useRef<HTMLDivElement>(null);

  const sidebarsState = useContext<SidebarsContextType>(SidebarsContext);
  const getWidthTags = () => {
    const rightSidebar = sidebarsState.isRightSidebarOpen ? 218 : 0;
    const leftSidebar = sidebarsState.isLeftSidebarOpen ? 0 : 318;
    const sizeTagsContainer = useResize(tagContainerRef);
    let value = sizeTagsContainer[0];
    if (props.view === 'center') {
      value += leftSidebar;
      value -= rightSidebar;
    }

    return value;
  };
  const widthTags = getWidthTags();
  const expert = props.item;

  const handleExpertRow = () => {
    if (!props?.handleExpertSelected) return;
    props.handleExpertSelected(expert._id);
  };

  const getIsKeystoneExpert = () => {
    if (!expert) return false;
    return expert.is_keystone_expert as boolean;
  };

  const is_keystone_expert = getIsKeystoneExpert();

  const renderTags = (subject_matter_tags: string[], width: number) => {
    let numberLetters = 0;
    let tagsLeft = 0;
    let subjectsLeft = '';
    const thresholdOfLetters = 12;
    let limit = width;
    if (is_keystone_expert) {
      limit -= 251;
    }
    return (
      <>
        {subject_matter_tags.map((subject, idx) => {
          numberLetters += 100 + 12;
          if (numberLetters >= limit) {
            tagsLeft = Math.max(tagsLeft, subject_matter_tags.length - idx);
            subjectsLeft += `${subject}, `;
            return null;
          }
          return (
            <>
              {subject.length >= thresholdOfLetters ? (
                <Tooltip title={subject}>
                  <StyledTag backgroundColor={stc(`${subject}`)}>{subject}</StyledTag>
                </Tooltip>
              ) : (
                <StyledTag backgroundColor={stc(`${subject}`)}>{subject}</StyledTag>
              )}
            </>
          );
        })}
        {tagsLeft > 0 && (
          <Tooltip title={subjectsLeft.substring(0, subjectsLeft.length - 2)}>
            <StyledTag>{`+${tagsLeft}`}</StyledTag>
          </Tooltip>
        )}
      </>
    );
  };
  return (
    <StyledContainer
      itemStyles={props?.itemStyles}
      onClick={handleExpertRow}
      isDisabled={props.isDisabled}
    >
      <StyledLeftSideContainer>
        {props.leftIcon}
        <StyledName onClick={(e) => e.stopPropagation()}>
          {`${getFullName(expert?.firstName ?? '', expert?.lastName ?? '')}`}
        </StyledName>
        {is_keystone_expert && <StyledArea2Container><StyledKeystoneIcon src={KeystoneLogo} /></StyledArea2Container>}
      </StyledLeftSideContainer>
      <StyledTagContainer ref={tagContainerRef}>
        {renderTags(expert?.subject_matter_tags ?? [], widthTags)}
      </StyledTagContainer>
      {props.rightIcon}
    </StyledContainer>
  );
};

const getFullName = (firstName: string, lastName: string) => {
  const fullName = `${firstName} ${lastName}`;
  if (fullName.length >= 19) {
    return `${firstName[0]}. ${lastName}`;
  }
  return fullName;
};

export default ExpertRow;
