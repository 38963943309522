export const titleCase = (input: string) => {
  if (!input) return input;
  const splitStr = input.toLowerCase().split(' ');

  const results = splitStr.map((item: string) => {
    return `${item.charAt(0).toUpperCase()}${item.substring(1)}`;
  });

  return results.join(' ');
};

export const escapeCommas = (input: string | undefined) => {
  if (!input) return '';
  return input.trim().replaceAll(',', '","');
};

export const escapeSpecialChars = (input: string | undefined) => {
  if (!input) return '';
  let result = input;
  result = result.replaceAll('#', '*');
  result = result.replaceAll('é', 'e');
  result = result.replaceAll('á', 'a');
  result = result.replaceAll('í', 'i');
  result = result.replaceAll('ó', 'o');
  result = result.replaceAll('ú', 'u');

  return result;
};
