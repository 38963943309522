import { gql } from '@apollo/client';

export const GET_EXPERT_BY_ID = gql`
  query GetExpertByID($expertId: ID!) {
    expert_getById(expertId: $expertId) {
      _id
      accomplishments
      bio
      bio_url
      education_or_background
      expertise_ids
      expertises
      expert_type
      expert_type_id
      firstName
      institutions_or_positions_or_affiliations
      is_keystone_expert
      lastName
      location
      overview_or_notes
      projects
      project_ids
      rate
      relationship_manager_eta
      relationship_manager_eta_id
      relationship_manager_strategy
      relationship_manager_strategy_id
      relationship_manager_support
      relationship_manager_support_id
      subject_matter_tags
      patents
      papers
    }
  }
`;

export const GET_EXPERTS_BY_NAME = gql`
  query GetExpertsByName($expertName: String!, $cursor: String){
    expert_findByName(name: $expertName, cursor: $cursor){
      edges {
        node {
          _id
          accomplishments
          bio
          bio_url
          education_or_background
          expertise_ids
          expertises
          expert_type
          expert_type_id
          firstName
          institutions_or_positions_or_affiliations
          is_keystone_expert
          lastName
          location
          overview_or_notes
          projects
          project_ids
          rate
          relationship_manager_eta
          relationship_manager_eta_id
          relationship_manager_strategy
          relationship_manager_strategy_id
          relationship_manager_support
          relationship_manager_support_id
          subject_matter_tags
          patents
          papers
        }
        cursor
      }
    }
  }
`;
