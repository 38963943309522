import styled from 'styled-components';

interface IContainer {
  showSearch: boolean;
}

export const StyledContainer = styled.div`
  width: 97%;
  margin: 0 auto;
  height: 100%;  
`;

export const StyledProjectInfoContainer = styled.div<IContainer>`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0 0;
  background-color: white;  
  margin-top: 20px;
  overflow-y: scroll;
`;

export const StyledSearchContainer = styled.div`  
  grid-area: body; 
  display: flex;
  flex-flow: column nowrap;       
  height: 100%;
`;
