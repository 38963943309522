import React, { useEffect } from 'react';

import {
  StyledFlexColumn,
  StyledLinksFlexColumn,
  StyledLink,
  StyledRowTitle,
  StyledArrowIcon,
  StyledKeywordsRowTitle,
} from './style';

interface ICellRendererProps {
  item: any;
  setRowOpen: (value: boolean) => void;
  isRowOpen: boolean;
  getFilingStatus: (secFiling: DisplayFilingType) => 'TRACKED' | 'REJECTED' | 'RAW';
  selectedTrackedCase: FilingStatusType | null;
  fieldName: string;
  styleProps: {
    width: string;
    padding: string;
    textAlign: string;
    textColor: string;
    fontSize: string;
    fontWeight: string;
  }
}

const CellRenderer: React.FC<ICellRendererProps> = (props: ICellRendererProps) => {
  useEffect(() => {
    if (!props.selectedTrackedCase) return;

    if (props.selectedTrackedCase.companyId === props.item.companyId
      && props.selectedTrackedCase.filingId === props.item.filingId
    ) {
      props.setRowOpen(true);
    }
  }, [props.selectedTrackedCase]);

  const toggleOpenHandler = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (props.isRowOpen) return props.setRowOpen(false);

    return props.setRowOpen(true);
  };

  const getKeywordsFromFiling = (filing: DisplayFilingType) => {
    const keywords = filing.filingDisplayMatchingKeywords.split(' + ');

    return keywords ? keywords : [];
  };

  // eslint-disable-next-line
  const expandArrowTestId = `propects-secFilings-expandablerGrid-arrow-${props.item.companyId}-${props.item.filingId}`;

  const tickersValue = (props.item.tickers as string[]).join(', ');

  const displayCell = () => {
    switch (props.fieldName) {
      case 'company_display_name':
        return (
          <StyledFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            filingStatus={props.getFilingStatus(props.item)}
            padding={props.styleProps.padding}
          >
            <StyledRowTitle
              textAlign={props.styleProps.textAlign}
              textColor={props.styleProps.textColor}
              fontSize={props.styleProps.fontSize}
              fontWeight={props.styleProps.fontWeight}
              fontFamily="Cairo"
            >
              {props.item[props.fieldName]}
            </StyledRowTitle>
            <StyledArrowIcon
              $isOpen={props.isRowOpen}
              onClick={toggleOpenHandler}
              data-testid={expandArrowTestId}
            />
          </StyledFlexColumn>
        );

        break;

      case 'tickers':
        return (
          <StyledFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            filingStatus={props.getFilingStatus(props.item)}
            padding={props.styleProps.padding}
          >
            <StyledRowTitle
              textAlign={props.styleProps.textAlign}
              textColor={props.styleProps.textColor}
              fontSize={props.styleProps.fontSize}
              fontWeight={props.styleProps.fontWeight}
              fontFamily="Cairo"
            >
              {tickersValue}
            </StyledRowTitle>
          </StyledFlexColumn>
        );

        break;

      case 'filingForm':
        return (
          <StyledLinksFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            filingStatus={props.getFilingStatus(props.item)}
            padding={props.styleProps.padding}
          >
            <StyledLink
              href={props.item.filingUrl}
              target="_blank"
            >
              <StyledRowTitle
                textAlign={props.styleProps.textAlign}
                textColor={props.styleProps.textColor}
                fontSize={props.styleProps.fontSize}
                fontWeight={props.styleProps.fontWeight}
                fontFamily="Cairo"
              >
                {props.item[props.fieldName]}
              </StyledRowTitle>
            </StyledLink>
          </StyledLinksFlexColumn>
        );

        break;

      case 'latest_filed':
        return (
          <StyledFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            filingStatus={props.getFilingStatus(props.item)}
            padding={props.styleProps.padding}
          >
            <StyledRowTitle
              textAlign={props.styleProps.textAlign}
              textColor={props.styleProps.textColor}
              fontSize={props.styleProps.fontSize}
              fontWeight={props.styleProps.fontWeight}
              fontFamily="Cairo"
            >
              {props.item[props.fieldName]}
            </StyledRowTitle>
          </StyledFlexColumn>
        );

        break;

      case 'filingDisplayMatchingKeywords':
        return (
          <StyledFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            filingStatus={props.getFilingStatus(props.item)}
            padding={props.styleProps.padding}
          >
            <StyledKeywordsRowTitle
              textAlign={props.styleProps.textAlign}
              textColor={props.styleProps.textColor}
              fontSize={props.styleProps.fontSize}
              fontWeight={props.styleProps.fontWeight}
              fontFamily="Cairo"
            >
              {getKeywordsFromFiling(props.item).map((keyword: string) => {
                return (
                  <span key={keyword}>{keyword}</span>
                );
              })}
            </StyledKeywordsRowTitle>
          </StyledFlexColumn>
        );

        break;
      default:

        return null;
    }
  };

  return (
    <>
      {displayCell()}
    </>
  );
};

export default CellRenderer;
