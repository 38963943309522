import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  StyledContainer,
  StyledButtonContainer,
  StyledDoubleArrowIcon,
} from './style';

import ExpertsContent from './expertContent';
import ProspectsContent from './prospectsContent';

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  projects: ProjectType[];
  selectedProjectId: string | null;
  setSelectedProjectId: (id: string | null) => void;
  selectedExpertSearches: ExpertSearchType[];
  setSelectedExpertSearches: (searchList: ExpertSearchType[]) => void;
  selectedExpertSearchId: string | null;
  setSelectedExpertSearchId: (id: string | null) => void;
  setSelectedExpertSearchResults: (expertSearchResults: ExpertSearchResultType[]) => void;
  setSelectedCourtCaseId: (id: string | null) => void
  expertsShortlisted: ExpertShortlistedType[]
  setExpertsShortlisted: (expertsShortlisted: ExpertShortlistedType[]) => void
  setUpdateProject: (value: boolean) => void;
}

const LeftSideBar: React.FC<IProps> = (props: IProps) => {
  const location = useLocation();

  const prospectRoutes = ['/prospector'];

  const otherRoutes = ['/experts', '/projects'];

  const handleButtonClick = () => {
    if (props.isOpen) return props.setIsOpen(false);
    return props.setIsOpen(true);
  };

  const displaySidebarContent = () => {
    const currentLocation = location.pathname;

    if (prospectRoutes.some((item: string) => currentLocation.includes(item))) {
      return (
        <ProspectsContent
          isOpen={props.isOpen}
          setSelectedCourtCaseId={props.setSelectedCourtCaseId}
        />
      );
    }
    if (otherRoutes.some((item: string) => currentLocation.includes(item))) {
      return (
        <ExpertsContent
          isOpen={props.isOpen}
          projects={props.projects}
          selectedProjectId={props.selectedProjectId}
          setSelectedProjectId={props.setSelectedProjectId}
          selectedExpertSearches={props.selectedExpertSearches}
          setSelectedExpertSearches={props.setSelectedExpertSearches}
          selectedExpertSearchId={props.selectedExpertSearchId}
          setSelectedExpertSearchId={props.setSelectedExpertSearchId}
          setSelectedExpertSearchResults={props.setSelectedExpertSearchResults}
          setUpdateProject={props.setUpdateProject}
          expertsShortlisted={props.expertsShortlisted}
          setExpertsShortlisted={props.setExpertsShortlisted}
        />
      );
    }

    return null;
  };

  return (
    <StyledContainer $isOpen={props.isOpen}>
      <StyledButtonContainer
        onClick={handleButtonClick}
      >
        <StyledDoubleArrowIcon $isOpen={props.isOpen} />
      </StyledButtonContainer>
      {displaySidebarContent()}
    </StyledContainer>
  );
};

export default LeftSideBar;
