import { gql } from '@apollo/client';

export const GET_EXPERT_SHORTLISTED_BY_PROJECT = gql`
  query GetExpertShortlistedByProject($projectId: ID!) {
    expertShortlisted_getByProject(projectId: $projectId) {
      shortlist {
        _id
        expertId
        searchId
      }
      experts {
        _id
        accomplishments
        bio
        bio_url
        education_or_background
        expertise_ids
        expertises
        expert_type
        expert_type_id
        firstName
        institutions_or_positions_or_affiliations
        is_keystone_expert
        lastName
        location
        overview_or_notes
        projects
        project_ids
        rate
        relationship_manager_eta
        relationship_manager_eta_id
        relationship_manager_strategy
        relationship_manager_strategy_id
        relationship_manager_support_id
        relationship_manager_support_id
        subject_matter_tags
        patents
        papers
      }      
    }
  }
`;
