import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import CustomTag from '../../../../../components/customTag';
import {
  StyledContainer,
  StyledInfoIcon,
  StyledLabel,
  StyledAddExpertButton,
  StyledAddExpertIcon,
  StyledIdealContainer,
} from './style';
import ModalWrapper from '../../../../../components/modalWrapper';
import AddExpertModal from '../../../../../components/addExpertModal';
import * as expertService from '../../../../../entities/expert/service';

interface IProps {
  isSelectedSearchEditable: boolean;
  selectedExpertSearchId: string | null;
  setEditedExpertSearch: (expertise: ExpertSearchEditType | null) => void;
  editedExpertSearch: ExpertSearchEditType | null;
  getExpert: (expertId: string) => ExpertType | null;
  expertSearch?: ExpertSearchType;
}

const ExpertiseIdeal: React.FC<IProps> = (props: IProps) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSearch = async (name: string, cursor: string | null) => {
    const paginationExpert = await expertService.fetchExpertsByName(name, cursor);
    return paginationExpert;
  };

  const handleAdd = async (idealExpertIds: string[]) => {
    if (!props.selectedExpertSearchId) return;
    if (!props.editedExpertSearch) return;
    const filteredIdealExpertIds = idealExpertIds.filter(
      (expId) => !props.editedExpertSearch?.idealExpertIds.includes(expId),
    );
    props.setEditedExpertSearch({
      ...props.editedExpertSearch,
      idealExpertIds: [
        ...props.editedExpertSearch.idealExpertIds,
        ...filteredIdealExpertIds,
      ],
    });
  };

  const handleDeleteIdealExpertTag = (id?: string) => {
    if (!id || !props.editedExpertSearch) return;

    const newIdealExpertIds = (props.editedExpertSearch?.idealExpertIds ?? []).filter((x) => !x.includes(id));
    props.setEditedExpertSearch({
      ...props.editedExpertSearch,
      idealExpertIds: newIdealExpertIds,
    });
  };

  const displayIdealTags = () => {
    const expSearch = props.editedExpertSearch ?? props.expertSearch;
    const selectedExpertiseIds: ExpertType[] = expSearch?.idealExpertIds
      .map((expId) => props.getExpert(expId))
      .filter((exp) => exp !== null) as ExpertType[] ?? [];

    if (selectedExpertiseIds.length === 0) {
      return (
        <h3 style={{ margin: '6px 0px 0px 0px', fontWeight: 'lighter' }}>
          No info added
        </h3>
      );
    }
    const thresholdOfWords = 3; // Threshold of expertiseTypes allowed
    const remainingExpertiseIdeals = selectedExpertiseIds.length - thresholdOfWords;
    return (
      <>
        {selectedExpertiseIds.map((expertiseIdealObject: ExpertType | null, idx: number) => {
          const id = expertiseIdealObject?._id;
          const name = `${expertiseIdealObject?.firstName} ${expertiseIdealObject?.lastName}`;
          return (idx < thresholdOfWords && (
          <CustomTag
            key={id}
            title={name}
            handleDeleteIconClick={props.editedExpertSearch ? () => handleDeleteIdealExpertTag(id) : null}
            styles={{
              backgroundColor: '#D7D7D7',
              borderRadius: 0,
              color: 'black',
              margin: '3px 2px 3px 2px',
              padding: '0',
              fontSize: 10,
              fontFamily: 'Cairo',
            }}
          />
          )
          );
        })}
        {remainingExpertiseIdeals > 0 && (
          <CustomTag
            title={`+${remainingExpertiseIdeals}`}
            handleDeleteIconClick={null}
            styles={{
              backgroundColor: '#828282',
              borderRadius: 0,
              color: 'white',
              margin: '3px 2px 3px 2px',
              padding: '0',
              fontSize: 12,
              fontFamily: 'Cairo',
              fontWeight: 'bold',
            }}
          />
        )}
      </>
    );
  };

  return (
    <StyledContainer>
      <StyledLabel>Ideal Experts:</StyledLabel>
      <Tooltip title="Profiles similar to what you are looking for">
        <StyledInfoIcon />
      </Tooltip>
      {props.isSelectedSearchEditable && (
        <>
          <StyledAddExpertButton
            onClick={() => setOpenModal(true)}
          >
            <StyledAddExpertIcon />
            Add expert
          </StyledAddExpertButton>
        </>
      )}
      <StyledIdealContainer>
        {displayIdealTags()}
      </StyledIdealContainer>
      <ModalWrapper isModalOpen={openModal} handleModal={() => setOpenModal(false)}>
        <AddExpertModal
          onCancel={() => setOpenModal(false)}
          handleSearch={handleSearch}
          handleAdd={handleAdd}
          selectedExpertIds={props.editedExpertSearch?.idealExpertIds ?? []}
        />
      </ModalWrapper>
    </StyledContainer>
  );
};

export default ExpertiseIdeal;
