import React, { useState } from 'react';

export interface ExpertiseContextType {
  expertises: ExpertiseType[];
  setExpertises: (expertises: ExpertiseType[]) => void
  expertTypes: ExpertTypeType[];
  setExpertTypes: (expertTypes: ExpertTypeType[]) => void
  fetchError: Error | null;
  setFetchError: (error: Error) => void
}

export const ExpertiseContext = React.createContext<ExpertiseContextType>({
  expertises: [],
  setExpertises: () => {},
  expertTypes: [],
  setExpertTypes: () => {},
  fetchError: null,
  setFetchError: () => {},
});

interface IProps {
  children: React.ReactNode;
}

const ExpertiseContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [expertises, setExpertises] = useState<ExpertiseType[]>([]);
  const [expertTypes, setExpertTypes] = useState<ExpertTypeType[]>([]);
  const [fetchError, setFetchError] = useState<Error | null>(null);

  const contextValue = {
    expertises,
    setExpertises,
    expertTypes,
    setExpertTypes,
    fetchError,
    setFetchError,
  };

  return (
    <ExpertiseContext.Provider value={contextValue}>
      {children}
    </ExpertiseContext.Provider>
  );
};

export default ExpertiseContextProvider;
