import _ from 'lodash';
import { GET_ALL_TRACKED_FILING_STATUSES } from './query';
import { UPSERT_FILING_STATUS_BY_FILING } from './mutation';
import client from '../../../communication/client';

export const fetchAllTrackedFilingStatuses = async () => {
  const response = await client.query({
    query: GET_ALL_TRACKED_FILING_STATUSES,
    fetchPolicy: 'network-only',
  });
  return _.get(response, 'data.filingStatus_getAllTracked', null);
};

export const upsertFilingStatus = async (
  companyId: string,
  filingId: string,
  status: 'TRACKED' | 'REJECTED' | 'RAW',
) => {
  const response = await client.mutate({
    mutation: UPSERT_FILING_STATUS_BY_FILING,
    variables: {
      companyId,
      filingId,
      status,
    },
  });

  return _.get(response, 'data.filingStatus_upsertByFiling', false);
};
