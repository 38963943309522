import React from 'react';
import Chip from '@material-ui/core/Chip';

import {
  useStyles,
} from './style';

interface IProps {
  styles: {
    backgroundColor?: string;
    color?: string;
    border?: string;
    padding?: string;
    margin?: string;
    fontFamily?: string;
    fontSize?: number;
    fontWeight?: string;
    boxShadow?: string;
    borderRadius?: number;
  }
  title: string;
  handleDeleteIconClick?: (() => void) | null;
}

const CustomTag: React.FC<IProps> = (props: IProps) => {
  const classes: any = useStyles(props.styles);

  return (
    <Chip
      onDelete={props.handleDeleteIconClick !== null ? props.handleDeleteIconClick : undefined}
      classes={{ root: classes.root, deleteIcon: classes.deleteIcon, label: classes.label }}
      label={props.title.toUpperCase()}
    />
  );
};

export default CustomTag;
