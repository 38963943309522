import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';

export const StyledContainer = styled.div`
  grid-area: body;
  padding: 0;  
  padding-left: 15px;
  padding-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface IFlexSectionProps {
  margin?: string;
}

export const StyledFlexDocketNumberSection = styled.div<IFlexSectionProps>`
  box-sizing: border-box;
  width: 100%;
  display: flex;  
  justify-content: flex-start;
  align-items: flex-start; 
  margin: ${(props) => props.margin ? props.margin : '0'};     
`;

interface IColProps {
  width?: string;
  paddingRight?: string;
}

export const StyledSectionCol = styled.div<IColProps>`
  box-sizing: border-box;
  flex: ${(props) => props.width ? `0 1 ${props.width}` : '0 1 47%'};
  padding-right: ${(props) => props.paddingRight ? props.paddingRight : '0'};
`;

export const StyledSectionTitle = styled.h3`
  margin: 0;
  padding: 0;  
  color: #9F9F9F;
  font-size: 14px;
  font-weight: bold;
  width: 150px;
  border-bottom: 1px solid #9F9F9F;
  margin-bottom: 15px;
  padding: 10px 0;
`;

export const inputStyles = {
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15);',
  backgroundColor: 'white',
  border: 'none',
  textColor: '#757575',
  fontSize: 12,
  fontWeight: 'bold',
  fontFamily: 'Cairo, Verdana, Geneva, Tahoma, sans-serif',
  focusBorder: 'none',
  focusTextColor: '#757575',
  padding: '5px 10px',
  focusBackgroundColor: 'white',
  disabledBackgroundColor: 'white',
  disabledTextColor: '#757575',
  selectionBackgroundColor: '#668793',
  selectionTextColor: 'white',
};

export const textAreaStyles = {
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15);',
  backgroundColor: 'white',
  border: 'none',
  textColor: '#757575',
  fontSize: 12,
  fontWeight: 'bold',
  fontFamily: 'Cairo, Verdana, Geneva, Tahoma, sans-serif',
  focusBorder: 'none',
  focusTextColor: '#757575',
  padding: '5px 10px',
  focusBackgroundColor: 'white',
  disabledBackgroundColor: 'white',
  disabledTextColor: '#757575',
  selectionBackgroundColor: '#668793',
  selectionTextColor: 'white',
};

export const StyledGoToCaseInCourtButton = styled(Button)`

  && {
    background-color: #8DC63F;
    color: white;
    margin-left: 15px;       
    font-size: 12px;
    font-weight: bold;
    font-family: Cairo;  
    padding: 7px 10px;    
    text-transform: none;   
    border-radius: 0; 
  }
`;

export const StyledGoToCaseIcon = styled(LaunchOutlinedIcon)`

  && {
    color: white;
    margin-right: 10px;
    font-size: 18px;
  }
`;

export const StyledGotoCaseInCourtLink = styled.a`
  display: block;
  text-decoration: none;
  align-self: flex-end;  
`;

export const StyledDescriptionSection = styled.div`

  width: 100%;

`;

export const StyledFlexSection = styled.div<IFlexSectionProps>`
  box-sizing: border-box;
  width: 100%;
  display: flex;  
  justify-content: space-between;
  align-items: flex-start; 
  margin: ${(props) => props.margin ? props.margin : '0'};    
`;

export const StyledAttorneySection = styled.div<IFlexSectionProps>`
  box-sizing: border-box;
  width: 100%;  
  margin: ${(props) => props.margin ? props.margin : '0'};    
`;

export const StyledAttorneyList = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
`;

export const StyledAttorneyItemContainer = styled.div`
  flex: 1 1 auto;  
  max-width: fit-content;
  min-width: fit-content;
  margin-right: 10px;
  
`;

export const StyledAttorneyItemSpan = styled.span`
  display: block;
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border: none;
  color: #757575;
  background-color: white;
  padding: 5px 10px;
  text-align: center;  
  font-size: 12;
  font-weight: bold;
  font-family: Cairo;  


  &:focus {
    outline: none;
    background-color: white;    
    border: none;    
    color: #757575;
  }

  &::selection {
    background-color: #668793;
      color: white;    
  }    

  &:hover {
    cursor: text;
  }

`;

export const StyledFirmSection = styled.div<IFlexSectionProps>`
  box-sizing: border-box;
  width: 100%;  
  margin: ${(props) => props.margin ? props.margin : '0'};    
`;
