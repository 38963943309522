import { createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#304F5A',
    },
    secondary: {
      main: '#8DC63F',
      contrastText: '#FFFFFF',
    },
    info: {
      main: grey[200],
      dark: grey[400],
    },
  },
});
