import React from 'react';
import { Li, ListWrap, UL } from './style';

function Projects(props: any) {
  return (
    <ListWrap>
      { props.projectList.length > 0 ? props.projectList.map((item: any) => (
        <UL key={item}>
          <Li>{item}</Li>
        </UL>
      )) : <span>-</span>}
    </ListWrap>
  );
}

export default Projects;
