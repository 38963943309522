import { gql } from '@apollo/client';

export const GET_SEC_COMPANY_FEEDS_AFTER_FILED_DATE = gql`
  query GetSecCompanyFeedsAfterFiledDate($lastFileDateLimit: String!) {
    secCompanyFeed_getAfterFiledDate(lastFileDateLimit: $lastFileDateLimit) {
      companyFeeds {
        id
        company_display_name
        tickers
        filings {
          id
          form
          url
          file_date
          matching_keywords
          display_matching_keywords
          filing_type
        }
        latest_filed
        source_display_name
      }
      statuses {
        companyId
        filingId
        status
      }
    }
  }
`;
