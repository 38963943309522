import React from 'react';

import {
  StyledContainer,
  StyledDisputeContainer,
  StyledButtonsContainer,
  StyledResetButton,
  StyledRejectButton,
  StyledTrackButton,
  StyledResetIcon,
  StyledRejectIcon,
  StyledTrackIcon,
} from './style';

interface IProps {
  dataItem: DisplayFilingType;
  isRowOpen: boolean;
}

const RowCollapser: React.FC<IProps> = (props: IProps) => {
  // eslint-disable-next-line
   const trackButtonCaseTestId = `propects-courtCases-expandablerGrid-item-itemContent-trackCaseButton-${props.dataItem.companyId}-${props.dataItem.filingId}`;

  // eslint-disable-next-line
   const rejectButtonTestId = `propects-courtCases-expandablerGrid-item-itemContent-rejectButton-${props.dataItem.companyId}-${props.dataItem.filingId}`;

  // eslint-disable-next-line
   const resetButtonTestId = `propects-courtCases-expandablerGrid-item-itemContent-resetButton-${props.dataItem.companyId}-${props.dataItem.filingId}`;

  return (
    <StyledContainer $isOpen={props.isRowOpen} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <StyledDisputeContainer>
        {' '}
      </StyledDisputeContainer>
      <StyledButtonsContainer>
        <StyledResetButton
          data-testid={resetButtonTestId}
        >
          <StyledResetIcon />
          Reset
        </StyledResetButton>
        <StyledRejectButton
          data-testid={rejectButtonTestId}
        >
          <StyledRejectIcon />
          Reject
        </StyledRejectButton>
        <StyledTrackButton
          data-testid={trackButtonCaseTestId}
        >
          <StyledTrackIcon />
          Track Case
        </StyledTrackButton>
      </StyledButtonsContainer>
    </StyledContainer>
  );
};

export default RowCollapser;
