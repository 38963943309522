import { gql } from '@apollo/client';

export const ADD_EXPERT_SHORTLISTED = gql`
  mutation AddExpertShortlisted($expertId: ID!, $searchId: ID!) {
    expertShortlisted_add(expertId: $expertId, searchId: $searchId) {      
      _id
      expertId
      searchId
    }
  }
`;

export const REMOVE_EXPERT_SHORTLISTED = gql`
  mutation RemoveExpertShortlisted($expertId: ID!, $searchId: ID!) {
    expertShortlisted_remove(expertId: $expertId, searchId: $searchId)
  }
`;
