import { styled as materialStyled } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export const ProjectItem = materialStyled(ListItem)({
  color: '#fff',
  backgroundColor: '#304F5A',
  '&:hover': {
    backgroundColor: '#304F5A',
  },
});

export const ProjectText = materialStyled(ListItemText)({
  fontFamily: 'Cairo',
  fontSize: '13px',
});

export const ProjectIcon = materialStyled(ListItemIcon)({
  justifyContent: 'flex-end',
  color: '#fff',
});

export const ExpertSearchItem = materialStyled(ListItem)({
  color: '#757575',
  backgroundColor: '#fff',
  border: '1px solid #304F5A',
  borderTop: 'none',
  fontFamily: 'Cairo',
  fontSize: '13px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#fff',
  },
});
