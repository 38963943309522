import React from 'react';
import styled from 'styled-components';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

interface IProps {
  $isOpen: boolean;
  children: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

const StyledMainContainer = styled.div`
  width: 100%;
  background-color: #F0F0F0;;
  height: 100%;
`;

const StyledContentContainer = styled.div<IProps>`

  width: 100%;
  background-color: #F0F0F0;
  height: 100%;
  display: ${(props) => props.$isOpen ? 'block' : 'none'};  
`;

export const StyledContainer: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledMainContainer onClick={props.onClick}>
      <StyledContentContainer $isOpen={props.$isOpen}>
        {props.children}
      </StyledContentContainer>
    </StyledMainContainer>
  );
};

export const StyledTitle = styled.h2`

  width: 100%;
  color: #9F9F9F;
  font-size: 17px;
  padding-left: 40px;
  padding-top: 10px;
`;

export const StyledSearchContainer = styled.div`

  background-color: white;
  height: calc(100vh - 190px);
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;  
`;

export const StyledTopContainer = styled.div`

  width: 100%;

`;

export const StyledFooterContainer = styled.div`

  width: 100%;


`;

export const StyledFooterTitle = styled.h3`
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: left;
  font-family: Cairo;
  font-size: 10px;
  font-weight: normal;
  color: black;  
  padding-left: 20px;
  margin-bottom: 20px;
`;

export const StyledFooterLink = styled.a`
  text-decoration: none;
  color: #8DC63F;
  font-family: Cairo;
  font-size: 10px;
  font-weight: normal;
  margin: 0 5px;
`;

interface IContainerProps {
  selected: boolean;
}

export const StyledSelectionContainer = styled.div<IContainerProps>`
  box-sizing: border-box;
  background-color: ${(props: IContainerProps) => props.selected ? '#304F5A' : '#9F9F9F'} ;
  width: 90%;
  margin: 0 auto;
  padding: 15px;
  position: relative;  
  margin-bottom: 3px;
  transition: all .2s linear;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSelectionTitle = styled.h4`
  width: 100%;
  padding: 0;
  margin: 0;
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: Cairo;
`;

export const StyledSelectionDetail = styled.h4`

  width: 100%;
  padding: 0;
  margin: 0;
  color: white;
  font-size: 14px;
  font-weight: normal;
  font-family: Cairo;
`;

interface IArrowIconProps {
  $selected: boolean;
}

export const StyledArrowIcon = styled(PlayArrowIcon)<IArrowIconProps>`
  display: ${(props: IArrowIconProps) => props.$selected ? 'block' : 'none'};
  color: #304F5A;
  position: absolute;
  top: 50%;
  right: -15px;
  margin-top: -12px;
  font-size: 24px;
`;

export const StyledDisabledContainer = styled.div`
  box-sizing: border-box;
  background-color: #F5F5F5;
  width: 90%;
  margin: 0 auto;
  padding: 15px;
  position: relative;  
  margin-bottom: 3px;
  transition: all .2s linear;
  
`;

export const StyledDisabledTitle = styled.h4`
  width: 100%;
  padding: 0;
  margin: 0;
  color: #B4B4B4;;
  font-size: 14px;
  font-weight: bold;
  font-family: Cairo;
`;

export const StyledDisabledDetail = styled.h4`

  width: 100%;
  padding: 0;
  margin: 0;
  color: #B4B4B4;;
  font-size: 14px;
  font-weight: normal;
  font-family: Cairo;
`;
