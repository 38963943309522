import { gql } from '@apollo/client';

export const GET_EXPERT_TYPES = gql`
    query GetExpertTypes {
        expertType_getAll {
            _id
            name            
        }
    }
`;
