import styled from 'styled-components';
import CancelIcon from '@material-ui/icons/Cancel';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

export const StyledContainer = styled.div`

  width: 100%;
  background-color: white;
  margin-bottom: 5px;  
  display: grid;
  grid-template-areas:
  "area1 area2 area3";
  grid-template-columns: 1fr 3fr 0.6fr;
  grid-template-rows: 1fr;
`;

export const StyledName = styled.h4`
  grid-area: area1;
  padding: 0;
  margin: 0;
  color: #9F9F9F;
  font-size: 12px;
  font-weight: bold;
  height: 100%;
  padding-left: 20px;  
  vertical-align: center;
  padding: 7px 10px;

  &::selection {
    background-color: #668793;
    color: white;
  }
`;

export const StyledDeleteIcon = styled(CancelIcon)`

  && {
    color: #E4E4E4;

  }

  &:hover {
    cursor: pointer;
  }

`;

export const StyledIconsContainer = styled.div`

  grid-area: area3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`;

export const StyledStarIconInactive = styled(StarBorderIcon)`

  && {
    color: #757575;
    
  }

  &:hover {
    cursor: pointer;
  }

`;

export const StyledStarIconActive = styled(StarIcon)`

  && {
    color: #8DC63F;
    
  }

  &:hover {
    cursor: pointer;
  }

`;
