import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

import {
  StyledContainer,
  StyledFormControl,
  StyledDecorativeIcon,
  useStyles,
} from './style';

interface IProps {
  selectedValue: EnumPeriodOption;
  handleChange: (value: EnumPeriodOption) => void;
  disabled: boolean;
}

enum EnumPeriodOption { LAST24H, LAST7D, LAST14D, LAST30D, LAST90D }

const DaysSelect: React.FC<IProps> = (props: IProps) => {
  const classes: any = useStyles(props);

  const menuProps: any = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <StyledContainer>
      <StyledFormControl>
        <Select
          disableUnderline
          value={props.selectedValue}
          onChange={(e: any) => props.handleChange(e.target.value)}
          classes={{ root: classes.select, icon: classes.icon }}
          MenuProps={menuProps}
          disabled={props.disabled}
        >
          <MenuItem value={EnumPeriodOption.LAST24H}>Last 24 hours</MenuItem>
          <MenuItem value={EnumPeriodOption.LAST7D}>Last 7 days</MenuItem>
          <MenuItem value={EnumPeriodOption.LAST14D}>Last 14 days</MenuItem>
          <MenuItem value={EnumPeriodOption.LAST30D}>Last 30 days</MenuItem>
          <MenuItem value={EnumPeriodOption.LAST90D}>Last 90 days</MenuItem>
        </Select>
      </StyledFormControl>
      <StyledDecorativeIcon $disabled={props.disabled} />
    </StyledContainer>
  );
};

export default DaysSelect;
