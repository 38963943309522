import _ from 'lodash';

export const getJwtApiToken = () => {
  return localStorage.getItem('jwtToken');
};

export const setJwtApiToken = (token:string | null) => {
  if (token === null) return localStorage.removeItem('jwtToken');
  return localStorage.setItem('jwtToken', token);
};

export const clearStore = () => localStorage.clear();

export const handleDownload = async (
  filename: string,
  service: (id: number) => Promise<any>,
  id:number,
) => {
  // 1. Convert data into 'blob'
  const response = await service(id);
  const blob = await response.blob();

  // 2. Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  // 3. Append to html page
  document.body.appendChild(link);

  // 4. Force download
  link.click();

  // Clean up and remove the link
  if (!_.isNull(link.parentNode)) link.parentNode.removeChild(link);
};
