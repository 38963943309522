import React from 'react';
import errorImage from '../../assets/svg/ErrorIcon.svg';
import {
  StyledContainer,
  StyledErrorImage,
  StyledTitle,
  StyledCenter,
} from './style';

interface ErrorCompProps {

  title: string,

}

function ErrorComponent({ title } : ErrorCompProps) {
  return (
    <StyledContainer>
      <StyledCenter>
        <StyledTitle>{title}</StyledTitle>
        <StyledErrorImage src={errorImage} alt="Error" />
      </StyledCenter>
    </StyledContainer>
  );
}

export default ErrorComponent;
