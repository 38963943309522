import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  StyledContainer,
  StyledContentContainer,
  StyledCenter,
  StyledTitle,
  StyledButton,
  StyledButtonsContainer,
  StyledExpertIcon,
  StyledProspectIcon,
} from './style';

const Home = () => {
  const history = useHistory();

  const handleProspectsButtonClick = () => {
    history.push('/prospector');
  };

  const handleExpertsButtonClick = () => {
    history.push('/experts');
  };

  return (
    <StyledContainer>
      <StyledContentContainer>
        <StyledCenter>
          <StyledTitle>I&apos;m looking for:</StyledTitle>
          <StyledButtonsContainer>
            <StyledButton
              onClick={handleProspectsButtonClick}
              data-testid="home-prospectsButton"
            >
              <StyledProspectIcon />
              Prospects
            </StyledButton>
            <StyledButton
              onClick={handleExpertsButtonClick}
              data-testid="home-expertsButton"
            >
              <StyledExpertIcon />
              Experts
            </StyledButton>
          </StyledButtonsContainer>
        </StyledCenter>
      </StyledContentContainer>
    </StyledContainer>
  );
};

export default Home;
