import styled from 'styled-components';

interface IContainerProps {
  isLoading: boolean;
}

export const StyledContainer = styled.div<IContainerProps>`
  width: 100%;
  padding: 0;
  margin: 0;  
  position: relative;

  &::before {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;  
  display: ${(props: IContainerProps) => props.isLoading ? 'block' : 'none'}; 
  backdrop-filter: ${(props: IContainerProps) => props.isLoading ? 'blur(10px)' : ''};   
  z-index: ${(props: IContainerProps) => props.isLoading ? 50 : -1};
}


`;
