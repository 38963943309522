import React from 'react';
import {
  LSN, Border, ListWrap, UL,
} from '../style';

interface IProps {
  selectedExpert: ExpertType;
}

function Accomplishment(props: IProps) {
  const accomplishments = props.selectedExpert.accomplishments;
  return (
    <ListWrap>
      <UL>
        {accomplishments.length > 0
          ? accomplishments.map((item: string) => (
            <div key={item}>
              <LSN>{item}</LSN>
              <Border />
            </div>
          )) : '-'}
      </UL>
    </ListWrap>
  );
}

export default Accomplishment;
