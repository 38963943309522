const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const convertStrToCurrency = (strValue: string | null) => {
  if (!strValue) return '$0';
  const value = parseInt(strValue, 10);
  return formatter.format(value);
};
