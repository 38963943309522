import React from 'react';

import {
  StyledContainer,
} from './style';

interface IProps {
  children: React.ReactNode
}

const MainScreen: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      {props.children}
    </StyledContainer>
  );
};

export default MainScreen;
