import React from 'react';

import {
  StyledContainer,
  StyledList,
} from './style';

import FeaturedItem from './featuredItem';

interface IProps {
  items: {
    _id: string;
    name?: string;
    stared?: boolean;
  }[];
  handleDelete: (id: string) => void;
  toggleExpertStarOn?: (id: string) => void;
  shouldShowStar?: boolean;
  shouldHideIcons?: boolean;
}

const FeaturedList: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      <StyledList>
        {
          props.items.map((item: any) => {
            return (
              <FeaturedItem
                key={item._id}
                item={item}
                handleDelete={props.handleDelete}
                toggleStar={props.toggleExpertStarOn}
                shouldShowStar={props.shouldShowStar}
                shouldHideIcons={props.shouldHideIcons}
              />
            );
          })
        }
      </StyledList>
    </StyledContainer>
  );
};

export default FeaturedList;
