import styled from 'styled-components';

interface IProps {
  $isOpen: boolean;
  disabled?: boolean;
}

export const StyledContainer = styled.div<IProps>`
  width: 100%;
  background-color: #E5E5E5;
  height: 100%;
  display: ${(props) => props.$isOpen ? 'block' : 'none'};  
`;

export const StyledInnerContainer = styled.div`
  height: 90%;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 32px;
  display: flex;
  justify-content: center;

  @media only screen and (max-height: 702px) {
    max-height: 60%;
  }
  @media only screen and (min-height: 702px) {
    max-height: 70%;
  }
  @media only screen and (min-height: 1200px) {
    max-height: 80%;
  }
  @media only screen and (min-height: 1755px) {
    max-height: 85%;
  }
`;
