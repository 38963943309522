import React from 'react';

import {
  StyledContainer,
  StyledStarIcon,
} from './style';

import ExpertRow from '../../../../../../components/expertRow';
import StarIconGreen from '../../../../../../assets/svg/StarIconGreen.svg';

interface IProps {
  expertFavorites: ExpertFavoriteType[];
  getExpert: (expertId: string) => ExpertType | null;
  handleRemoveFavoriteExpert: (expertId: string) => void;
}

const ExpertsList: React.FC<IProps> = (props: IProps) => {
  const compare = (expertFavoriteA: ExpertFavoriteType, expertFavoriteB: ExpertFavoriteType) => {
    const expertA = props.getExpert(expertFavoriteA.expertId);
    const expertB = props.getExpert(expertFavoriteB.expertId);

    if (!expertA || !expertB) return 0;

    if ((expertA?.firstName ?? '').toLowerCase() < (expertB?.firstName ?? '').toLocaleLowerCase()) return -1;

    if ((expertA?.firstName ?? '').toLowerCase() > (expertB?.firstName ?? '').toLocaleLowerCase()) return 1;

    return 0;
  };

  const sortedExpertFavorites = [...props.expertFavorites].sort(compare);

  return (
    <StyledContainer>
      {sortedExpertFavorites.map((expertFavorite) => {
        const item = props.getExpert(expertFavorite.expertId);
        if (!item) return null;
        return (
          <ExpertRow
            key={expertFavorite._id}
            item={item}
            view="right"
            rightIcon={(
              <StyledStarIcon
                src={StarIconGreen}
                onClick={() => props.handleRemoveFavoriteExpert(item?._id ?? '')}
              />
            )}
          />
        );
      })}
    </StyledContainer>
  );
};

export default ExpertsList;
