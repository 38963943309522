import styled from 'styled-components';

interface ExpertSearchContainer {
  visible: boolean;
}

export const StyledContainer = styled.div<ExpertSearchContainer>`
  height: inherit;
  width: 90%;
  margin: 0px auto;
  display: ${(props) => props.visible ? 'block' : 'none'};
  opacity: ${(props) => props.visible ? 1 : 0};
  transition: opacity .3s linear;  
`;
