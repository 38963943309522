import { gql } from '@apollo/client';

export const GET_PROJECTS = gql`
    query GetProjects {
        project_getAll {
            _id
            name
            billable
            projectCode
            StageName
            CloseDate
            client
            Matter_Name
            Budget__c
            Client_Sponsor__Name
            Law_Firm_Name
            Industry__c
            Practice_Order__c
            Affiliated_Expert__c
            Project_Type__c
            about
        }
    }
`;
