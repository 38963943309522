import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const StyledContainer = styled.div`
  width: 100%;
  box-shadow: 0 0 5px lightgray;
`;

interface IContainerProps {
  isLoading: boolean;
}

export const StyledListContainer = styled.div<IContainerProps>`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;  
  position: relative;  
 
`;

const LoadingWithSpinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 200000;
  opacity: 1;
`;

const CenterContainer = styled.div`

  width: 200px;
  height: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;  
  margin-top: 130px;
`;

const LoadingTitle = styled.h3`

  color:#304F5A;
  font-size: 14px;
  font-family: Cairo;
  font-weight: bold;
  text-align: center;

`;

export const StyledLoadingSpinner: React.FC = () => {
  return (
    <LoadingWithSpinner>
      <CenterContainer>
        <CircularProgress style={{ color: '#8DC63F' }} />
        <LoadingTitle>Loading results</LoadingTitle>
      </CenterContainer>
    </LoadingWithSpinner>
  );
};
