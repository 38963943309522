import { GET_EXPERT_SHORTLISTED_BY_PROJECT } from './query';
import { ADD_EXPERT_SHORTLISTED, REMOVE_EXPERT_SHORTLISTED } from './mutation';
import client from '../../../communication/client';
import { cleanExpertsShortlisted } from '../utils';

export const fetchExpertsShortlisted = async (projectId: string) => {
  const response = await client.query({
    query: GET_EXPERT_SHORTLISTED_BY_PROJECT,
    variables: {
      projectId,
    },
    fetchPolicy: 'network-only',
  });

  const {
    shortlist,
    experts,
  } = response.data.expertShortlisted_getByProject;

  const cleanedShortlist = cleanExpertsShortlisted(shortlist);

  return {
    shortlist: cleanedShortlist,
    experts,
  };
};

export const addExpertShortlisted = async (expertId: string, searchId: string) => {
  const response = await client.mutate({
    mutation: ADD_EXPERT_SHORTLISTED,
    variables: {
      expertId,
      searchId,
    },
  });

  const cleanedResult = cleanExpertsShortlisted([response.data.expertShortlisted_add])[0];

  return cleanedResult;
};

export const removeExpertShortlisted = async (expertId: string, searchId: string) => {
  const response = await client.mutate({
    mutation: REMOVE_EXPERT_SHORTLISTED,
    variables: {
      expertId,
      searchId,
    },
  });

  return response.data.expertShortlisted_remove;
};
