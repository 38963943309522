import React from 'react';

import {
  StyledContainer,
  StyledName,
  StyledIconsContainer,
  StyledStarIconActive,
  StyledStarIconInactive,
  StyledDeleteIcon,
} from './style';

interface IProps {
  item: {
    _id: string;
    name?: string;
    stared?: boolean;
  };
  handleDelete: (id: string) => void;
  toggleStar?: (id: string) => void;
  shouldShowStar?: boolean;
  shouldHideIcons?: boolean;
}

const FeaturedItem: React.FC<IProps> = (props: IProps) => {
  const displayStars = () => {
    if (!props.shouldShowStar || !!props.shouldHideIcons) return null;

    if (props.item?.stared) {
      return (
        <StyledStarIconActive onClick={() => props.toggleStar ? props.toggleStar(props.item._id) : {}} />
      );
    }

    return (
      <StyledStarIconInactive onClick={() => props.toggleStar ? props.toggleStar(props.item._id) : {}} />
    );
  };

  const displayCloseIcon = () => {
    if (props.shouldHideIcons) return null;

    return (
      <StyledDeleteIcon onClick={() => props.handleDelete(props.item._id)} />
    );
  };

  return (
    <StyledContainer>
      <StyledName>{props.item?.name}</StyledName>
      <StyledIconsContainer>
        {displayStars()}
        {displayCloseIcon()}
      </StyledIconsContainer>
    </StyledContainer>
  );
};

export default FeaturedItem;
