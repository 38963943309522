import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  mainContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '10em',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.success.main,
  },
  button: {
    backgroundColor: theme.palette.success.main,
    margin: theme.spacing(1),
    color: 'white',
    fontSize: '2rem',
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
}));
