import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../../../react-auth0-spa';

import HomeIcon from './homeIcon';
import DotsMenuIcon from './dotsIcon';
import NavMenus from './navMenus';
import {
  StyledAppBar,
  StyledContainer,
  StyledToolBar,
} from './style';

import { FilingContext, FilingContextType } from '../../../context/filingContext';
import { ProjectContext, ProjectContextType } from '../../../context/projectContext';

const Navbar = () => {
  const { logout } = useAuth0();

  const filingState = useContext<FilingContextType>(FilingContext);
  const projectState = useContext<ProjectContextType>(ProjectContext);

  const handleContainerClick = () => {
    if (filingState.selectedTrackedCase) {
      filingState.setSelectedTrackedCase(null);
    }
    projectState.setUpdateProject(true);
  };

  return (
    <StyledContainer onClick={handleContainerClick}>
      <StyledAppBar>
        <StyledToolBar>
          <Link to="/">
            <HomeIcon />
          </Link>
          <NavMenus />
          <Link
            onClick={() => logout()}
            to="/"
          >
            <DotsMenuIcon />
          </Link>
        </StyledToolBar>
      </StyledAppBar>
    </StyledContainer>
  );
};

export default Navbar;
