import React, { useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { ProjectContext, ProjectContextType } from '../../../context/projectContext';
import ProjectBody from './projectBody';
import * as projectService from '../../../entities/project/service';

interface IProps {
  project: ProjectType
  valueAboutProject: string,
  changeInputValue: (value: string) => void
  updateProject: boolean;
  setUpdateProject: (value: boolean) => void;
  handleShowSuccess: (message: string) => void;
  isEditingProject: boolean;
  setIsEditingProject: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectContent = (props: IProps) => {
  const client = useApolloClient();

  const projectState = useContext<ProjectContextType>(ProjectContext);

  useEffect(() => {
    if (!props.updateProject) return;

    editProject();
  }, [props.updateProject]);

  const editProject = async () => {
    if (!props.project
    || !props.isEditingProject || props.project.about === props.valueAboutProject) return props.setUpdateProject(false);
    const updated = await projectService.updateProject(client, {
      projectId: props.project._id,
      about: props.valueAboutProject,
    });
    const newProjects = projectState.projects.map((proj) => {
      if (proj._id === props.project._id) return updated;
      return proj;
    });
    projectState.setProjects(newProjects);
    props.setIsEditingProject(false);
    props.setUpdateProject(false);
    return props.handleShowSuccess('Changes saved');
  };

  return (
    <>
      <ProjectBody
        project={props.project}
        valueAbout={props.valueAboutProject}
        changeInputValue={props.changeInputValue}
      />
      {!!editProject}
    </>
  );
};

export default ProjectContent;
