import { makeStyles, Theme } from '@material-ui/core/styles';

const buttonStyle = {
  width: '40%',
  color: 'white',
  maxWidth: 150,
};

export default makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    outline: 0,
    minWidth: 500,
    maxWidth: 750,
  },
  modalTitle: {
    color: theme.palette.success.main,
    textAlign: 'center',
  },
  greenButton: {
    backgroundColor: theme.palette.success.main,
    ...buttonStyle,
  },
  yellowButton: {
    backgroundColor: theme.palette.warning.main,
    ...buttonStyle,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  uploadInputContainer: {
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 10,
    minWidth: 397,
  },
  uploadInputTextStyle: {
    fontFamily: 'inherit',
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 0.25)',
  },
  titleInput: {
    width: '100%',
  },
  divisor: {
    margin: theme.spacing(2),
  },
  textFileStyle: {
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
