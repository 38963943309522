import React from 'react';
import {
  StyledArrowBackIcon, StyledArrowForwardIcon,
  StyledContainer,
  StyledH3,
} from './style';

interface IProps {
  currentPage: number;
  handleSearch: (newCurrentPage: number | null) => void;
  isNextDisabled: boolean;
  isLoader: boolean;
}

const Pagination = (props: IProps) => {
  const isPreviousDisabled = props.currentPage === 1 || props.isLoader;
  const isNextDisabled = props.isNextDisabled || props.isLoader;

  const handlePreviousPage = () => {
    if (isPreviousDisabled) return;
    props.handleSearch(props.currentPage - 1);
  };

  const handleNextPage = () => {
    if (isNextDisabled) return;
    props.handleSearch(props.currentPage + 1);
  };

  return (
    <StyledContainer>
      <StyledArrowBackIcon
        onClick={handlePreviousPage}
        disabled={isPreviousDisabled}
        htmlColor={isPreviousDisabled ? 'gray' : '#8dc53f'}
      />
      <StyledH3>{props.currentPage}</StyledH3>
      <StyledArrowForwardIcon
        onClick={handleNextPage}
        disabled={isNextDisabled}
        htmlColor={isNextDisabled ? 'gray' : '#8dc53f'}
      />
    </StyledContainer>
  );
};

export default Pagination;
