import _ from 'lodash';
import { GET_SEC_COMPANY_FEEDS_AFTER_FILED_DATE } from './query';
import client from '../../../communication/client';

export const fetchSECCompanyFeedsAfterFiledDate = async (lastFileDateLimit: string) => {
  const response = await client.query({
    query: GET_SEC_COMPANY_FEEDS_AFTER_FILED_DATE,
    variables: {
      lastFileDateLimit,
    },
    fetchPolicy: 'network-only',
  });

  return _.get(response, 'data.secCompanyFeed_getAfterFiledDate', []);
};
