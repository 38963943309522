import { gql } from '@apollo/client';

export const ADD_EXPERT_FAVORITE = gql`
  mutation AddExpertFavorite($expertId: ID!) {
    expertFavorite_add(expertId: $expertId) {      
      _id
      expertId
      personId
    }
  }
`;

export const REMOVE_EXPERT_FAVORITE = gql`
  mutation RemoveExpertFavorite($expertId: ID!) {
    expertFavorite_remove(expertId: $expertId)
  }
`;
