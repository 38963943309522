import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import AppSetup from './appSetup';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './react-auth0-spa';
import history from './utils/history';
import {
  AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_EXPERT_GRID_API_AUDIENCE, SENTRY, ENV,
} from './config';

Sentry.init({
  dsn: SENTRY.IS_SENTRY_ENABLED ? SENTRY.SENTRY_DSN : undefined,
  environment: ENV.KS_ENV,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const onRedirectCallback = (appState:any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

const config = {
  clientId: AUTH0_CLIENT_ID,
  domain: AUTH0_DOMAIN,
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience={AUTH0_EXPERT_GRID_API_AUDIENCE}
    scope="read:current_user offline_access"
    useRefreshTokens
  >
    <AppSetup />
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
