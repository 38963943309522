import React from 'react';
import { StyledIcon } from './style';
import DotsMenuWhite from '../../../../assets/svg/HomeRightIcon.svg';

function DotsMenuIcon() {
  return (
    <StyledIcon src={DotsMenuWhite} />
  );
}

export default DotsMenuIcon;
