import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { ExpertContext, ExpertContextType } from '../../context/expertContext';
import CustomSearch from '../customSearch';
import Pagination from '../pagination';
import ExpertList from './expertList';
import {
  StyledContainer,
  StyledHeader,
  StyledContent,
  StyledPersonAddIcon,
  StyledSubContent,
  StyledLoadingSpinner,
  StyledButtonsContainer,
  StyledButton,
  StyledNotFound,
  StyledText,
  StyledSpan,
  StyledTitleHeader,
  StyledButtonPaginationContainer,
} from './style';

interface IProps {
  onCancel: () => void;
  handleSearch: (search: string, cursor: string | null) => Promise<ExpertType[]>;
  handleAdd: (expertsIds: string[]) => Promise<void>;
  selectedExpertIds: string[];
}

const AddExpertModal = (props: IProps) => {
  const expertState = useContext<ExpertContextType>(ExpertContext);
  const [value, setValue] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [items, setItems] = useState<ExpertType[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>(initCheckedList());
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [maxFetchedPage, setMaxFetchedPage] = useState<{ [page: string]: ExpertType[] } | null>(null);
  const [nextCursor, setNextCursor] = useState<string | null>(null);

  const handleSearch = async (newCurrentPage: number | null = null) => {
    setIsLoader(true);
    if (maxFetchedPage && newCurrentPage && maxFetchedPage[newCurrentPage]) {
      setItems(maxFetchedPage[newCurrentPage]);
      setCurrentPage(newCurrentPage ?? 1);
      setIsLoader(false);
      return;
    }
    const curCursor = newCurrentPage ? nextCursor : null;
    const paginationExperts = await props.handleSearch(value, curCursor);
    const edges = _.get(paginationExperts, 'edges', []) as any[];
    const newItems = _.map(edges, 'node');
    expertState.setExperts(newItems);
    const newKeyValue = { [newCurrentPage ?? 1]: newItems };
    const newMaxFetchedPage = newCurrentPage !== null ? { ...maxFetchedPage, ...newKeyValue } : { ...newKeyValue };
    setMaxFetchedPage(newMaxFetchedPage);
    setItems(newItems);
    const newNextCursor = _.last(edges)?.cursor ?? null;
    setNextCursor(newNextCursor);
    setCurrentPage(newCurrentPage ?? 1);
    setIsLoader(false);
  };

  useEffect(() => {
    if (!maxFetchedPage) {
      return;
    }
    const arrMaxFetchedPage = Object.values(maxFetchedPage).reduce((acum, x) => [...acum, ...x], []);
    setCheckedList(arrMaxFetchedPage.filter((x) => checkedList.includes(x._id)).map((x) => x._id));
  }, [maxFetchedPage]);

  const handleAdd = async () => {
    setIsLoader(true);
    await props.handleAdd(checkedList);
    setIsLoader(false);
    props.onCancel();
  };

  const handleClear = () => {
    setItems([]);
    setValue('');
    setCurrentPage(null);
    setMaxFetchedPage(null);
    setNextCursor(null);
    setCheckedList(initCheckedList());
  };

  const hasPagination = currentPage !== null && maxFetchedPage !== null && !isLoader;

  const isInvalid = items.length === 0 || isLoader;
  return (
    <StyledContainer>
      <StyledHeader>
        <StyledPersonAddIcon />
        <StyledTitleHeader>Add an expert to this search</StyledTitleHeader>
      </StyledHeader>
      <StyledContent>
        <CustomSearch
          value={value}
          handleChange={(newValue: string) => (setValue(newValue))}
          onExecute={() => handleSearch(null)}
          handleClear={handleClear}
          styles={{
            maxWidth: '350px',
            margin: '5px 0',
            searchIconSize: '25px',
          }}
          placeholder="Search by name"
        />
        <StyledSubContent>
          <StyledText>
            Results found on this page:
            {' '}
            <StyledSpan>{isInvalid ? 'N/A' : items.length}</StyledSpan>
          </StyledText>
          <StyledText>
            Experts Selected:
            {' '}
            <StyledSpan>{isInvalid ? 'N/A' : checkedList.length}</StyledSpan>
          </StyledText>
        </StyledSubContent>
        {isLoader && (
          <StyledLoadingSpinner />
        )}
        {!isLoader && items.length === 0 && (
          <StyledNotFound />
        )}
        {!isLoader && items.length > 0 && (
          <ExpertList
            items={items}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            selectedExpertIds={props.selectedExpertIds}
          />
        )}
        <StyledButtonPaginationContainer>
          {
            currentPage !== null && maxFetchedPage !== null && (
            <Pagination
              currentPage={currentPage}
              handleSearch={handleSearch}
              isNextDisabled={items.length < 30}
              isLoader={isLoader}
            />
            )
          }
          <StyledButtonsContainer hasPagination={hasPagination}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={props.onCancel}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color={isInvalid ? 'default' : 'secondary'}
              disabled={isInvalid || checkedList.length === 0}
              onClick={handleAdd}
            >
              Accept
            </StyledButton>
          </StyledButtonsContainer>
        </StyledButtonPaginationContainer>
      </StyledContent>
    </StyledContainer>
  );
};

const initCheckedList = () => {
  return [];
};

export default AddExpertModal;
