import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const StyledContainer = styled.div`
  grid-area: header;     
  display: flex;
  justify-content: flex-start;    
`;

export const StyledBasicInfoContainer = styled.div`

  flex: 1 1 0;  
  max-width: 400px;
  min-height: 50px;
  background-color: #304F5A;  
  display: flex;
  justify-content: flex-start;
    
`;

export const StyledBasicInfo = styled.div`  
  flex: 1 1 0;
  display: flex;
  min-width: 100px;
  max-width: 200px;  
  justify-content: flex-start;   
  align-items: center;
  
`;

export const StyledTitleContainer = styled.div`
  flex: 1 1 0;
  min-width: 100px; 
  max-width: 200px;
  padding-left: 10px;  
  position: relative;  
  color: white; 
  display: flex;
  justify-content: center;
  align-items: center; 
`;

export const StyledArrow = styled(ArrowForwardIosIcon)`

  && {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -7px;
    font-size: 14px;
  }

`;
export const StyledExpertName = styled.h2`
  box-sizing: border-box;
  padding: 0;
  margin: 0;  
  font-size: 0.95rem;
  font-weight: bold;    
  font-family: Cairo;
  line-height: 1.2rem;  
  color: white;  
  padding-left: 25px;
`;

export const StyledTitle = styled.h2`
  padding: 0;
  margin: 0;  
  font-size: 0.95rem;
  font-weight: bold;    
  font-family: Cairo;
  text-align: center;
  line-height: 1.2rem;  
  color: white;  
`;

export const BackButton = styled.div`
  background: #8DC63F;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.95rem;
  width: 80px;
  margin: 0px 5px;
  padding: 10px 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  img{
    width: 15px;
    height: 15px;
  }

  &:hover{
    cursor: pointer;
  }
`;
