import {
  GET_EXPERT_BY_ID,
  GET_EXPERTS_BY_NAME,
} from './query';
import client from '../../../communication/client';

export const fetchExpertById = async (expertId: string) => {
  const response = await client.query({
    query: GET_EXPERT_BY_ID,
    variables: {
      expertId,
    },
    fetchPolicy: 'network-only',
  });

  return response.data.expert_getById;
};

export const fetchExpertsByName = async (expertName: string, cursor: string | null = null) => {
  const response = await client.query({
    query: GET_EXPERTS_BY_NAME,
    variables: {
      expertName,
      cursor,
    },
    fetchPolicy: 'network-only',
  });
  return response.data?.expert_findByName ?? [];
};
