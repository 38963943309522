import styled from 'styled-components';

export const StyledContainer = styled.div`
  grid-area: header;
  background-color: #304F5A;
  display: flex;
  justify-content: flex-start;  
  align-items: center;
  padding: 12px 0px 12px 25px;
  box-sizing: border-box;
`;

export const StyledHeaderLabel = styled.label`
  
  padding: 0;
  margin: 0;
  color: white;
  font-size: 12px;
  font-weight: normal;
  font-family: Cairo;

`;

export const StyledHeaderTitle = styled.h4`
  
  padding: 0;
  margin: 0;
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: Cairo;
  margin-left: 15px;
`;
