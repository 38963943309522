import _ from 'lodash';
import { GET_EXPERT_TYPES } from './query';

import client from '../../../communication/client';

export const getExpertTypes = async ():Promise<ExpertTypeType[]> => {
  const r:any = await client.query({
    query: GET_EXPERT_TYPES,
  });
  return _.get(r, 'data.expertType_getAll', []);
};
