import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ErrorComponent from '../../components/errorComponent';
import { ExpertContext, ExpertContextType } from '../../context/expertContext';
import * as expertService from '../../entities/expert/service';

import ExpertHeader from './expertHeader';
import Projects from './projects';
import Education from './education';
import Instituion from './institution';
import Accomplishments from './accomplishments';
import ManagerInformation from './managerInfo';
import SubjectMatterTags from './subjectMatterTags';

import LinkSvg from '../../assets/svg/link.svg';
import RightCaret from '../../assets/svg/rightcaret.svg';
import UserSvg from '../../assets/svg/user_icon.svg';
import ActivitySvg from '../../assets/svg/activity_icon.svg';

import * as expertFavoriteService from '../../entities/expertFavorite/service';

import {
  MainContainer,
  StyledContainer,
  StyledExpertInfoContainer,
  ExpertDetails,
  EducationDetails,
  ProjectDetails,
  SideHeading,
  NotesWrap,
  NameWrap,
  HeadField,
  Subject,
  ButtonsWrap,
  Button,
  BioWrap,
  BioLink,
  BioPara,
  Name,
  StyledSvgImg,
  ListWrap,
  ItemWrap,
} from './style';

interface ParamType {
  expert_id: string;
}

const ExpertView: React.FC = () => {
  const expertState = useContext<ExpertContextType>(ExpertContext);

  const routeParams = useParams<ParamType>();

  const [error, setError] = useState<Error | null>(null);

  const selectedExpert = expertState.getExpert(routeParams.expert_id);

  useEffect(() => {
    const fetchExpert = async () => {
      try {
        const expertObject = await expertService.fetchExpertById(routeParams.expert_id);
        const expertFavorites = await expertFavoriteService.fetchExpertsFavorites();

        expertState.setExperts([...expertFavorites.experts, expertObject]);
        expertState.setExpertFavorites(expertFavorites.favorites);
      } catch (err) {
        setError(err);
      }
    };

    fetchExpert();
  }, []);

  if (error) {
    return <ErrorComponent title={`ERROR: ${error.message}`} />;
  }

  if (!selectedExpert) return null;

  return (
    <StyledContainer>
      <ExpertHeader expert={selectedExpert} />
      <MainContainer>
        <StyledExpertInfoContainer>
          <ExpertDetails>
            <NameWrap>
              <HeadField>
                <Name>{`${selectedExpert.firstName} ${selectedExpert.lastName}`}</Name>
              </HeadField>
              <Subject>{selectedExpert.expert_type || '-'}</Subject>
            </NameWrap>
            <BioWrap>
              <SideHeading>Bio:</SideHeading>
              <BioPara>
                {selectedExpert.bio}
              </BioPara>
              <BioLink href={selectedExpert?.bio_url || '#'} target={selectedExpert?.bio_url ? '_blank' : '_self'}>
                <StyledSvgImg src={LinkSvg} alt="" />
                &nbsp; Go To Bio &nbsp;
                <StyledSvgImg src={RightCaret} alt="" />
              </BioLink>
            </BioWrap>
            <ButtonsWrap>
              <Button>
                <StyledSvgImg src={UserSvg} alt="" />
                &nbsp;
                <span>Outreach Notes</span>
              </Button>
              <Button style={{ background: 'white', color: 'grey' }}>
                <StyledSvgImg src={ActivitySvg} alt="" />
                &nbsp;
                <span>Expert’s Activity</span>
              </Button>
            </ButtonsWrap>
            <ManagerInformation selectedExpert={selectedExpert} />
          </ExpertDetails>
          <EducationDetails>
            <SideHeading>Institution/Position/Affiliation</SideHeading>
            <Instituion
              institutions_or_positions_or_affiliations={selectedExpert.institutions_or_positions_or_affiliations}
            />
            <SideHeading>Education/Background</SideHeading>
            <Education education_or_background={selectedExpert.education_or_background} />
            <SideHeading>Acomplishments</SideHeading>
            <Accomplishments selectedExpert={selectedExpert} />
            <SideHeading>Subject Matter Tags</SideHeading>
            <SubjectMatterTags items={selectedExpert.subject_matter_tags} />
            <SideHeading>Patents</SideHeading>
            <ListWrap>
              <ItemWrap>{selectedExpert.patents || '-'}</ItemWrap>
            </ListWrap>
            <SideHeading>Papers</SideHeading>
            <ListWrap>
              <ItemWrap>{selectedExpert.papers || '-'}</ItemWrap>
            </ListWrap>
          </EducationDetails>
          <ProjectDetails>
            <SideHeading>Projects</SideHeading>
            <Projects projectList={selectedExpert.projects || []} />
            <SideHeading>Overview - Notes</SideHeading>
            <NotesWrap
              value={selectedExpert.overview_or_notes.join('\n')}
              placeholder="Add a Overview/Relevant Experience/Notes"
            />
          </ProjectDetails>
        </StyledExpertInfoContainer>
      </MainContainer>
    </StyledContainer>
  );
};

export default ExpertView;
