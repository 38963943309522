import { UPDATE_PROJECT } from './mutation';

export const updateProject = async (client: any, input: {
  projectId: string;
  about: string;
}): Promise<ProjectType> => {
  const result = await client.mutate({
    mutation: UPDATE_PROJECT,
    variables: {
      input,
    },
  });

  return result.data.project_edit;
};
