import React from 'react';

import {
  StyledInput,
  StyledContainer,
  StyledTextArea,
  StyledEditIcon,
  StyledDateIcon,
} from './style';

enum IconType {EDIT, DATE}

interface InputProps {
  placeholder? : string;
  defaultValue : string;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  styles?: {
    padding? : string;
    border? : string;
    borderRadius?: string;
    backgroundColor?: string;
    textAlign?: string;
    textColor?: string;
    fontSize?: number;
    fontWeight?: string;
    focusBorder?: string;
    focusTextColor?: string;
    fontFamily?: string;
    boxShadow?: string;
    focusBackgroundColor?: string;
    disabledBackgroundColor?: string;
    disabledTextColor?: string;
    textAreaHeight?: string;
    selectionBackgroundColor: string,
    selectionTextColor: string,
    lineHeight?: number,
    boxSizing?: string,
  }
  isTextArea?: boolean;
  iconType?: IconType;
  iconPosRight?: number;
  iconPosTop?: number;
  iconColor?: string;
  onIconClick?: () => void;
}

const CustomInput: React.FC<InputProps> = (props: InputProps) => {
  const handleInputChange = (inputString: string) => {
    if (props.disabled || !props.onChange) return null;
    return props.onChange(inputString);
  };
  const handleIconClick = () => {
    if (!props.onIconClick) return null;
    return props.onIconClick();
  };

  const getValue = () => {
    if (!('value' in props)) return props.defaultValue;
    return props.value;
  };

  return (
    <StyledContainer onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      {
        props.isTextArea
          ? (
            <StyledTextArea
              placeholder={props.placeholder}
              styles={props.styles}
              onChange={(e: any) => handleInputChange(e.target.value!)}
              value={getValue()}
              disabled={props.disabled || false}
            />
          )
          : (
            <StyledInput
              placeholder={props.placeholder}
              styles={props.styles}
              onChange={(e: any) => handleInputChange(e.target.value!)}
              value={getValue()}
              disabled={props.disabled || false}
            />
          )
      }
      { (props.iconType === IconType.EDIT)
      && (
        <StyledEditIcon
          iconposright={props.iconPosRight}
          iconpostop={props.iconPosTop}
          iconcolor={props.iconColor}
          onClick={() => handleIconClick()}
        />
      )}
      { (props.iconType === IconType.DATE)
      && (
        <StyledDateIcon
          iconposright={props.iconPosRight}
          iconpostop={props.iconPosTop}
          iconcolor={props.iconColor}
        />
      )}
    </StyledContainer>
  );
};

export default CustomInput;
