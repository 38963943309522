import React from 'react';
import {
  StyledContainer,
} from './style';

import ExpertSearchPosition from './expertSearchPosition';

interface IProps {
  visible: boolean;
  selectedProjectId: string | null;
  expertises: ExpertiseType[];
  setEditedExpertSearch: (expertSearch: ExpertSearchEditType | null) => void;
  selectedExpertSearches: ExpertSearchType[];
  setSelectedExpertSearches: (searchList: ExpertSearchType[]) => void;
  selectedExpertSearchId: string | null;
  setSelectedExpertSearchId: (id: string | null) => void;
  setSelectedExpertSearchResults: (expertSearchResults: ExpertSearchResultType[]) => void;
  expertsShortlisted: ExpertShortlistedType[];
  setExpertsShortlisted: (expertsShortlisted: ExpertShortlistedType[]) => void;
  createSearch: (newSearch: NewExpertSearchEditType) => void;
}

const ExpertSearch: React.FC<IProps> = (props: IProps) => {
  const handleNewExpertPositionButtonClick = async () => {
    const newSearch = {
      projectId: props.selectedProjectId,
      description: '',
      position: 'CONSULTING',
      expertiseIds: [],
      idealExpertIds: [],
      name: `Search ${props.selectedExpertSearches.length + 1}`,
    } as ExpertSearchEditType;

    props.createSearch(newSearch);
  };

  return (
    <StyledContainer visible={props.visible}>
      <ExpertSearchPosition
        selectedProjectSearches={props.selectedExpertSearches}
        selectedExpertSearchId={props.selectedExpertSearchId}
        setSelectedExpertSearchId={props.setSelectedExpertSearchId}
        expertises={props.expertises}
        setEditedExpertSearch={props.setEditedExpertSearch}
        setSelectedExpertSearchResults={props.setSelectedExpertSearchResults}
        expertsShortlisted={props.expertsShortlisted}
        setExpertsShortlisted={props.setExpertsShortlisted}
        setSelectedExpertSearches={props.setSelectedExpertSearches}
        handleNewExpertSearchPosition={handleNewExpertPositionButtonClick}
      />
    </StyledContainer>
  );
};

export default ExpertSearch;
