import React, { useState, useEffect } from 'react';
import Menu from '@material-ui/core/Menu';
import { PopoverOrigin } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LabelIconGreen from '../../../../../../assets/svg/LabelIconGreen.svg';
import LabelIconWhite from '../../../../../../assets/svg/LabelIconWhite.svg';
import StarIconGreen from '../../../../../../assets/svg/StarIconGreen.svg';
import StarIconWhite from '../../../../../../assets/svg/StarIconWhite.svg';
import {
  StyledIconsContainer,
  StyledDotsMenuButton,
  StyledLabelIcon,
  StyledMenuTitle,
  StyledStarIcon,
  StyledDeleteIcon,
  useMenuListStyles,
} from './style';

interface IProps {
  isExpertFavorite: (expertId: string) => boolean;
  handleDelete: (expertSearchResultId: string) => void;
  isExpertShortlisted: (expertId: string) => boolean;
  item: ExpertSearchResultType;
  handleShortListExpert: (expertId: string) => void;
  handleFavoriteExpert: (expertId: string) => void;
  handleCheckedList: (expertId: string, value: boolean) => void;
}

const MenuResult = (props: IProps) => {
  const classes = useMenuListStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorPosition, setAnchorPosition] = useState<any>(null);
  const [viewportHeight, setViewportHeight] = useState<any>(null);

  useEffect(() => {
    if (anchorEl) return props.handleCheckedList(props.item.expertId, true);

    return props.handleCheckedList(props.item.expertId, false);
  }, [anchorEl]);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorPosition(null);
  };

  const handleDotsButtonClick = (currentTarget: Element) => {
    const currentPosition = currentTarget.getBoundingClientRect();
    setAnchorPosition(currentPosition);
    setViewportHeight(window.innerHeight);
    setAnchorEl(currentTarget);
  };
  const handleShortListExpertClick = (expertId: string) => {
    handleMenuClose();
    if (!props.handleShortListExpert) return;
    props.handleShortListExpert(expertId);
  };

  const handleFavoriteExpertClick = (expertId: string) => {
    handleMenuClose();
    if (!props.handleFavoriteExpert) return;
    props.handleFavoriteExpert(expertId);
  };

  const getAnchorOrigin = () => {
    if (!anchorPosition) return { vertical: 'bottom', horizontal: 'left' };
    const posFromBottom = viewportHeight - anchorPosition.bottom;

    if (posFromBottom >= 110) {
      return { vertical: 'bottom', horizontal: 'left' };
    }

    return { vertical: 'top', horizontal: 'left' };
  };

  const getTransformOrigin = () => {
    if (!anchorPosition) return { vertical: 'top', horizontal: 'left' };
    const posFromBottom = viewportHeight - anchorPosition.bottom;

    if (posFromBottom >= 110) {
      return { vertical: 'top', horizontal: 'left' };
    }

    return { vertical: 'bottom', horizontal: 'left' };
  };
  return (
    <StyledIconsContainer onClick={(e) => e.stopPropagation()}>
      <StyledDotsMenuButton handleClick={handleDotsButtonClick} />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: '100px',
            width: '200px',
            padding: 0,
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={getAnchorOrigin() as PopoverOrigin}
        transformOrigin={getTransformOrigin() as PopoverOrigin}
      >
        <MenuList classes={{ root: classes.root }}>
          <MenuItem
            onClick={() => handleShortListExpertClick(props.item.expertId)}
          >
            <ListItemIcon>
              <StyledLabelIcon
                src={
                  props.isExpertShortlisted(props.item.expertId) ? LabelIconGreen : LabelIconWhite
                }
              />
            </ListItemIcon>
            <StyledMenuTitle>SHORTLIST EXPERT</StyledMenuTitle>
          </MenuItem>
          <MenuItem
            onClick={() => handleFavoriteExpertClick(props.item.expertId)}
          >
            <ListItemIcon>
              <StyledStarIcon
                src={
                  props.isExpertFavorite(props.item.expertId) ? StarIconGreen : StarIconWhite
                }
              />
            </ListItemIcon>
            <StyledMenuTitle>ADD TO FAVORITES</StyledMenuTitle>
          </MenuItem>
        </MenuList>
      </Menu>
      <StyledDeleteIcon onClick={() => props.handleDelete(props.item._id)} />
    </StyledIconsContainer>
  );
};

export default MenuResult;
