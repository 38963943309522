import styled from 'styled-components';

interface IContainerProps {
  areBothSidebarsOpen: boolean;
}

export const StyledContainer = styled.div<IContainerProps>`
  
  width: 100%;
  padding: 20px 0 30px 0; 
  display: flex;
  flex-flow: row nowrap;  
  justify-content: space-between;  
  align-items: center;
  height: auto;   

  @media (max-width: 1650px) {
    padding: ${(props: IContainerProps) => props.areBothSidebarsOpen ? '0 0 30px 0' : '20px 0 30px 0'}; 
    flex-flow: ${(props: IContainerProps) => props.areBothSidebarsOpen ? 'column nowrap' : 'row nowrap'};  
    justify-content: ${(props: IContainerProps) => props.areBothSidebarsOpen ? 'flex-start' : 'space-between'};  
    height: ${(props: IContainerProps) => props.areBothSidebarsOpen ? '80px' : 'auto'};      
  }

`;

export const StyledLeftPanelContainer = styled.div<IContainerProps>`

  flex: 0 1 350px;
  width: auto;
  margin-bottom: 0; 

   @media (max-width: 1650px) {

    margin-bottom: ${(props: IContainerProps) => props.areBothSidebarsOpen ? '20px' : '0'}; 
    width: ${(props: IContainerProps) => props.areBothSidebarsOpen ? '450px' : '350px'};     
  } 

`;

export const StyledRightPanelContainer = styled.div<IContainerProps>`

  flex: 1 1 0;
  width: auto;  
  max-width: 600px;  
  display: flex;
  justify-content: space-between;
  align-items: center;  

  @media (max-width: 1650px) {

    width: ${(props: IContainerProps) => props.areBothSidebarsOpen ? '100%' : 'auto'};  
    max-width: ${(props: IContainerProps) => props.areBothSidebarsOpen ? '100%' : '600px'};  
    justify-content: ${(props: IContainerProps) => props.areBothSidebarsOpen ? 'center' : 'space-between'};  
    
  }
`;
