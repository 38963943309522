import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface ProjectContextType {
  projects: ProjectType[];
  setProjects: (projects: ProjectType[]) => void;
  selectedProjectId: string | null;
  setSelectedProjectId: (projectId: string | null) => void;
  updateProject: boolean;
  setUpdateProject: (value: boolean) => void;
  selectedExpertSearches: ExpertSearchType[];
  setSelectedExpertSearches: (searchList: ExpertSearchType[]) => void;
  selectedExpertSearchId: string | null;
  setSelectedExpertSearchId: (id: string | null) => void;
  selectedExpertSearchResults: ExpertSearchResultType[];
  setSelectedExpertSearchResults: (expertSearchResults: ExpertSearchResultType[]) => void;
}

export const ProjectContext = React.createContext<ProjectContextType>({
  projects: [],
  setProjects: (value: ProjectType[]) => value,
  selectedProjectId: null,
  setSelectedProjectId: (id: string | null) => id,
  updateProject: false,
  setUpdateProject: () => null,
  selectedExpertSearches: [],
  setSelectedExpertSearches: (searchList: ExpertSearchType[]) => searchList,
  selectedExpertSearchId: null,
  setSelectedExpertSearchId: (id: string | null) => id,
  selectedExpertSearchResults: [],
  setSelectedExpertSearchResults: (searchResults: ExpertSearchResultType[]) => searchResults,
});

interface IProps {
  children: React.ReactNode;
}

const ProjectContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [projects, setProjects] = useState<ProjectType[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedExpertSearches, setSelectedExpertSearches] = useState<ExpertSearchType[]>([]);
  const [selectedExpertSearchId, setSelectedExpertSearchId] = useState<string | null>(null);
  const [selectedExpertSearchResults, setSelectedExpertSearchResults] = useState<ExpertSearchResultType[]>([]);
  const [updateProject, setUpdateProject] = useState(false);
  const history = useHistory();

  const contextValue = {
    projects,
    setProjects,
    selectedProjectId,
    setSelectedProjectId: (value: string | null) => {
      setSelectedProjectId(value);
      history.push(`/projects/${value}`);
    },
    selectedExpertSearches,
    setSelectedExpertSearches,
    selectedExpertSearchId,
    setSelectedExpertSearchId: (value: string | null) => {
      setSelectedExpertSearchId(value);
      const projectURL = history.location.pathname.split('/searches')[0];
      if (!value) {
        history.push(`${projectURL}`);
        return;
      }
      history.push(`${projectURL}/searches/${value}`);
    },
    selectedExpertSearchResults,
    setSelectedExpertSearchResults,
    updateProject,
    setUpdateProject,
  };

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
