import styled from 'styled-components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const StyledContainer = styled.ul`
  height: 348px;
  padding: 0;
  overflow-y: auto;
`;

const getColor = (color: string) => {
  if (color === 'secondary') return 'lightgray';
  if (color === 'primary') return '#8DC63F';
  return 'lightgray';
};

interface IStyledCheckIcon {
  disabled?: boolean;
}

export const StyledCheckCircleIcon = styled(CheckCircleIcon)<IStyledCheckIcon>`
  color: ${(props) => getColor(props.color as string)};
  position: relative;
  margin-left: 8px;
  &:hover {
    cursor: ${(props) => props.disabled ? 'inherit' : 'pointer'};
  }
`;
