function pad(s: number) { return (s < 10) ? `0${s}` : `${s}`; }

export const getFormattedDateNDaysBeforeToday = (days: number) => {
  const today = new Date();

  const initialDate = new Date(today.getTime() - (days * 24 * 60 * 60 * 1000));

  const day = initialDate.getDate();

  const month = initialDate.getMonth() + 1;

  const year = initialDate.getFullYear();

  const formattedDate = `${year}-${pad(month)}-${pad(day)}`;

  return formattedDate;
};

export const getFormattedDateStringYMD = (date: Date) => {
  const day = date.getDate();

  const month = date.getMonth() + 1;

  const year = date.getFullYear();

  const formattedDate = `${year}-${pad(month)}-${pad(day)}`;

  return formattedDate;
};

export const getFormattedDateStringMDY = (dateString: string | undefined) => {
  if (!dateString) return '';
  const year = dateString.substring(0, 4);

  const month = dateString.substring(5, 7);

  const day = dateString.substring(8, 10);

  const formattedDate = `${month}-${day}-${year}`;

  return formattedDate;
};
