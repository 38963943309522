import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

export const StyledContainer = styled.div`
  grid-area: footer;
  
`;

export const StyledButtonContainer = styled.div`

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;    
`;

export const StyledAddButton = styled(Button)`

  && {
    background-color: #8DC63F;
    color: white;
    margin-right: 25px;    
    font-size: 10px;
    font-weight: bold;
    font-family: Cairo;  
    padding: 10px 15px;    
  }

  &:disabled {
    background-color: #F5F5F5;
    color: #B4B4B4;
  }
`;

export const StyledAddIcon = styled(AddCircleOutlineOutlinedIcon)`

  && {
    color: white;
    margin-right: 10px;
    font-size: 18px;
  }
`;
