import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  StyledContainer,
  StyledButtonContainer,
  StyledDoubleArrowIcon,
} from './style';

import SidebarContent from './rightSidebarContent';
import ProspectsContent from './prospectsContent';

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedProjectId: string | null;
  selectedExpertSearches: ExpertSearchType[];
  setUpdateProject: (value: boolean) => void;
}

const RightSideBar: React.FC<IProps> = (props: IProps) => {
  const location = useLocation();

  const prospectRoutes = ['/prospector'];

  const otherRoutes = ['/experts', '/projects'];

  const handleButtonClick = () => {
    if (props.isOpen) return props.setIsOpen(false);
    return props.setIsOpen(true);
  };

  const displaySidebarContent = () => {
    const currentLocation = location.pathname;

    if (prospectRoutes.some((item: string) => currentLocation.includes(item))) {
      return (
        <ProspectsContent
          isOpen={props.isOpen}
          setIsOpen={props.setIsOpen}
        />
      );
    }
    if (otherRoutes.some((item: string) => currentLocation.includes(item))) {
      return (
        <SidebarContent
          isOpen={props.isOpen}
          selectedProjectId={props.selectedProjectId}
          setIsOpen={props.setIsOpen}
          selectedExpertSearches={props.selectedExpertSearches}
          setUpdateProject={props.setUpdateProject}
        />
      );
    }

    return null;
  };

  return (
    <StyledContainer $isOpen={props.isOpen}>
      <StyledButtonContainer
        onClick={handleButtonClick}
      >
        <StyledDoubleArrowIcon $isOpen={props.isOpen} />
      </StyledButtonContainer>
      {displaySidebarContent()}
    </StyledContainer>
  );
};

export default RightSideBar;
