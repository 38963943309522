import React from 'react';
import CustomInput from '../../../../../../components/customInput';

import {
  StyledFlexSection,
  StyledSectionCol,
  StyledSectionTitle,
  inputStyles,
} from '../style';

interface IProps {
  courtCase: CourtCaseType | null;
}

const SectionAssignedTo: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledFlexSection>
      <StyledSectionCol width="47%">
        <StyledSectionTitle>Assigned To</StyledSectionTitle>
        <CustomInput
          defaultValue={props.courtCase?.assignedTo || ''}
          disabled
          styles={inputStyles}
        />
      </StyledSectionCol>
      <StyledSectionCol width="47%">
        <StyledSectionTitle>Court</StyledSectionTitle>
        <CustomInput
          defaultValue={props.courtCase?.court || ''}
          disabled
          styles={inputStyles}
        />
      </StyledSectionCol>
    </StyledFlexSection>
  );
};

export default SectionAssignedTo;
