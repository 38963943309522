import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  FormControl,
} from '@material-ui/core';

export const StyledContainer = styled.div`

  flex: 0 0 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  margin-right: 5px;
`;

export const StyledFormControl = styled(FormControl)`
  && {
    width: 130px; 
    background: 'white',
  }  

  & > div.MuiOutlinedInput-root {   
      border-radius: 0; 
      background: 'white',
  }    
    
`;

interface ISelectProps {
  disabled: boolean;
}

export const useStyles = makeStyles({
  select: {
    minWidth: 130,
    background: (props: ISelectProps) => props.disabled ? '#DEDEDE' : 'white',
    color: (props: ISelectProps) => props.disabled ? '#A1A1A1' : '#304F5A',
    border: 'none',
    borderRadius: 0,
    paddingLeft: 30,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    fontSize: 12,
    fontFamily: 'Cairo',
    fontWeight: 'bold',
    boxSizing: 'border-box',
    '&:focus': {
      background: 'white',
      borderRadius: 0,
      border: '1px solid #8DC63F',
    },
  },
  icon: {
    color: (props: ISelectProps) => props.disabled ? '#A1A1A1' : '#304F5A',
    marginTop: 0,
    right: 2,
    position: 'absolute',
    flexShrink: 0,
    userSelect: 'none',
    pointerEvents: 'none',
    fontSize: 22,
  },
  paper: {
    borderRadius: 0,
    marginTop: 8,
    border: '1px solid #8DC63F',
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    '& li': {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 12,
      color: '#304F5A',
      fontFamily: 'Cairo',
      fontWeight: 'normal',
    },
    '& li:hover': {
      background: '#E3E3E3',
    },
    '& li.Mui-selected': {
      color: 'white',
      background: '#8DC63F',
    },
    '& li.Mui-selected:hover': {
      background: '#E3E3E3',
    },
  },
});

interface IIconProps {
  $disabled: boolean;
}

export const StyledDecorativeIcon = styled(DateRangeIcon)<IIconProps>`

  && {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 50%;
    margin-top: -7.5px;
    left: 10px;
    color: ${(props: IIconProps) => props.$disabled ? '#A1A1A1' : '#304F5A'};
  }

 

`;
