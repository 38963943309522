import styled from 'styled-components';

export const StyledContainer = styled.div`
  grid-area: body;
  padding: 0;  
  padding-left: 15px;
  padding-right: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 20px;
`;

export const StyledResultsCount = styled.h3`
  width: 100%;
  font-family: Cairo;  
  font-weight: normal;
  font-size: 12px;
  text-align: left;
`;
