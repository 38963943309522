import React, { useState } from 'react';
import Fuse from 'fuse.js';
import Grid from '@material-ui/core/Grid';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  StyledContainer,
  StyledInnerContainer,
  StyledButtonsContainer,
  StyledToggleButton,
  StyledButtonCountSpan,
  StyledTotalResults,
  StyledAddExpertButton,
  LoadingWithSpinner,
  CenterContainer,
  BoldTitle,
} from './style';

import CustomSearch from '../../../../components/customSearch';
import ResultList from './resultList';
import DataSourceLabel from '../../../../components/dataSourceLabel';

import { deleteExpertSearchResult } from '../../../../entities/expertSearchResult/service';
import ModalWrapper from '../../../../components/modalWrapper';
import AddExpertModal from '../../../../components/addExpertModal';
import * as expertService from '../../../../entities/expert/service';
import * as expertSearchResultService from '../../../../entities/expertSearchResult/service';
import * as expertSearchUtils from '../../../../entities/expertSearch/utils';

const StyledLoadingSpinner: React.FC = () => (
  <LoadingWithSpinner>
    <CenterContainer>
      <CircularProgress style={{ color: '#8DC63F' }} />
      <BoldTitle>Loading results</BoldTitle>
    </CenterContainer>
  </LoadingWithSpinner>
);

interface IProps {
  selectedExpertSearchResults: ExpertSearchResultType[];
  setSelectedExpertSearchResults: (expertSearchResults: ExpertSearchResultType[]) => void;
  isSelectedSearchEditable: boolean;
  selectedExpertSearchId: string | null;
  getExpert: (expertId: string) => ExpertType | null;
  isUpdatingSearch: boolean;
  setIsUpdatingSearch: (isUpdating: boolean) => void;
  $isOpen: boolean;
  setExperts: (expert: ExpertType[]) => void;
  selectedExpertSearches: ExpertSearchType[];
  setSelectedExpertSearches: (searchList: ExpertSearchType[]) => void;
}

enum ExpertFilters {
  Recommended = 'recommended',
  Keystone = 'keystone',
  Added = 'added',
}

const ExpertSearchResults: React.FC<IProps> = (props: IProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [activatedFilters, setActivatedFilters] = useState<ExpertFilters[]>([
    ExpertFilters.Keystone,
    ExpertFilters.Recommended,
    ExpertFilters.Added,
  ]);
  const [filterByName, setFilterByName] = useState<string>('');
  const expertSearch = expertSearchUtils.getExpertSearchObject(
    props.selectedExpertSearchId, props.selectedExpertSearches,
  );

  const handleSearchModal = async (name: string, cursor: string | null) => {
    const paginationExpert = await expertService.fetchExpertsByName(name, cursor);
    return paginationExpert;
  };

  const handleAdd = async (idealExpertIds: string[]) => {
    if (!props.selectedExpertSearchId) return;
    const filteredIdealExpertIds = idealExpertIds.filter((expId) => !expertSearch.idealExpertIds.includes(expId));

    const newResults = await expertSearchResultService.addManuallyExpertSearchResult(
      filteredIdealExpertIds, props.selectedExpertSearchId,
    );
    const newExperts = newResults.experts;
    const newExpertSearchResults = newResults.results;
    const newSearch = newResults.search;
    props.setExperts(newExperts);
    props.setSelectedExpertSearchResults(newExpertSearchResults);

    const updatedExpertSeachList = props.selectedExpertSearches.map((s) => {
      if (s._id === newSearch._id) return newSearch;
      return s;
    });

    props.setSelectedExpertSearches(updatedExpertSeachList);
  };

  if (props.isUpdatingSearch) return <StyledLoadingSpinner />;
  if (props.isSelectedSearchEditable) return null;

  const handleExpertSearchResultDeletion = async (expertSearchResultId: string) => {
    await deleteExpertSearchResult(expertSearchResultId);

    const newExpertSearchResults = props.selectedExpertSearchResults.filter(
      (expertSearchResult: ExpertSearchResultType) => {
        return expertSearchResult._id !== expertSearchResultId;
      },
    );
    props.setSelectedExpertSearchResults(newExpertSearchResults);
  };

  const getFilteredResults = (expertSearchResults: ExpertSearchResultType[]) => {
    const filteredResults = expertSearchResults.filter((expertSearchResult: ExpertSearchResultType) => {
      if (activatedFilters.includes(ExpertFilters.Recommended)) return true;
      if (activatedFilters.includes(ExpertFilters.Added)) {
        return expertSearch
          .idealExpertIds
          .includes(expertSearchResult.expertId);
      }
      if (activatedFilters.includes(ExpertFilters.Keystone)) {
        const expertObject = props.getExpert(expertSearchResult.expertId);
        if (!expertObject) return false;
        return expertObject.is_keystone_expert;
      }
      return false;
    });
    return filteredResults;
  };

  const getFilteredResultsBySearch = (expertSearchResults: ExpertSearchResultType[]) => {
    if (!filterByName) {
      return expertSearchResults;
    }
    const resultsWithExpertName = expertSearchResults.map((expertSearchResult: ExpertSearchResultType) => {
      const expertObject = props.getExpert(expertSearchResult.expertId);
      return {
        ...expertSearchResult,
        name: expertObject ? `${expertObject.firstName} ${expertObject.lastName}` : '',
      };
    });

    const fuseOptions = {
      keys: ['name'],
      includeScore: true,
      shouldSort: true,
    };
    const fuse = new Fuse(resultsWithExpertName, fuseOptions);

    const fuseResults = fuse.search(filterByName);

    const resultsWithoutName = fuseResults.map((result: any) => {
      const { name, ...rest } = result.item;

      return rest;
    });
    return resultsWithoutName;
  };

  const getKeystoneResultsCount = (filteredSearchResults: ExpertSearchResultType[]) => {
    const ksExpertsResults = filteredSearchResults.filter((expertSearchResult: ExpertSearchResultType) => {
      const expertObject = props.getExpert(expertSearchResult.expertId);
      if (!expertObject) return false;
      return expertObject.is_keystone_expert;
    });

    return ksExpertsResults.length;
  };

  const handleToggleButton = (event: React.MouseEvent<HTMLElement>, value: ExpertFilters[]) => {
    setActivatedFilters(value);
  };

  const resultsFilteredBySearch = getFilteredResultsBySearch(props.selectedExpertSearchResults);
  const filteredSearchResults = getFilteredResults(resultsFilteredBySearch);

  const expertsRecommended = props
    .selectedExpertSearchResults
    .filter((exp) => !expertSearch?.idealExpertIds.includes(exp.expertId));
  const keystoneExpertsFilteredBySearchCount = getKeystoneResultsCount(resultsFilteredBySearch);
  const addedManuallyExpertsFiltered = props
    .selectedExpertSearchResults
    .filter((exp) => expertSearch?.idealExpertIds.includes(exp.expertId));

  const handleSearch = () => {
    props.setIsUpdatingSearch(true);
    setTimeout(() => {
      props.setIsUpdatingSearch(false);
    }, 700);
  };

  return (
    <StyledContainer $isOpen={props.$isOpen} $listElements={filteredSearchResults.length}>
      <StyledInnerContainer>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: 20 }}
        >
          <Grid item container spacing={2} alignItems="center" xs>
            <Grid item>
              <CustomSearch
                value={filterByName}
                handleChange={(value: string) => setFilterByName(value)}
                onExecute={handleSearch}
                handleClear={() => setFilterByName('')}
                styles={{
                  maxWidth: '350px',
                  margin: '5px 0',
                }}
                placeholder="Search by name"
              />
            </Grid>
            <Grid item>
              <StyledButtonsContainer
                value={activatedFilters}
                onChange={handleToggleButton}
              >
                <StyledToggleButton value={ExpertFilters.Keystone}>
                  Keystone
                  <StyledButtonCountSpan>{`(${keystoneExpertsFilteredBySearchCount})`}</StyledButtonCountSpan>
                </StyledToggleButton>
                <StyledToggleButton value={ExpertFilters.Recommended}>
                  Recommended
                  <StyledButtonCountSpan>{`(${expertsRecommended.length})`}</StyledButtonCountSpan>
                </StyledToggleButton>
                <StyledToggleButton value={ExpertFilters.Added}>
                  Added
                  <StyledButtonCountSpan>{`(${addedManuallyExpertsFiltered.length})`}</StyledButtonCountSpan>
                </StyledToggleButton>
              </StyledButtonsContainer>
            </Grid>
          </Grid>
          <Grid item>
            <DataSourceLabel
              labelName="Academic Publications & Patents Graph"
              link="https://academic.microsoft.com/home"
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ marginBottom: 20 }}
        >
          <Grid item xs>
            <StyledTotalResults>
              Results found:
              {' '}
              {filteredSearchResults?.length}
            </StyledTotalResults>
          </Grid>
          <Grid item>
            <StyledAddExpertButton
              color="secondary"
              startIcon={<PersonAddIcon />}
              onClick={() => setOpenModal(true)}
            >
              Add an expert to this search
            </StyledAddExpertButton>
          </Grid>
        </Grid>
        {props.isUpdatingSearch && <StyledLoadingSpinner />}
        <ResultList
          items={filteredSearchResults}
          handleDelete={handleExpertSearchResultDeletion}
          getExpert={props.getExpert}
        />
      </StyledInnerContainer>
      <ModalWrapper isModalOpen={openModal} handleModal={() => setOpenModal(false)}>
        <AddExpertModal
          handleAdd={handleAdd}
          handleSearch={handleSearchModal}
          onCancel={() => setOpenModal(false)}
          selectedExpertIds={expertSearch?.idealExpertIds ?? []}
        />
      </ModalWrapper>
    </StyledContainer>
  );
};

export default ExpertSearchResults;
