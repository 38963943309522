import React from 'react';

import {
  StyledDescriptionSection,
  StyledSectionTitle,
  textAreaStyles,
} from '../style';

import CustomInput from '../../../../../../components/customInput';

interface IProps {
  caseDescription: string;
}

const SectionDescription: React.FC<IProps> = (props:IProps) => {
  return (
    <StyledDescriptionSection>
      <StyledSectionTitle>Description:</StyledSectionTitle>
      <CustomInput
        defaultValue={props.caseDescription}
        value={props.caseDescription}
        disabled
        styles={{
          ...textAreaStyles,
          textAreaHeight: '100px',
          padding: '15px 35px 5px 10px',
        }}
        isTextArea
      />
    </StyledDescriptionSection>
  );
};

export default SectionDescription;
