import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CancelIcon from '@material-ui/icons/Cancel';

export const StyledIconsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 28px;
  margin-right: 3%;
`;

interface DotsMenuButtonProps {
  handleClick: (currentTarget: any) => void;
}

const useDotsMenuButtonStyles = makeStyles({
  root: {
    transition: 'all .3s linear',
    '&:hover': {
      backgroundColor: '#757575',
    },
  },
});

export const StyledDotsMenuButton: React.FC<DotsMenuButtonProps> = (props: DotsMenuButtonProps) => {
  const classes = useDotsMenuButtonStyles();

  return (
    <IconButton
      classes={{ root: classes.root }}
      size="small"
      onClick={(e: any) => props.handleClick(e.currentTarget)}
    >
      <StyledDotsIcon />
    </IconButton>
  );
};

const StyledDotsIcon = styled(MoreVertIcon)`

  && {
    color: #757575;

  }

  &:hover {
    cursor: pointer;
    color: white;
  }

`;

export const StyledLabelIcon = styled.img`

  && {
    width: 20px;
    height: 20px;
    margin-left: 2px;
  }
    
  &:hover {
    cursor: pointer;
  }

`;

export const StyledMenuTitle = styled.h4`

  padding: 0;
  margin: 0;
  font-size: 10px;
  font-weight: bold;
  font-family: Cairo;
  color: #686868;

`;

export const StyledStarIcon = styled.img`

  && {
    width: 20px;
    height: 20px;
    margin-left: 2px;
  }
    
  &:hover {
    cursor: pointer;
  }

`;

export const StyledDeleteIcon = styled(CancelIcon)`

  && {
    color: #E4E4E4;
    transition: all .3s linear;
  }

  &:hover {
    cursor: pointer;
    color: #757575;
  }

`;

export const useMenuListStyles = makeStyles({
  root: {
    padding: 0,
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
});
