import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import {
  StyledContainer,
  StyledSection,
  StyledSectionTitle,
  inputStyles,
  StyledInputContainer,
  StyledSaveButton,
  StyledPanelButtonContainer,
  StyledDoubleArrowIcon,
  StyledSubContainer,
  StyledPanelClosedTitle,
  StyledEditIcon,
  StyledLabel,
  StyledInfoIcon,
  StyledSectionSub,
  StyledIconButton,
  StyledExpertDescriptionSubContainer,
  StyledExpertDescriptionLabel,
} from './style';

import ExpertSearchCustomInput from '../../../../components/customInput';

import ExpertiseTags from './expertiseTags';

import ExpertiseIdeal from './expertiseIdeal';

import * as expertSearchUtils from '../../../../entities/expertSearch/utils';
import ExpertPosition from '../../header/expertPosition';

interface IProps {
  setEditedExpertSearch: (expertise: ExpertSearchEditType | null) => void;
  selectedExpertSearches: ExpertSearchType[];
  selectedExpertSearchId: string | null;
  editedExpertSearch: ExpertSearchEditType | null;
  handleExpertiseTagDeletion: (expertiseId: string) => void;
  expertises: ExpertiseType[];
  isUpdatingSearch: boolean;
  isSelectedSearchEditable: boolean;
  currentDescription: string;
  setCurrentDescription: (value: string) => void;
  setSelectedExpertSearchId: (id: string | null) => void;
  $isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  updateSearch: (newExpertSearch: ExpertSearchType) => void;
  executeSearch: (newExpertSearch: ExpertSearchType) => void;
  getExpert: (expertId: string) => ExpertType | null;
}

const ExpertSearch: React.FC<IProps> = (props: IProps) => {
  const searchObject : ExpertSearchType | undefined = expertSearchUtils.getExpertSearchObject(
    props.selectedExpertSearchId,
    props.selectedExpertSearches,
  ) as ExpertSearchType | undefined;

  const handlePanelButtonClick = () => {
    if (props.$isOpen) return props.setIsOpen(false);
    return props.setIsOpen(true);
  };

  const handleDescriptionChange = (descriptionValue: string) => {
    props.setCurrentDescription(descriptionValue);
  };

  const isCreateUpdateButtonDisabled = () => {
    if (props.isUpdatingSearch) return true;

    if (!props.isSelectedSearchEditable) return true;
    return isEditedSearchEqualObjectSearch();
  };

  const isEditedSearchEqualObjectSearch = () => {
    if (!props.editedExpertSearch) return false;

    const editedExpertiseIds = props.editedExpertSearch.expertiseIds;
    const objectExpertiseIds = searchObject?.expertiseIds ?? [];
    const hasEqualExpertiseId = editedExpertiseIds.length === objectExpertiseIds.length
      && editedExpertiseIds.every(
        (expId) => objectExpertiseIds.includes(expId),
      );
    const hasEqualDescription = props?.currentDescription === searchObject?.description;
    const editedIdealExpertIds = props.editedExpertSearch.idealExpertIds;
    const objectIdealExpertIds = searchObject?.idealExpertIds ?? [];
    const hasEqualIdealExpertIds = editedIdealExpertIds.length === objectIdealExpertIds.length
      && editedIdealExpertIds.every(
        (expId) => objectIdealExpertIds?.includes(expId),
      );
    return hasEqualIdealExpertIds && hasEqualDescription && hasEqualExpertiseId;
  };

  const handleEditIconClick = (searchId: string) => {
    props.setSelectedExpertSearchId(searchId);
    const search = props.selectedExpertSearches.find((s) => s._id === searchId);
    if (search) props.setEditedExpertSearch(search);
  };

  const handleCancelButtonClick = () => {
    props.setEditedExpertSearch(null);
  };

  const getCurrentSearchPositionValue = () => {
    if (props.editedExpertSearch) {
      return props.editedExpertSearch.position;
    }
    return expertSearchUtils.getExpertSearchObject(
      props.selectedExpertSearchId, props.selectedExpertSearches,
    )?.position as 'CONSULTING' | 'TESTIFYING';
  };

  const handlePositionChange = (positionValue: 'CONSULTING' | 'TESTIFYING') => {
    if (!props.editedExpertSearch) return;
    // Validation in order to avoid requests
    if (!searchObject || positionValue === props.editedExpertSearch.position) return;
    props.updateSearch({ ...searchObject, position: positionValue });
  };

  const displayExpertDescription = () => {
    return (
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item>
          <StyledLabel>Description:</StyledLabel>
        </Grid>
        <Grid item>
          <Tooltip title="Keywords to find on experts publications and patents">
            <StyledInfoIcon />
          </Tooltip>
        </Grid>
        <Grid item xs>
          {!props.isSelectedSearchEditable ? (
            <StyledExpertDescriptionSubContainer>
              <StyledExpertDescriptionLabel>
                {!props.currentDescription ? 'No info added' : props.currentDescription}
              </StyledExpertDescriptionLabel>
            </StyledExpertDescriptionSubContainer>
          ) : (
            <ExpertSearchCustomInput
              disabled={!props.isSelectedSearchEditable}
              defaultValue=""
              value={props.currentDescription ?? ''}
              onChange={handleDescriptionChange}
              placeholder="Add a Description Here"
              styles={inputStyles}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const handleUpdateSearch = () => {
    props.executeSearch(
      { ...props.editedExpertSearch as ExpertSearchType, description: props.currentDescription },
    );
  };
  const isReseting = !props.currentDescription
    && props.editedExpertSearch?.expertiseIds.length === 0
    && props.editedExpertSearch?.idealExpertIds.length === 0;

  return (
    <StyledContainer $isOpen={props.$isOpen} $isEditing={!!props.editedExpertSearch}>
      <StyledSubContainer $isOpen={props.$isOpen}>
        <StyledSection container alignItems="center" spacing={2} justify="space-between">
          <StyledSectionSub item xs>
            <StyledSectionTitle>
              Recomendation Criteria
            </StyledSectionTitle>
            {!props.editedExpertSearch && (
              <StyledIconButton
                color="primary"
                onClick={(
                      () => handleEditIconClick(props.selectedExpertSearchId as string)
                    )}
              >
                <Tooltip title="edit">
                  <StyledEditIcon
                    color="primary"
                    fontSize="small"
                  />
                </Tooltip>
              </StyledIconButton>
            )}
            <ExpertPosition
              editedExpertSearch={props.editedExpertSearch}
              positionValue={getCurrentSearchPositionValue()}
              handlePositionChange={handlePositionChange}
              $isEditing={props.isSelectedSearchEditable}
            />
          </StyledSectionSub>
          {props.editedExpertSearch && (
            <Grid item>
              <StyledSaveButton
                color="primary"
                onClick={handleCancelButtonClick}
                data-testid="edit-mode-back-button"
              >
                Back
              </StyledSaveButton>
              <StyledSaveButton
                color="secondary"
                onClick={handleUpdateSearch}
                disabled={isCreateUpdateButtonDisabled()}
                data-testid="edit-mode-update-search-button"
              >
                {!isEditedSearchEqualObjectSearch() && isReseting ? ('Reset Search') : ('Update Search')}
              </StyledSaveButton>
            </Grid>
          )}
        </StyledSection>
        <StyledInputContainer>
          <ExpertiseTags
            setEditedExpertSearch={props.setEditedExpertSearch}
            isSelectedSearchEditable={props.isSelectedSearchEditable}
            selectedExpertSearches={props.selectedExpertSearches}
            selectedExpertSearchId={props.selectedExpertSearchId}
            editedExpertSearch={props.editedExpertSearch}
            handleExpertiseTagDeletion={props.handleExpertiseTagDeletion}
            expertises={props.expertises}
          />
          <ExpertiseIdeal
            isSelectedSearchEditable={props.isSelectedSearchEditable}
            selectedExpertSearchId={props.selectedExpertSearchId}
            editedExpertSearch={props.editedExpertSearch}
            setEditedExpertSearch={props.setEditedExpertSearch}
            getExpert={props.getExpert}
            expertSearch={searchObject}
          />
          {displayExpertDescription()}
        </StyledInputContainer>
      </StyledSubContainer>
      <StyledPanelClosedTitle $isOpen={props.$isOpen}>Recommendation Criteria</StyledPanelClosedTitle>
      <StyledPanelButtonContainer onClick={handlePanelButtonClick}>
        <StyledDoubleArrowIcon $isOpen={props.$isOpen} />
      </StyledPanelButtonContainer>
    </StyledContainer>
  );
};

export default ExpertSearch;
