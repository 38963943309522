import { gql } from '@apollo/client';

export const GET_COURT_CASES = gql`
    query getCourtCases {
        courtCase_getAll {
          caseName
          docketNumber
          absolute_url
          description
          assignedTo
          court
          dateArgued
          dateFiled
          dateTerminated
          firm
          jurisdictionType 
          attorney
          party
        }
    }
`;
