import React, { useState, useContext } from 'react';
import _ from 'lodash';
import {
  StyledContainer,
  StyledToolbar,
  StyledButtonContainer,
  StyledStarIcon,
  StyledShortListIcon,
  StyledArrowDown,
} from './style';

import ShortlistedExperts from './shortlistedExperts';

import FavoriteExperts from './favoriteExperts';

import ArrowDown from '../../../../assets/svg/ArrowDown.svg';

import { ExpertContext, ExpertContextType } from '../../../../context/expertContext';

import { ExpertiseContext, ExpertiseContextType } from '../../../../context/expertisesContext';

interface IProps {
  isOpen: boolean;
  selectedProjectId: string | null;
  setIsOpen: (value: boolean) => void;
  selectedExpertSearches: ExpertSearchType[];
  setUpdateProject: (value: boolean) => void;
}

enum EnumContentToShow { SHORTLISTED, FAVORITES }

const RightSidebarContent: React.FC<IProps> = (props: IProps) => {
  const [contentToShow, setContentToShow] = useState<EnumContentToShow>(EnumContentToShow.SHORTLISTED);

  const expertsState = useContext<ExpertContextType>(ExpertContext);

  const expertiseState = useContext<ExpertiseContextType>(ExpertiseContext);

  const handleShortlistedButtonClick = () => {
    if (!props.selectedProjectId) return;

    props.setIsOpen(true);

    setContentToShow(EnumContentToShow.SHORTLISTED);
  };

  const handleFavoriteButtonClick = () => {
    props.setIsOpen(true);

    setContentToShow(EnumContentToShow.FAVORITES);
  };

  const showShortlistedExperts = !!props.selectedProjectId && contentToShow === EnumContentToShow.SHORTLISTED;

  const showFavoriteExperts = contentToShow === EnumContentToShow.FAVORITES;

  const isShortlistedButtonSelected = props.isOpen && showShortlistedExperts;

  const isFavoritesButtonSelected = props.isOpen && contentToShow === EnumContentToShow.FAVORITES;

  const expertsShortlisted = expertsState.expertsShortlisted;
  const selectedProjectSearchesShortlisted = props.selectedExpertSearches.filter((selectedProjectSearch) => {
    return expertsShortlisted.some((expertShortlisted) => expertShortlisted.searchId === selectedProjectSearch._id);
  });
  const expertisesShortilsted = expertiseState.expertises.filter((e) => {
    return selectedProjectSearchesShortlisted.some((s) => s.expertiseIds.some((a) => a === e._id));
  });
  const expertTypesShortlisted = expertiseState.expertTypes.filter((expertType) => {
    return expertisesShortilsted.some((expertise) => expertise.expertTypeId === expertType._id);
  });
  const uniqueShortlistedExpertType = _.uniqBy(expertTypesShortlisted, '_id');

  return (
    <StyledContainer
      $isOpen={props.isOpen}
      onClick={() => props.setUpdateProject(true)}
    >
      <StyledToolbar $isOpen={props.isOpen}>
        <StyledButtonContainer
          $isOpen={props.isOpen}
          disabled={!props.selectedProjectId}
          $selected={isShortlistedButtonSelected}
          onClick={handleShortlistedButtonClick}
        >
          <StyledShortListIcon
            disabled={!props.selectedProjectId}
            $selected={isShortlistedButtonSelected}
          />
          <StyledArrowDown src={ArrowDown} $visible={isShortlistedButtonSelected} />
        </StyledButtonContainer>
        <StyledButtonContainer
          $isOpen={props.isOpen}
          $selected={isFavoritesButtonSelected}
          onClick={handleFavoriteButtonClick}
        >
          <StyledStarIcon $selected={isFavoritesButtonSelected} />
          <StyledArrowDown src={ArrowDown} $visible={isFavoritesButtonSelected} />
        </StyledButtonContainer>
      </StyledToolbar>
      { showShortlistedExperts
       && (
       <ShortlistedExperts
         isOpen={props.isOpen}
         setExpertsShortlisted={expertsState.setExpertsShortlisted}
         shortlistedExpertTypes={uniqueShortlistedExpertType}
         expertsShortlisted={expertsShortlisted}
         getExpert={expertsState.getExpert}
       />
       ) }
      { showFavoriteExperts
      && (
      <FavoriteExperts
        isOpen={props.isOpen}
        expertFavorites={expertsState.expertFavorites}
        setExpertFavorites={expertsState.setExpertFavorites}
        getExpert={expertsState.getExpert}
      />
      ) }
    </StyledContainer>
  );
};

export default RightSidebarContent;
