import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

import {
  StyledContainer,
  StyledFormControl,
  StyledDecorativeIcon,
  useStyles,
  StyledMenuItemCheckbox,
  StyledMenuItemTitle,
} from './style';

interface IProps {
  selectedValues: string[];
  handleChange: (value: string[]) => void;
  disabled: boolean;
  keywordsList: string[]
}

const KeywordsSelect: React.FC<IProps> = (props: IProps) => {
  const classes: any = useStyles(props);

  const menuProps: any = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  return (
    <StyledContainer>
      <StyledFormControl>
        <Select
          disableUnderline
          data-testid="prospects-secFilings-searchBar-keywordsSelect-counter"
          value={props.selectedValues}
          multiple
          onChange={(e: any) => props.handleChange(e.target.value)}
          classes={{ root: classes.select, icon: classes.icon }}
          disabled={props.disabled}
          MenuProps={menuProps}
          displayEmpty
          renderValue={(values: any) => {
            if (!values.length) return 'Keywords';

            return `Keywords (${values.length})`;
          }}
        >
          {
            props.keywordsList.map((keyword: string, index: number) => {
              return (
                <MenuItem
                  key={keyword}
                  value={keyword}
                >
                  <StyledMenuItemCheckbox
                    checked={props.selectedValues.includes(keyword)}
                    data-testid={`prospects-secFilings-searchBar-keywordsSelect-option${index}`}
                  />
                  <StyledMenuItemTitle>{keyword}</StyledMenuItemTitle>
                </MenuItem>
              );
            })
          }
        </Select>
      </StyledFormControl>
      <StyledDecorativeIcon $disabled={props.disabled} />
    </StyledContainer>
  );
};

export default KeywordsSelect;
