import React from 'react';
import { useLocation } from 'react-router-dom';
import { PageNavWrapper, StyledLink, NavItem } from '../style';
import ProspectIcon from '../../../../assets/svg/prospects_nav_icon.svg';
import ExpertUserIcon from '../../../../assets/svg/experts_nav_icon.svg';
import { useAuth0 } from '../../../../react-auth0-spa';

const paths = {
  home: '/',
  prospects: '/prospector',
  experts: '/experts',
  expertsProject: '/projects',
};

const checkIsExpertsPath = (pathname: string) => {
  if (pathname.includes(paths.experts) || pathname.includes(paths.expertsProject)) {
    return true;
  }
  return false;
};

const checkIsProspectsPath = (pathname: string) => {
  if (pathname === paths.prospects) {
    return true;
  }
  return false;
};

const checkIsHomePath = (pathname: string) => {
  return pathname === paths.home;
};

const NavMenus: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const pathName = useLocation().pathname;
  const isHomeView = checkIsHomePath(pathName);
  return (
    <>
      { isAuthenticated && !isHomeView
        ? (
          <PageNavWrapper>
            <StyledLink to={paths.prospects}>
              <NavItem selected={checkIsProspectsPath(pathName)}>
                <img src={ProspectIcon} alt="" />
                <span>Prospects</span>
              </NavItem>
            </StyledLink>
            <StyledLink to={paths.experts}>
              <NavItem selected={checkIsExpertsPath(pathName)}>
                <img src={ExpertUserIcon} alt="" />
                <span>Experts</span>
              </NavItem>
            </StyledLink>
          </PageNavWrapper>
        )
        : null}
    </>
  );
};

export default NavMenus;
