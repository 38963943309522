import styled from 'styled-components';

export const StyledContainer = styled.div`

  width: 100%;
  margin: 0;
  padding: 0;
    
`;

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
`;
