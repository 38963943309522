import _ from 'lodash';
import { GET_EXPERTISES } from './query';

import client from '../../../communication/client';

export const getExpertises = async ():Promise<ExpertiseType[]> => {
  const r:any = await client.query({
    query: GET_EXPERTISES,
  });
  return _.get(r, 'data.expertise_getAll', []);
};
