import { gql } from '@apollo/client';

export const DELETE_EXPERT_SEARCH_RESULT = gql`
  mutation DeleteExpertSearchResult($expertSearchResultId: ID!) {
    expertSearchResult_delete (expertSearchResultId: $expertSearchResultId)
  }
`;

export const ADD_MANUALLY_EXPERT_SEARCH_RESULT = gql`
  mutation AddManuallyExpertSearchResult($idealExpertIds: [ID]!, $expertSearchId: ID!) {
    expertSearchResult_addManually (idealExpertIds: $idealExpertIds, expertSearchId: $expertSearchId) {
      experts {
        _id
        accomplishments
        bio
        bio_url
        education_or_background
        expertise_ids
        expertises
        expert_type
        expert_type_id
        firstName
        institutions_or_positions_or_affiliations
        is_keystone_expert
        lastName
        location
        overview_or_notes
        projects
        project_ids
        rate
        relationship_manager_eta
        relationship_manager_eta_id
        relationship_manager_strategy
        relationship_manager_strategy_id
        relationship_manager_support
        relationship_manager_support_id
        subject_matter_tags
        patents
        papers
      }
      results {
        _id
        searchId
        expertId
        order
      }
      search {
        _id
        projectId
        expertiseIds
        idealExpertIds
        description
        position
        name
      }
    }
  }
`;
