import React, { useEffect, useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import {
  StyledContainer,
  StyledContentTitle,
  StyledInnerContainer,
  StyledDownloadIcon,
  StyledLoading,
  StyledLoadingTitle,
  StyledShareIcon,
  StyledTooltipContainer,
  StyledTooltipSpan,
  StyledTooltipIcon,
  StyledDownloadLink,
  StyledHeaderContainer,
  StyledButton,
} from './style';

import TrackedCasesList from './trackedCasesList';
import * as dateFunctions from '../../../../../utils/dateFunctions';
import * as filingDisputeValueService from '../../../../../entities/filingDisputeValue/service';
import * as secCompanyFeedsUtils from '../../../../../entities/secCompanyFeed/utils';
import * as stringFunctions from '../../../../../utils/stringFunctions';

interface IProps {
  isOpen: boolean;
  companyFeeds: SecCompanyFeedType[];
  filingStatuses: FilingStatusType[];
  isLoading: boolean;
  selectedTrackedCase: FilingStatusType | null;
  setSelectedTrackedCase: (filingStatus: FilingStatusType | null) => void;
}

const TrackedCases: React.FC<IProps> = (props: IProps) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filingsList = secCompanyFeedsUtils.getFilingsFromSecCompanyFeeds(props.companyFeeds);

  const getTrackedFilingStatuses = () => {
    return props.filingStatuses.filter((item: FilingStatusType) => item.status === 'TRACKED');
  };

  const compare = (secFilingA: FilingStatusType, secFilingB: FilingStatusType) => {
    const filingA = props.companyFeeds.find((item: SecCompanyFeedType) => item.id === secFilingA.companyId);
    const filingB = props.companyFeeds.find((item: SecCompanyFeedType) => item.id === secFilingB.companyId);

    if (!filingA || !filingB) return 0;

    if (filingA?.company_display_name.toLowerCase() < filingB?.company_display_name.toLocaleLowerCase()) return -1;

    if (filingA?.company_display_name.toLowerCase() > filingB?.company_display_name.toLocaleLowerCase()) return 1;

    return 0;
  };

  const getSortedTrackedFilingStatuses = () => {
    return [...getTrackedFilingStatuses()].sort(compare);
  };

  const handleClickOutside = (e: any) => {
    if (!!wrapperRef && !!wrapperRef.current && wrapperRef.current.contains(e.target)) {
      props.setSelectedTrackedCase(null);
    }
  };

  const handleShareButtonClick = () => {
    updateClipboard('http://grid.keystonestrategy.io/prospector?source=sec-filings&type=transferpricing');
  };

  const updateClipboard = async (textToCopy: string) => {
    await navigator.clipboard.writeText(textToCopy);
    setIsLinkCopied(true);
  };

  const getShareTooltipTitle = () => {
    if (!isLinkCopied) return 'Copy link to share';

    return (
      <StyledTooltipContainer>
        <StyledTooltipIcon />
        <StyledTooltipSpan>Link successfully copied to your clipboard</StyledTooltipSpan>
      </StyledTooltipContainer>
    );
  };

  const getDisputeValues = async () => {
    const filingsToQuery = getTrackedFilingStatuses().map((filingStatus: FilingStatusType) => {
      const filingObject = filingsList.find((item: DisplayFilingType) => {
        return (item.companyId === filingStatus.companyId) && (item.filingId === filingStatus.filingId);
      });
      return {
        companyId: filingObject?.companyId!,
        filingId: filingObject?.filingId!,
      };
    });

    return filingDisputeValueService.fetchFilingDisputeValuesByFilings(filingsToQuery);
  };

  const createCSVContent = async () => {
    const disputeValues = await getDisputeValues();

    const csvContent = getTrackedFilingStatuses().reduce((acc: string, filingStatus: FilingStatusType) => {
      const filingObject = filingsList.find((item: DisplayFilingType) => {
        return (item.companyId === filingStatus.companyId) && (item.filingId === filingStatus.filingId);
      });

      const disputeValueObject = disputeValues.find((item: FilingDisputeValueType | null) => {
        return (!!item && item.companyId === filingObject?.companyId && item.filingId === filingObject.filingId);
      });

      const disputeValue = disputeValueObject ? disputeValueObject.value.toString() : 'null';

      const companyName = stringFunctions.escapeCommas(filingObject?.company_display_name);
      const tickers = stringFunctions.escapeCommas(filingObject?.tickers.join(' '));
      const filing = stringFunctions.escapeCommas(filingObject?.filingForm);
      const filingUrl = stringFunctions.escapeSpecialChars(stringFunctions.escapeCommas(filingObject?.filingUrl));
      const latestFiled = dateFunctions.getFormattedDateStringMDY(filingObject?.latest_filed);
      const matchingKeywords = stringFunctions.escapeCommas(filingObject?.filingMatchingKeywords);
      // eslint-disable-next-line
      return `${acc}${companyName},${tickers},${filing},${filingUrl},${latestFiled},${matchingKeywords},${disputeValue}\n`;
    }, 'Company name,Tickers,Filing,Filing URL,Latest Filed,Keywords,Dispute Value\n');

    return csvContent;
  };

  const handleDownloadButton = async () => {
    if (!linkRef.current) return;

    const csvContent = await createCSVContent();

    linkRef.current.href = `data:text/csv;charset=utf-8,${encodeURI(csvContent)}`;

    const formattedDate = dateFunctions.getFormattedDateStringYMD(new Date());

    linkRef.current.download = `grid_prospector_sec_filings_transfer_pricing_${formattedDate}.csv`;

    linkRef.current.click();
  };

  return (
    <StyledContainer $isOpen={props.isOpen} ref={wrapperRef}>
      <StyledHeaderContainer>
        <StyledContentTitle>Tracked Cases</StyledContentTitle>
        <Tooltip title={getShareTooltipTitle()}>
          <StyledButton onClick={handleShareButtonClick} onMouseLeave={() => setIsLinkCopied(false)}>
            <StyledShareIcon />
          </StyledButton>
        </Tooltip>
        <StyledDownloadLink ref={linkRef} download>
          x
        </StyledDownloadLink>
        <Tooltip title="Download Tracked Cases">
          <StyledButton onClick={handleDownloadButton}>
            <StyledDownloadIcon />
          </StyledButton>
        </Tooltip>
      </StyledHeaderContainer>
      <StyledInnerContainer>
        {
          props.isLoading
            ? (
              <StyledLoading>
                <StyledLoadingTitle>Loading results..</StyledLoadingTitle>
              </StyledLoading>
            )
            : (
              <TrackedCasesList
                filings={filingsList}
                sortedFilingStatuses={getSortedTrackedFilingStatuses()}
                setSelectedTrackedCase={props.setSelectedTrackedCase}
                selectedTrackedCase={props.selectedTrackedCase}
              />
            )
        }
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default TrackedCases;
