import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;      
  background-color: #E5E5E5; 
`;

export const StyledContentContainer = styled.div`

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const StyledCenter = styled.div`
  
  width: 600px; 
  height: 300px; 
  
`;

export const StyledTitle = styled.h1`
  padding: 20px 0;
  margin: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  color: #9F9F9F;
  font-size: 30px;
  font-weight: bold;
  font-family: Cairo;  
`;

export const StyledButtonsContainer = styled.div`

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledButton = styled(Button)`

  && {
    background-color: #8DC63F;
    width: 239px;
    height: 70px;
    color: white;    
    text-transform: none;
    padding: 5px 20px; 
    border-radius: 0;
    font-family: Cairo;
    font-weight: bold;
    font-size: 20px;
    
  }
`;

export const StyledExpertIcon = styled(PersonIcon)`

  && {
    color: white;
    margin-right: 10px;
  }

`;

export const StyledProspectIcon = styled(PermContactCalendarIcon)`

&& {
    color: white;
    margin-right: 10px;
  }
  
`;
