import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  StyledContainer,
  StyledTitle,
  StyledSearchContainer,
  StyledSelectionContainer,
  StyledSelectionTitle,
  StyledSelectionDetail,
  StyledArrowIcon,
  StyledDisabledContainer,
  StyledDisabledTitle,
  StyledDisabledDetail,
  StyledTopContainer,
  StyledFooterContainer,
  StyledFooterTitle,
  StyledFooterLink,
} from './style';

import { FilingContext, FilingContextType } from '../../../../context/filingContext';
import { ProspectorContext, ProspectorContextType, EnumProspectOption } from '../../../../context/prospector';

interface IProps {
  isOpen: boolean;
  setSelectedCourtCaseId: (id: string | null) => void;
}

const ProspectContent: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();

  const filingState = useContext<FilingContextType>(FilingContext);
  const prospectorState = useContext<ProspectorContextType>(ProspectorContext);

  useEffect(() => {
    props.setSelectedCourtCaseId(null);
  }, []);

  const handleContainerClick = () => {
    if (filingState.selectedTrackedCase) filingState.setSelectedTrackedCase(null);
  };

  const handleSelectedOptionChange = (option: EnumProspectOption) => {
    props.setSelectedCourtCaseId(null);

    if (option === EnumProspectOption.TRANSFER_PRICING) {
      prospectorState.setSelectedOption(EnumProspectOption.TRANSFER_PRICING);
      history.push('/prospector?source=sec-filings&type=transferpricing');
      return;
    }

    if (option === EnumProspectOption.PATENTS) {
      prospectorState.setSelectedOption(EnumProspectOption.PATENTS);
      history.push('/prospector?source=court-cases&type=patent');
    }
  };

  return (
    <StyledContainer $isOpen={props.isOpen} onClick={handleContainerClick}>
      <StyledTitle>Find a Prospect</StyledTitle>
      <StyledSearchContainer>
        <StyledTopContainer>
          <StyledSelectionContainer
            selected={prospectorState.selectedOption === EnumProspectOption.TRANSFER_PRICING}
            onClick={() => handleSelectedOptionChange(EnumProspectOption.TRANSFER_PRICING)}
          >
            <StyledSelectionTitle>Transfer Pricing</StyledSelectionTitle>
            <StyledSelectionDetail>SEC</StyledSelectionDetail>
            <StyledArrowIcon $selected={prospectorState.selectedOption === EnumProspectOption.TRANSFER_PRICING} />
          </StyledSelectionContainer>
          <StyledSelectionContainer
            selected={prospectorState.selectedOption === EnumProspectOption.PATENTS}
            onClick={() => handleSelectedOptionChange(EnumProspectOption.PATENTS)}
          >
            <StyledSelectionTitle>Patents</StyledSelectionTitle>
            <StyledSelectionDetail>Court Cases</StyledSelectionDetail>
            <StyledArrowIcon $selected={prospectorState.selectedOption === EnumProspectOption.PATENTS} />
          </StyledSelectionContainer>
          <StyledDisabledContainer>
            <StyledDisabledTitle>Other IP</StyledDisabledTitle>
            <StyledDisabledDetail>Court Cases (PACER)</StyledDisabledDetail>
          </StyledDisabledContainer>
          <StyledDisabledContainer>
            <StyledDisabledTitle>Class Action</StyledDisabledTitle>
            <StyledDisabledDetail>Court Cases (PACER)</StyledDisabledDetail>
          </StyledDisabledContainer>
        </StyledTopContainer>
        <StyledFooterContainer>
          <StyledFooterTitle>
            Contact
            <StyledFooterLink href="mailto:getdata@keystonestrategy.com">getdata@keystonestrategy.com</StyledFooterLink>
            to add new feeds here
          </StyledFooterTitle>
        </StyledFooterContainer>
      </StyledSearchContainer>
    </StyledContainer>
  );
};

export default ProspectContent;
