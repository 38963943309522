import { gql } from '@apollo/client';

export const GET_MY_FAVORITE_EXPERTS = gql`
  query GetMyFavoriteExperts {
    expertFavorite_getMine {
      favorites {
        _id
        expertId
        personId
      }
      experts {
        _id
        accomplishments
        bio
        bio_url
        education_or_background
        expertise_ids
        expertises
        expert_type
        expert_type_id
        firstName
        institutions_or_positions_or_affiliations
        is_keystone_expert
        lastName
        location
        overview_or_notes
        projects
        project_ids
        rate
        relationship_manager_eta
        relationship_manager_eta_id
        relationship_manager_strategy
        relationship_manager_strategy_id
        relationship_manager_support_id
        relationship_manager_support_id
        subject_matter_tags
        patents
        papers
      }      
    }
  }
`;
