import { gql } from '@apollo/client';

export const EXECUTE_EXPERT_SEARCH = gql`
mutation UpdateExpertSearch($input: ExpertSearchUpdateInput!) {
  expertSearch_execute (input: $input) {
    experts {
      _id
      accomplishments
      bio
      bio_url
      education_or_background
      expertise_ids
      expertises
      expert_type
      expert_type_id
      firstName
      institutions_or_positions_or_affiliations
      is_keystone_expert
      lastName
      location
      overview_or_notes
      projects
      project_ids
      rate
      relationship_manager_eta
      relationship_manager_eta_id
      relationship_manager_strategy
      relationship_manager_strategy_id
      relationship_manager_support_id
      relationship_manager_support_id
      subject_matter_tags
      patents
      papers
    }
    results {
      _id
      searchId
      expertId
      order
    }
    search {
      _id
      projectId
      expertiseIds
      idealExpertIds
      description
      position
      name
    }
  }
}
`;

export const UPDATE_EXPERT_SEARCH = gql`
  mutation UpdateExpertSearch($input: ExpertSearchUpdateInput!) {
    expertSearch_update (input: $input) {
      search {
        _id
        projectId
        expertiseIds
        idealExpertIds
        description
        position
        name
      }
    }
  }
`;

export const DELETE_EXPERT_SEARCH = gql`
  mutation DeleteExpertSearch($searchId: ID!) {
    expertSearch_delete (searchId: $searchId)
  }
`;

export const CREATE_ONE_EXPERT_SEARCH = gql`
  mutation CreateOneExpertSearch($projectId: String!) {
    expertSearch_createOne (projectId: $projectId) {      
      search {
        _id
        projectId
        expertiseIds
        idealExpertIds
        description
        position
        name
      }
    }
  }
`;
