import React, {
  useState, useEffect, useContext,
} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {
  StyledContainer,
  StyledExpertPositionLabel,
  StyledListContainer,
  StyledExpertPositionContainer,
  StyledAddCircleIcon,
} from './style';

import SearchCard from './searchCard';
import { fetchExpertSearchResultsBySearch } from '../../../../../../entities/expertSearchResult/service';

import { ExpertContext, ExpertContextType } from '../../../../../../context/expertContext';

import Alert from '../../../../../../components/alert';

interface IProps {
  setEditedExpertSearch: (expertSearch: ExpertSearchType | null) => void;
  selectedProjectSearches: ExpertSearchType[];
  selectedExpertSearchId: string | null;
  setSelectedExpertSearchId: (id: string | null) => void;
  expertises: ExpertiseType[];
  setSelectedExpertSearchResults: (expertSearchResults: ExpertSearchResultType[]) => void;
  expertsShortlisted: ExpertShortlistedType[]
  setExpertsShortlisted: (expertsShortlisted: ExpertShortlistedType[]) => void
  setSelectedExpertSearches: (searchList: ExpertSearchType[]) => void;
  handleNewExpertSearchPosition: () => void;
}

const ExpertSearchPosition: React.FC<IProps> = (props: IProps) => {
  const [error, setError] = useState<Error | null>(null);
  const expertState = useContext<ExpertContextType>(ExpertContext);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  }, [error]);

  const handleCardClick = async (searchId: string) => {
    try {
      props.setSelectedExpertSearchId(searchId);

      const searchResults = await fetchExpertSearchResultsBySearch(searchId);

      expertState.setExperts(searchResults.experts);

      props.setSelectedExpertSearchResults(searchResults.results);

      props.setEditedExpertSearch(null);
    } catch (err) {
      setError(err);
    }
  };

  const handleEditIconClick = (searchId: string) => {
    props.setSelectedExpertSearchId(searchId);
    const search = props.selectedProjectSearches.find((s) => s._id === searchId);
    if (search) props.setEditedExpertSearch(search);
  };

  const displayCards = () => {
    return props.selectedProjectSearches.map((expertSearch: ExpertSearchType, idx: number) => {
      const typeName = expertSearch.name === '' ? `Search ${idx + 1}` : expertSearch.name;

      const expertiseList = expertSearch.expertiseIds.map((expertiseId: string) => {
        return props.expertises.find((item: ExpertiseType) => item._id === expertiseId) as ExpertiseType;
      });

      return (
        <SearchCard
          key={expertSearch._id}
          selectedExpertSearchId={props.selectedExpertSearchId}
          searchId={expertSearch._id}
          selectedProjectSearches={props.selectedProjectSearches}
          typeName={typeName}
          expertiseList={expertiseList}
          handleCardClick={() => handleCardClick(expertSearch._id)}
          handleEditIconClick={() => handleEditIconClick(expertSearch._id)}
          expertsShortlisted={props.expertsShortlisted}
          setExpertsShortlisted={props.setExpertsShortlisted}
          setSelectedExpertSearches={props.setSelectedExpertSearches}
          setSelectedExpertSearchId={props.setSelectedExpertSearchId}
        />
      );
    });
  };

  return (
    <StyledContainer>
      <StyledExpertPositionContainer>
        <StyledExpertPositionLabel>
          Expert Search Positions
          {' '}
          {`(${props.selectedProjectSearches.length})`}
        </StyledExpertPositionLabel>
        <IconButton>
          <Tooltip title="new search">
            <StyledAddCircleIcon
              onClick={props.handleNewExpertSearchPosition}
              data-testid="add-new-search-selector-button"
            />
          </Tooltip>
        </IconButton>
      </StyledExpertPositionContainer>
      <StyledListContainer
        data-testid="expert-search-card-container"
      >
        {displayCards()}
      </StyledListContainer>
      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ transform: 'translateY(70px)', zIndex: 9999999999 }}
      >
        <Alert severity="error">{error ? error.message : ''}</Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default ExpertSearchPosition;
