import React from 'react';

import {
  StyledFlexSection,
  StyledSectionCol,
  StyledSectionTitle,
  inputStyles,
} from '../style';

import CustomInput from '../../../../../../components/customInput';

interface IProps {
  courtCase: CourtCaseType | null;
}

const SectionDates: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledFlexSection>
      <StyledSectionCol width="31%">
        <StyledSectionTitle>Date Argued</StyledSectionTitle>
        <CustomInput
          defaultValue={props.courtCase?.dateArgued?.substring(0, 10) || ''}
          disabled
          styles={inputStyles}
        />
      </StyledSectionCol>
      <StyledSectionCol width="31%">
        <StyledSectionTitle>Date Filed</StyledSectionTitle>
        <CustomInput
          defaultValue={props.courtCase?.dateFiled?.substring(0, 10) || ''}
          disabled
          styles={inputStyles}
        />
      </StyledSectionCol>
      <StyledSectionCol width="31%">
        <StyledSectionTitle>Date Terminated</StyledSectionTitle>
        <CustomInput
          defaultValue={props.courtCase?.dateTerminated?.substring(0, 10) || ''}
          disabled
          styles={inputStyles}
        />
      </StyledSectionCol>
    </StyledFlexSection>
  );
};

export default SectionDates;
