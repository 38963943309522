import styled from 'styled-components';

export interface INameStyleProps {
  cursor?: string;
}
export interface IItemStyleProps {
  border? : string;
  height?: string;
}

interface StyledContainerProps {
  itemStyles?: IItemStyleProps;
  isDisabled?: boolean;
}

const getBackgroundColor = (isDisabled?: boolean) => {
  if (isDisabled) return 'lightgray';
  return 'white';
};

export const StyledContainer = styled.li<StyledContainerProps>`
  width: auto;
  background-color:${(props) => getBackgroundColor(props.isDisabled)};
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  border: ${(props) => props?.itemStyles?.border ?? 'initial'};
  position: relative;
  align-items: center;
  height: ${(props) => props.itemStyles?.height ?? 'auto'};

  &:hover {
    cursor: ${(props) => props.isDisabled ? 'inherit' : 'pointer'};
  }
`;

export const StyledLeftSideContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-basis: 300px;
`;

export const StyledArea2Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  `;

export const StyledKeystoneIcon = styled.img`
  
`;

export const StyledName = styled.h4`
  margin: 0;
  color: #000;
  font-size: 15px;
  margin-left: 30px;
  font-weight: 600;
  font-family: Cairo;
  padding: 10px 10px;
  position: relative;
  &::selection {
    background-color: #668793;
    color: white;
  }

  &:hover {
    cursor: initial;
  }
`;

export const StyledTagContainer = styled.div`
  display: flex;
  height: inherit;
  width: 100%;
  align-content: center;
  gap: 12px;
`;

interface IColor {
  backgroundColor?: string;
}

export const StyledTag = styled.div<IColor>`
  border: 1px solid ${(props) => props?.backgroundColor ?? '#304f5a'};
  padding: 2px 5px;
  background-color: ${(props) => props?.backgroundColor ?? '#304f5a'};
  color: white;
  border-radius: 5%;
  max-width: 70px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
