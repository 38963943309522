import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
  StyledContentTitle,
  StyledDownloadListButton,
  StyledDownloadIcon,
  StyledButtonContainer,
  StyledDownloadLink,
  StyledHeaderContainer,
} from './style';

interface IProps {
  linkRef?: React.RefObject<HTMLAnchorElement>;
  handleDownloadButton?: () => void;
  title: string;
  length: number
}

const HeaderExperts = (props: IProps) => {
  return (
    <StyledHeaderContainer>
      <StyledContentTitle>{props.title}</StyledContentTitle>
      {
        props.length > 0
  && (
    <Tooltip title="Download List">
      <StyledButtonContainer>
        <StyledDownloadLink ref={props?.linkRef} download>
          x
        </StyledDownloadLink>
        <StyledDownloadListButton onClick={props?.handleDownloadButton}>
          <StyledDownloadIcon />
        </StyledDownloadListButton>
      </StyledButtonContainer>
    </Tooltip>
  )
}
    </StyledHeaderContainer>
  );
};

export default HeaderExperts;
