import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import * as expertSearchService from '../../../../../../../entities/expertSearch/service';
import {
  StyledContainer,
  StyledTypeTitle,
  StyledTagsContainer,
  StyledTag,
  RightClickMessage,
  DeleteConfirmDlg,
  PositionName,
  Question,
  BtnWrap,
  StyledIconButton,
  StyledCircle,
  StyledDivider,
  StyledLabelInCircle,
} from './style';

interface IProps {
  selectedExpertSearchId: string | null;
  searchId: string;
  selectedProjectSearches: ExpertSearchType[];
  typeName: string;
  expertiseList: ExpertiseType[];
  handleCardClick: () => void;
  handleEditIconClick: () => void;
  expertsShortlisted: ExpertShortlistedType[];
  setExpertsShortlisted: (expertsShortlisted: ExpertShortlistedType[]) => void;
  setSelectedExpertSearches: (searchList: ExpertSearchType[]) => void;
  setSelectedExpertSearchId: (id: string | null) => void;
}

const SearchCard: React.FC<IProps> = (props: IProps) => {
  const client = useApolloClient();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [isDeleteDlgOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isSelected = props.selectedExpertSearchId === props.searchId;

  const displayTags = () => {
    const thresholdOfWords = 4; // Threshold of expertiseTypes allowed
    const remainingExpertiseTypes = props.expertiseList.length - thresholdOfWords;
    return (
      <>
        {props.expertiseList.map((expertiseObject: ExpertiseType, idx: number) => {
          const isLastExpertiseType = props.expertiseList.length - 1 === idx;
          const isNextBelowThreshold = (idx + 1) < thresholdOfWords;
          const hasNext = isNextBelowThreshold && !isLastExpertiseType;
          return (
            <React.Fragment key={expertiseObject?._id ?? idx}>
              {idx < thresholdOfWords && (
                <>
                  <StyledTag selected={isSelected}>
                    {expertiseObject?.name ?? ''}
                  </StyledTag>
                  {hasNext && <StyledDivider orientation="vertical" color={isSelected ? 'primary' : 'secondary'} />}
                </>
              )}
            </React.Fragment>
          );
        })}
        {remainingExpertiseTypes > 0 && (
          <StyledCircle selected={isSelected}>
            <StyledLabelInCircle>{`+ ${remainingExpertiseTypes}`}</StyledLabelInCircle>
          </StyledCircle>
        )}
      </>
    );
  };

  const handleTooltipClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setIsTooltipOpen(true);
  };

  const handleOpenDialog = () => {
    setIsDeleteDialogOpen(true);
    setIsTooltipOpen(false);
  };

  const handleExit = () => {
    setAnchorEl(null);
    setIsTooltipOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const deleteExpertSearch = async () => {
    await expertSearchService.deleteExpertSearch(client, props.searchId);
    const newProjectSearches = props.selectedProjectSearches.filter((s) => s._id !== props.searchId) || [];
    props.setSelectedExpertSearches(newProjectSearches);

    const newExpertsShortlisted = props.expertsShortlisted.filter((e) => e.searchId !== props.searchId);
    props.setExpertsShortlisted(newExpertsShortlisted);
    if (props.selectedExpertSearchId === props.searchId) props.setSelectedExpertSearchId(null);

    handleExit();
  };

  return (
    <StyledContainer
      selected={isSelected}
      onContextMenu={(event) => handleTooltipClick(event)}
      onClick={props.handleCardClick}
    >
      <StyledTypeTitle>{props.typeName}</StyledTypeTitle>
      <StyledIconButton
        color={isSelected ? 'primary' : 'secondary'}
        onClick={(event) => {
          event.stopPropagation();
          props.handleEditIconClick();
        }}
      >
        <Tooltip title="edit">
          <EditIcon />
        </Tooltip>
      </StyledIconButton>
      <StyledTagsContainer>
        {displayTags()}
      </StyledTagsContainer>
      {isTooltipOpen && (
      <RightClickMessage
        id={props.searchId}
        open={isTooltipOpen}
        anchorEl={anchorEl}
        onClose={handleExit}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <div role="button" onClick={handleOpenDialog} onKeyDown={handleOpenDialog} tabIndex={0}>
          <DeleteForeverIcon fontSize="small" />
          <span>Remove Expert Position</span>
        </div>
      </RightClickMessage>
      )}
      {isDeleteDlgOpen && (
      <DeleteConfirmDlg
        open={isDeleteDlgOpen}
        anchorEl={anchorEl}
        onClose={handleExit}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Question>
          Are you sure you want to delete this expert search position ?
        </Question>
        <PositionName>{props.typeName}</PositionName>
        <BtnWrap>
          <span
            role="button"
            tabIndex={0}
            onClick={handleExit}
            onKeyDown={handleExit}
          >
            Cancel
          </span>
          <span
            role="button"
            tabIndex={0}
            id="confirm"
            onClick={() => deleteExpertSearch()}
            onKeyDown={() => deleteExpertSearch()}
          >
            Confirm
          </span>
        </BtnWrap>
      </DeleteConfirmDlg>
      )}
    </StyledContainer>
  );
};

export default SearchCard;
