import { GET_ALL_EXPERT_SEARCH, GET_ALL_EXPERT_SEARCH_BY_PROJECT } from './query';
import {
  UPDATE_EXPERT_SEARCH,
  DELETE_EXPERT_SEARCH,
  CREATE_ONE_EXPERT_SEARCH,
  EXECUTE_EXPERT_SEARCH,
} from './mutation';
import { cleanExpertSearchResults } from '../../expertSearchResult/utils';
import { cleanExpertSearches } from '../utils';

export const fetchExpertSearches = async (client: any, projectId: string | null) => {
  try {
    const result = await client.query({
      query: GET_ALL_EXPERT_SEARCH_BY_PROJECT,
      variables: {
        projectId,
      },
      fetchPolicy: 'network-only',
    });

    return result.data.expertSearch_getAllByProject;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchAllExpertSearches = async (client: any) => {
  try {
    const result = await client.query({
      query: GET_ALL_EXPERT_SEARCH,
      fetchPolicy: 'network-only',
    });

    return result.data.expertSearch_getAll;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const createOneExpertSearch = async (
  client: any,
  projectId: string,
): Promise<ExpertSearchCreateOneMutationResultType> => {
  const response = await client.mutate({
    mutation: CREATE_ONE_EXPERT_SEARCH,
    variables: {
      projectId,
    },
  });
  const {
    search,
  } = response.data.expertSearch_createOne;

  return {
    search: cleanExpertSearches([search])[0],
  };
};

export const executeExpertSearch = async (client: any, input: {
  _id: string;
  projectId: string;
  expertiseIds: string[];
  idealExpertIds: string[];
  description: string;
  position: 'CONSULTING' | 'TESTIFYING';
  name: string;
}): Promise<ExpertSearchExecuteMutationResultType> => {
  const response = await client.mutate({
    mutation: EXECUTE_EXPERT_SEARCH,
    variables: {
      input,
    },
  });

  const {
    experts,
    results,
    search,
  } = response.data.expertSearch_execute;

  return {
    experts,
    results: cleanExpertSearchResults(results),
    search: cleanExpertSearches([search])[0],
  };
};

export const updateExpertSearch = async (client: any, input: {
  _id: string;
  projectId: string;
  expertiseIds: string[];
  idealExpertIds: string[];
  description: string;
  position: 'CONSULTING' | 'TESTIFYING';
  name: string;
}): Promise<ExpertSearchUpdateMutationResultType> => {
  const response = await client.mutate({
    mutation: UPDATE_EXPERT_SEARCH,
    variables: {
      input,
    },
  });

  const {
    search,
  } = response.data.expertSearch_update;
  return {
    search: cleanExpertSearches([search])[0],
  };
};

export const deleteExpertSearch = async (client: any, searchId: string) => {
  const response = await client.mutate({
    mutation: DELETE_EXPERT_SEARCH,
    variables: {
      searchId,
    },
  });

  return response.data.expertSearch_delete as boolean;
};
