import React from 'react';
import CustomSearch from '../../../../components/customSearch';

import {
  StyledContainer,
  StyledLeftPanelContainer,
  StyledRightPanelContainer,
} from './style';

import DaysSelect from './daysSelect';
import StatusSelect from './statusSelect';
import KeywordsSelect from './keywordsSelect';
import FilingTypeSelect from './filingTypeSelect';

enum EnumPeriodOption { LAST24H, LAST7D, LAST14D, LAST30D, LAST90D }

enum EnumStatusOption { TRACKED, REJECTED, NONE, ALL }

enum EnumFilingTypeOption { PRIMARY, EXHIBIT, ALL }

interface IProps {
  areBothSidebarsOpen: boolean;
  filterByCompanyName: string;
  setFilterByCompanyName: React.Dispatch<React.SetStateAction<string>>;
  selectedPeriod: EnumPeriodOption;
  onChangeSelectedPeriod: (newSelectedPeriod: EnumPeriodOption) => Promise<void>;
  selectedStatus: EnumStatusOption;
  setSelectedStatus: React.Dispatch<React.SetStateAction<EnumStatusOption>>;
  selectedKeywords: string[];
  setSelectedKeywords: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFilingType: EnumFilingTypeOption;
  setSelectedFilingType: React.Dispatch<React.SetStateAction<EnumFilingTypeOption>>
  selectedTrackedCase: FilingStatusType | null;
  secCompanyFeeds: SecCompanyFeedType[];
  filingStatuses: FilingStatusType[];
  keywordsList: string[];
}

const SearchBar: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer areBothSidebarsOpen={props.areBothSidebarsOpen}>
      <StyledLeftPanelContainer areBothSidebarsOpen={props.areBothSidebarsOpen}>
        <CustomSearch
          value={props.filterByCompanyName}
          handleChange={(value: string) => props.setFilterByCompanyName(value)}
          handleClear={() => props.setFilterByCompanyName('')}
          styles={{
            margin: '0 10px 0 0',
          }}
          placeholder="Search"
          data-testid="prospects-secFilings-searchBar-globalSearchInput"
        />
      </StyledLeftPanelContainer>
      <StyledRightPanelContainer areBothSidebarsOpen={props.areBothSidebarsOpen}>
        <FilingTypeSelect
          selectedValue={props.selectedFilingType}
          handleChange={(value: EnumFilingTypeOption) => props.setSelectedFilingType(value)}
          disabled={!!props.selectedTrackedCase}
          secCompanyFeeds={props.secCompanyFeeds}
        />
        <DaysSelect
          selectedValue={props.selectedPeriod}
          handleChange={(value: EnumPeriodOption) => props.onChangeSelectedPeriod(value)}
          disabled={!!props.selectedTrackedCase}
        />
        <StatusSelect
          selectedValue={props.selectedStatus}
          handleChange={(value: EnumStatusOption) => props.setSelectedStatus(value)}
          disabled={!!props.selectedTrackedCase}
          secCompanyFeeds={props.secCompanyFeeds}
          filingStatuses={props.filingStatuses}
        />
        <KeywordsSelect
          selectedValues={props.selectedKeywords}
          handleChange={(value: string[]) => props.setSelectedKeywords(value)}
          disabled={!!props.selectedTrackedCase}
          keywordsList={props.keywordsList}
        />
      </StyledRightPanelContainer>
    </StyledContainer>
  );
};

export default SearchBar;
