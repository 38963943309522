import { gql } from '@apollo/client';

export const GET_ALL_TRACKED_FILING_STATUSES = gql`
  query GetAllTrackedFilingStatuses {
    filingStatus_getAllTracked {
      companyFeeds {
        id
        company_display_name
        tickers
        filings {
          id
          form
          url
          file_date
          matching_keywords
          display_matching_keywords
        }
        latest_filed
        source_display_name
      }
      statuses {
        companyId
        filingId
        status
      }
    }
  }
`;
