import React, { useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import * as qs from 'querystring';

import {
  StyledContainer,
  StyledGridContainer,
} from './style';

import { FilingContext, FilingContextType } from '../../context/filingContext';

import { CourtCaseContext, CourtCaseContextType } from '../../context/courtCaseContext';
import { ProspectorContext, ProspectorContextType, EnumProspectOption } from '../../context/prospector';

import ProspectHeader from './prospectsHeader';
import SecFilings from './secFilings';
import CourtCases from './courtCases';
import CourtCaseView from './courtCases/courtCaseView';

import { fetchSECCompanyFeedsAfterFiledDate } from '../../entities/secCompanyFeed/service';
import { fetchAllTrackedFilingStatuses } from '../../entities/filingStatus/service';
import { getFormattedDateNDaysBeforeToday } from '../../utils/dateFunctions';
import DataSourceLabel from '../../components/dataSourceLabel';

const Prospects = () => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();

  const filingState = useContext<FilingContextType>(FilingContext);
  const courtCaseState = useContext<CourtCaseContextType>(CourtCaseContext);
  const prospectorState = useContext<ProspectorContextType>(ProspectorContext);

  const fetchSecCompanyFeeds = async (daysBefore: number) => {
    setIsLoading(true);
    filingState.setIsLoadingTrackedFilings(true);

    const initialDate = getFormattedDateNDaysBeforeToday(daysBefore);
    const results = await fetchSECCompanyFeedsAfterFiledDate(initialDate);
    const trackedStatusResults = await fetchAllTrackedFilingStatuses();
    const filteredCompanyFeed = trackedStatusResults
      .companyFeeds.filter((companyFeed: any) => companyFeed.latest_filed >= initialDate);

    const mergedCompanyFeeds = [...results.companyFeeds, ...filteredCompanyFeed];
    const mergedFilingStatuses = [...results.statuses, ...trackedStatusResults.statuses];
    const uniqueCompanyFeeds = _.uniqBy(mergedCompanyFeeds, 'id');

    const uniqueFilingStatuses = _.uniqBy(mergedFilingStatuses, (value: FilingStatusType) => {
      return `${value.companyId}-${value.filingId}`;
    });

    filingState.setCompanyFeeds(uniqueCompanyFeeds);
    filingState.setFilingStatuses(uniqueFilingStatuses);

    filingState.setIsLoadingTrackedFilings(false);

    setIsLoading(false);
  };

  const params = qs.parse(location.search.replace('?', ''));

  useEffect(() => {
    if (params.source === 'sec-filings') {
      prospectorState.setSelectedOption(EnumProspectOption.TRANSFER_PRICING);
      history.push('/prospector?source=sec-filings&type=transferpricing');
      return;
    }
    if (params.source === 'court-cases') {
      prospectorState.setSelectedOption(EnumProspectOption.PATENTS);
      history.push('/prospector?source=court-cases&type=patent');
      return;
    }
    // default case
    prospectorState.setSelectedOption(EnumProspectOption.TRANSFER_PRICING);
    history.push('/prospector?source=sec-filings&type=transferpricing');
  }, []);

  useEffect(() => {
    fetchSecCompanyFeeds(30);
  }, []);

  const handleContainerClick = () => {
    if (filingState.selectedTrackedCase) filingState.setSelectedTrackedCase(null);
  };

  const getCourtCase = (caseId: string) => {
    const result = courtCaseState.courtCases.find((item: CourtCaseType) => item.caseName === caseId);
    if (!result) return null;
    return result;
  };

  const displayProspectsContent = () => {
    if (courtCaseState.selectedCourtCaseId) {
      return (
        <CourtCaseView
          courtCase={getCourtCase(courtCaseState.selectedCourtCaseId)}
        />
      );
    }

    if (prospectorState.selectedOption === EnumProspectOption.PATENTS) {
      return (
        <StyledGridContainer>
          <ProspectHeader selectedProspectorOption={prospectorState.selectedOption} />
          <CourtCases
            courtCases={courtCaseState.courtCases}
            isLoadingCourtCases={courtCaseState.isLoadingCourtCases}
            setSelectedCourtCaseId={courtCaseState.setSelectedCourtCaseId}
          />
          <DataSourceLabel
            labelName="CourtListener"
            link="https://www.courtlistener.com"
          />
        </StyledGridContainer>
      );
    }

    return (
      <StyledGridContainer>
        <ProspectHeader selectedProspectorOption={prospectorState.selectedOption} />
        <SecFilings
          secCompanyFeeds={filingState.companyFeeds}
          filingStatuses={filingState.filingStatuses}
          setFilingStatuses={filingState.setFilingStatuses}
          selectedTrackedCase={filingState.selectedTrackedCase}
          fetchSecCompanyFeeds={fetchSecCompanyFeeds}
          isLoading={isLoading}
        />
        <DataSourceLabel
          labelName=" SEC EDGAR Data"
          link="https://www.sec.gov/edgar/search/"
        />
      </StyledGridContainer>
    );
  };

  return (
    <StyledContainer
      data-testid="prospects"
      onClick={handleContainerClick}
    >
      {displayProspectsContent()}
    </StyledContainer>
  );
};

export default Prospects;
