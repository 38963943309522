import React from 'react';
import styled from 'styled-components';

const StyledMainContainer = styled.div`
  width: 100%;
  height: 100%;  
`;

const StyledContentContainer = styled.div`
  width: 97%;
  margin: 0 auto;
  height: 100%;  
`;

interface IProps {
  'data-testid': string;
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
}

export const StyledContainer: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledMainContainer data-testid={props['data-testid']} onClick={props.onClick}>
      <StyledContentContainer>
        {props.children}
      </StyledContentContainer>
    </StyledMainContainer>
  );
};

export const StyledGridContainer = styled.div`

  width: 100%;
  height: 100%;
  padding: 0;
  display: grid;
  grid-template-areas: "header header" "body body" "footer footer";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.1fr 1.5fr 0.1fr;
  gap: 0 0;
  background-color: #E5E5E5;  

`;
