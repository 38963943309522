import React, { useState, useContext } from 'react';

import {
  StyledSearchContainer,
} from '../style';

import { ProjectContext, ProjectContextType } from '../../../context/projectContext';
import { ExpertContext, ExpertContextType } from '../../../context/expertContext';
import { EditedExpertSearchContext, EditedExpertSearchContextType } from '../../../context/editedExpertSearch';

import ExpertSearchHeader from './expertSearchHeader';
import ExpertSearchResults from './expertSearchResults';

interface IProps {
  expertises: ExpertiseType[];
  isSelectedSearchEditable: boolean;
  $isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  updateSearch: (updateSearch: ExpertSearchType) => void;
  executeSearch: (executeSearch: ExpertSearchType) => void;
  isUpdatingSearch: boolean;
  setIsUpdatingSearch: (isUpdating: boolean) => void;
}

const ExpertSearchContent = (props: IProps) => {
  const [currentDescription, setCurrentDescription] = useState('');

  const projectState = useContext<ProjectContextType>(ProjectContext);
  const expertState = useContext<ExpertContextType>(ExpertContext);
  const editedExpertSearchState = useContext<EditedExpertSearchContextType>(EditedExpertSearchContext);

  const handleExpertiseTagDeletion = (expertiseId: string) => {
    if (!props.isSelectedSearchEditable) return;
    if (!editedExpertSearchState.editedExpertSearch) return;
    const currentExpertiseIds = editedExpertSearchState.editedExpertSearch.expertiseIds;

    const newExpertiseIds = currentExpertiseIds.filter((item: string) => item !== expertiseId);

    editedExpertSearchState.setEditedExpertSearch({
      ...editedExpertSearchState.editedExpertSearch,
      description: currentDescription,
      expertiseIds: newExpertiseIds,
    });
  };

  return (
    <StyledSearchContainer>
      <ExpertSearchHeader
        setSelectedExpertSearchId={projectState.setSelectedExpertSearchId}
        setEditedExpertSearch={editedExpertSearchState.setEditedExpertSearch}
        selectedExpertSearches={projectState.selectedExpertSearches}
        selectedExpertSearchId={projectState.selectedExpertSearchId}
        editedExpertSearch={editedExpertSearchState.editedExpertSearch}
        updateSearch={props.updateSearch}
        expertises={props.expertises}
        isUpdatingSearch={props.isUpdatingSearch}
        isSelectedSearchEditable={props.isSelectedSearchEditable}
        currentDescription={currentDescription}
        setCurrentDescription={setCurrentDescription}
        handleExpertiseTagDeletion={handleExpertiseTagDeletion}
        $isOpen={props.$isOpen}
        setIsOpen={props.setIsOpen}
        executeSearch={props.executeSearch}
        getExpert={expertState.getExpert}
      />
      <ExpertSearchResults
        setSelectedExpertSearches={projectState.setSelectedExpertSearches}
        selectedExpertSearchResults={projectState.selectedExpertSearchResults}
        setSelectedExpertSearchResults={projectState.setSelectedExpertSearchResults}
        isSelectedSearchEditable={props.isSelectedSearchEditable}
        selectedExpertSearchId={projectState.selectedExpertSearchId}
        getExpert={expertState.getExpert}
        setExperts={expertState.setExperts}
        isUpdatingSearch={props.isUpdatingSearch}
        setIsUpdatingSearch={props.setIsUpdatingSearch}
        $isOpen={props.$isOpen}
        selectedExpertSearches={projectState.selectedExpertSearches}
      />
    </StyledSearchContainer>
  );
};

export default ExpertSearchContent;
