import styled from 'styled-components';

export const StyledContainer = styled.div`

  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;


`;

export const StyledCenter = styled.div`

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;

`;

export const StyledTitle = styled.h1`

  color: #304F5A;  
  font-size: 20px;

`;

export const StyledErrorImage = styled.img`

  width: 60px;
  height: 60px;  
  display: flex;
  justify-content: center;
  align-items: center;

`;
