import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';

interface IInputProps {
  disabled?: boolean;
  styles?: {
    padding? : string;
    border? : string;
    borderRadius?: string;
    backgroundColor?: string;
    textAlign?: string;
    textColor?: string;
    fontSize?: number;
    fontWeight?: string;
    focusBorder?: string;
    focusTextColor?: string;
    fontFamily?: string;
    boxShadow?: string;
    focusBackgroundColor?: string;
    disabledBackgroundColor?: string;
    disabledTextColor?: string;
    textAreaHeight?: string;
    selectionBackgroundColor: string;
    selectionTextColor: string;
    lineHeight?: number;
    boxSizing?: string;
  }
}

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;    
`;

export const StyledInput = styled.input<IInputProps>`
  display: block;
  box-sizing: ${(props) => (props.styles && props.styles.boxSizing) ? props.styles.boxSizing : 'border-box'};
  width: 100%;
  margin: 0;
  padding: ${(props) => (props.styles && props.styles.padding) ? props.styles.padding : '5px 10px'};
  
  border: ${(props) => (props.styles && props.styles.border) ? props.styles.border : '1px solid gray'};
  border-radius: ${(props) => (props.styles && props.styles.borderRadius)
    ? props.styles.borderRadius : '0'};   
  font-family: ${(props) => (props.styles && props.styles.fontFamily)
    ? props.styles.fontFamily : 'Cairo, Verdana, Geneva, Tahoma, sans-serif'};
  font-size: ${(props) => (props.styles && props.styles.fontSize) ? props.styles.fontSize : 14}px; 
  color: ${(props) => (props.styles && props.styles.textColor) ? props.styles.textColor : '#707070'};
  background-color: ${(props) => (props.styles && props.styles.backgroundColor)
    ? props.styles.backgroundColor : 'lightgray'};
  text-align: ${(props) => (props.styles && props.styles.textAlign) ? props.styles.textAlign : 'left'};
  transition: background-color .2s linear; 
  font-weight: ${(props) => (props.styles && props.styles.fontWeight)
    ? props.styles.fontWeight : 'normal'};  
  box-shadow: ${(props) => (props.styles && props.styles.boxShadow)
    ? props.styles.boxShadow : '0px 0px 2px lightgray'};
  
  &:focus {
    outline: none;
    background-color: ${(props) => (props.styles && props.styles.focusBackgroundColor)
    ? props.styles.focusBackgroundColor : '#DCDCDC'};
    border: ${(props) => (props.styles && props.styles.focusBorder)
    ? props.styles.focusBorder : 'none'};  
    color: ${(props) => (props.styles && props.styles.focusTextColor)
    ? props.styles.focusTextColor : '#707070'};
  }

  &::selection {
    background-color: ${(props) => (props.styles && props.styles.selectionBackgroundColor)
    ? props.styles.selectionBackgroundColor : '#707070'};
      color: ${(props) => (props.styles && props.styles.selectionTextColor)
    ? props.styles.selectionTextColor : 'white'};    
  }    

  &::placeholder {
    font-weight: ${(props) => (props.styles && props.styles.fontWeight)
    ? props.styles.fontWeight : 'normal'};
  }

  &:disabled {
    background-color: ${(props) => (props.styles && props.styles.disabledBackgroundColor)
    ? props.styles.disabledBackgroundColor : '#DCDCDC'};
    color: ${(props) => (props.styles && props.styles.disabledTextColor) ? props.styles.disabledTextColor : '#707070'};
  }

  &:hover {
    cursor: text;
  }

`;

export const StyledTextArea = styled.textarea<IInputProps>`
  display: block;
  width: 100%;  
  box-sizing: border-box;
  margin: 0;  
  line-height: ${(props) => (props.styles && props.styles.lineHeight) ? props.styles.lineHeight : 22}px;
  padding: ${(props) => (props.styles && props.styles.padding) ? props.styles.padding : '5px 10px'};

  height: ${(props) => (props.styles && props.styles.textAreaHeight) ? props.styles.textAreaHeight : 'auto'};
  
  border: ${(props) => (props.styles && props.styles.border) ? props.styles.border : '1px solid gray'};
  border-radius: ${(props) => (props.styles && props.styles.borderRadius)
    ? props.styles.borderRadius : '0'};   
  font-family: ${(props) => (props.styles && props.styles.fontFamily)
    ? props.styles.fontFamily : 'Cairo, Verdana, Geneva, Tahoma, sans-serif'};
  font-size: ${(props) => (props.styles && props.styles.fontSize) ? props.styles.fontSize : 14}px; 
  color: ${(props) => (props.styles && props.styles.textColor) ? props.styles.textColor : '#707070'};
  background-color: ${(props) => (props.styles && props.styles.backgroundColor)
    ? props.styles.backgroundColor : 'lightgray'};
  text-align: ${(props) => (props.styles && props.styles.textAlign) ? props.styles.textAlign : 'left'};
  transition: background-color .2s linear; 
  font-weight: ${(props) => (props.styles && props.styles.fontWeight)
    ? props.styles.fontWeight : 'normal'};  
  box-shadow: ${(props) => (props.styles && props.styles.boxShadow)
    ? props.styles.boxShadow : '0px 0px 2px lightgray'};
  
  &:focus {
    outline: none;
    background-color: ${(props) => (props.styles && props.styles.focusBackgroundColor)
    ? props.styles.focusBackgroundColor : '#DCDCDC'};
    border: ${(props) => (props.styles && props.styles.focusBorder)
    ? props.styles.focusBorder : 'none'};  
    color: ${(props) => (props.styles && props.styles.focusTextColor)
    ? props.styles.focusTextColor : '#707070'};
  }

  &::selection {
    background-color: ${(props) => (props.styles && props.styles.selectionBackgroundColor)
    ? props.styles.selectionBackgroundColor : '#707070'};
      color: ${(props) => (props.styles && props.styles.selectionTextColor)
    ? props.styles.selectionTextColor : 'white'};    
  }    

  &::placeholder {
    font-weight: ${(props) => (props.styles && props.styles.fontWeight)
    ? props.styles.fontWeight : 'normal'};
    color: #D7D7D7;
  }

  &:disabled {
    background-color: ${(props) => (props.styles && props.styles.disabledBackgroundColor)
    ? props.styles.disabledBackgroundColor : '#DCDCDC'};
    color: ${(props) => (props.styles && props.styles.disabledTextColor) ? props.styles.disabledTextColor : '#707070'};
  }

  &:hover {
    cursor: text;
  }

`;

interface IIconProps {
  iconposright?: number;
  iconpostop?: number;
  iconcolor?: string;
}

export const StyledEditIcon = styled(EditIcon)<IIconProps>`

  && {
    position: absolute;
    right: ${(props) => (props.iconposright)
    ? props.iconposright : '5'}px;
    top: ${(props) => (props.iconpostop)
    ? props.iconpostop : '5'}px;
    color: ${(props) => (props.iconcolor)
    ? props.iconcolor : '#757575'};   
    z-index: 100;
  }

  &:hover {
    cursor: pointer;
  }

`;

export const StyledDateIcon = styled(DateRangeIcon)<IIconProps>`

  && {
    position: absolute;
    right: ${(props) => (props.iconposright)
    ? props.iconposright : '5'}px;
    top: ${(props) => (props.iconpostop)
    ? props.iconpostop : '5'}px;
    color: ${(props) => (props.iconcolor)
    ? props.iconcolor : '#757575'};   
    z-index: 100;
  }

  &:hover {
    cursor: pointer;
  }

`;
