import React from 'react';
import { EnumProspectOption } from '../../../context/prospector';

import {
  StyledContainer,
  StyledHeaderLabel,
  StyledHeaderTitle,
} from './style';

interface IProps {
  selectedProspectorOption: EnumProspectOption;
}

const ProspectsHeader: React.FC<IProps> = (props: IProps) => {
  const displayTitle = () => {
    switch (props.selectedProspectorOption) {
      case EnumProspectOption.TRANSFER_PRICING:

        return 'Recent SEC Filings mentioning Transfer Pricing';

      case EnumProspectOption.PATENTS:

        return 'Court Cases';

      default:
        return 'Recent SEC Filings mentioning Transfer Pricing';
    }
  };
  return (
    <StyledContainer>
      <StyledHeaderLabel>Prospector Report:</StyledHeaderLabel>
      <StyledHeaderTitle>{displayTitle()}</StyledHeaderTitle>
    </StyledContainer>
  );
};

export default ProspectsHeader;
