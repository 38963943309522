import React from 'react';
import List from '@material-ui/core/List';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
  ProjectItem,
  ProjectText,
  ProjectIcon,
  ExpertSearchItem,
} from './style';

interface ExpertSearchCardProps {
  project: ProjectType | undefined;
  onProjectClick: (projectId: string | undefined) => void;
  expertSearches: ExpertSearchType[];
  onExpertSearchClick: (expertSearchId: string) => void;
}

const ExpertSearchCard = ({
  project,
  expertSearches,
  onProjectClick,
  onExpertSearchClick,
}: ExpertSearchCardProps) => {
  const handleSearchClick = async (searchId: string) => {
    onProjectClick(project?._id);
    await onExpertSearchClick(searchId);
  };

  return (
    <List>
      <ProjectItem button onClick={() => onProjectClick(project?._id)}>
        <ProjectText primary={project?.name} disableTypography />
        <ProjectIcon>
          <ChevronRightIcon />
        </ProjectIcon>
      </ProjectItem>
      {expertSearches?.map((expertSearch, index) => (
        <ExpertSearchItem
          button
          key={expertSearch._id}
          onClick={() => handleSearchClick(expertSearch._id)}
        >
          {expertSearch.name || `Search ${index + 1}`}
        </ExpertSearchItem>
      ))}
    </List>
  );
};

export default ExpertSearchCard;
