import React from 'react';

import {
  StyledFlexDocketNumberSection,
  StyledSectionCol,
  StyledSectionTitle,
  inputStyles,
  StyledGoToCaseInCourtButton,
  StyledGoToCaseIcon,
  StyledGotoCaseInCourtLink,
} from '../style';

import CustomInput from '../../../../../../components/customInput';

interface IProps {
  courtCase: CourtCaseType | null;
}

const SectionDocketNumber: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledFlexDocketNumberSection>
      <StyledSectionCol width="30%" paddingRight="0">
        <StyledSectionTitle>Docket Number</StyledSectionTitle>
        <CustomInput
          defaultValue={props.courtCase?.docketNumber || 'N/A'}
          disabled
          styles={inputStyles}
        />
      </StyledSectionCol>
      <StyledGotoCaseInCourtLink href={`https://www.courtlistener.com${props.courtCase?.absolute_url || ''}`} target="_blank">
        <StyledGoToCaseInCourtButton>
          <StyledGoToCaseIcon />
          Go to case in Court Listener
        </StyledGoToCaseInCourtButton>
      </StyledGotoCaseInCourtLink>
    </StyledFlexDocketNumberSection>
  );
};

export default SectionDocketNumber;
