import React from 'react';

import {
  StyledContainer,
  StyledFlexColumn,
  StyledHeaderTitle,
  StyledSortArrow,
} from './style';

import { EnumSortOrder } from '../types';

interface IProps {
  columns: {
    headerProps: {
      label: string;
      width: string;
    };
    cellRenderer: (
      item: any,
      setRowOpen: (value: boolean) => void,
      isRowOpen: boolean,
    ) => JSX.Element;
    compareTo: (a: any, b: any) => void;
  }[];
  handleSort: (labelName: string) => void;
  sortOrder: EnumSortOrder;
  sortedColumnLabel: string | null;
}

const GridHeader: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      {props.columns.map((column: {
        headerProps: {
          label: string;
          width: string;
        };
        cellRenderer: (
          item: any,
          setRowOpen: (value: boolean) => void,
          isRowOpen: boolean,
        ) => JSX.Element;
        compareTo: (a: any, b: any) => void;
      }) => {
        return (
          <StyledFlexColumn
            key={column.headerProps.label}
            width={column.headerProps.width}
            backColor="#ECF1F2"
          >
            <StyledHeaderTitle
              textAlign="center"
              textColor="#304F5A"
              fontSize="12px"
              fontWeight="bold"
              fontFamily="Cairo"
            >
              {column.headerProps.label}
            </StyledHeaderTitle>
            <StyledSortArrow
              onClick={() => props.handleSort(column.headerProps.label)}
              $isSorted={props.sortedColumnLabel === column.headerProps.label}
              $sortOrder={props.sortOrder}
            />
          </StyledFlexColumn>
        );
      })}
    </StyledContainer>
  );
};

export default GridHeader;
