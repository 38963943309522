import React from 'react';
import CustomInput from '../../../../components/customInput';
import { convertStrToCurrency } from '../../../../utils/converter';

import {
  StyledContainerInfo,
  StyledContainer,
  StyledProjectTitle,
  StyledSectionGrid,
  StyledProjectValue,
  inputStyles,
  StyledContainerCustomInput,
  StyledAboutTitle,
  StyledInfoIcon,
  StyledProjectStatusContainer,
} from './style';

interface IProps {
  project: ProjectType | null | undefined;
  valueAbout: string;
  changeInputValue: (value: string) => void;
}

const ProjectBody: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      <StyledContainerInfo>
        <StyledSectionGrid>
          <StyledProjectTitle>Project Type:</StyledProjectTitle>
          <StyledProjectValue>{props.project?.Project_Type__c}</StyledProjectValue>
        </StyledSectionGrid>
        <StyledSectionGrid>
          <StyledProjectTitle>Law Firm:</StyledProjectTitle>
          <StyledProjectValue>{props.project?.Law_Firm_Name ?? 'Law Firm Name'}</StyledProjectValue>
        </StyledSectionGrid>
        <StyledSectionGrid>
          <StyledProjectTitle>End Date:</StyledProjectTitle>
          <StyledProjectValue>{getCloseDate(props.project?.CloseDate)}</StyledProjectValue>
        </StyledSectionGrid>
        <StyledSectionGrid>
          <StyledProjectTitle>Account:</StyledProjectTitle>
          <StyledProjectValue>{props.project?.client ?? 'client'}</StyledProjectValue>
        </StyledSectionGrid>
        <StyledSectionGrid>
          <StyledProjectTitle>Matter Name:</StyledProjectTitle>
          <StyledProjectValue>{props.project?.Matter_Name ?? 'Empty Matter Name'}</StyledProjectValue>
        </StyledSectionGrid>
        <StyledSectionGrid>
          <StyledProjectTitle>Estimated Revenue:</StyledProjectTitle>
          <StyledProjectValue>{convertStrToCurrency(props.project?.Budget__c ?? null)}</StyledProjectValue>
        </StyledSectionGrid>
        <StyledSectionGrid>
          <StyledProjectTitle>Client Contact:</StyledProjectTitle>
          <StyledProjectValue>{props.project?.Client_Sponsor__Name ?? 'Client Sponsor Name'}</StyledProjectValue>
        </StyledSectionGrid>
        <StyledSectionGrid>
          <StyledProjectTitle>Attorney:</StyledProjectTitle>
          <StyledProjectValue>{props.project?.Affiliated_Expert__c ?? 'Affiliated Expert?*'}</StyledProjectValue>
        </StyledSectionGrid>
        <StyledSectionGrid>
          <StyledProjectTitle>Project Status:</StyledProjectTitle>
          <StyledProjectStatusContainer>
            <StyledProjectValue>{props.project?.StageName}</StyledProjectValue>
            <StyledInfoIcon />
          </StyledProjectStatusContainer>
        </StyledSectionGrid>
      </StyledContainerInfo>
      <StyledContainerCustomInput>
        <StyledAboutTitle>About:</StyledAboutTitle>
        <CustomInput
          defaultValue={props.valueAbout}
          value={props.valueAbout}
          styles={{
            ...inputStyles,
            padding: '30px 35px 30px 10px',
          }}
          onChange={props.changeInputValue}
          placeholder="Type a description"
        />
      </StyledContainerCustomInput>
    </StyledContainer>
  );
};

const getCloseDate = (dateValue: string | null | undefined) => {
  if (!dateValue) return null;
  const dateString = dateValue.substr(0, 10);

  const year = dateString.substr(0, 4);

  const month = dateString.substr(5, 2);

  const day = dateString.substr(8, 2);

  return `${month}/${day}/${year}`;
};

export default ProjectBody;
