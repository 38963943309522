export const cleanExpertSearchResults = (expertSearchResults: any[]) => {
  const cleanedResults = expertSearchResults.map((result: any) => {
    const {
      _id: id,
      searchId,
      expertId,
      order,
    } = result;

    return {
      _id: id,
      searchId,
      expertId,
      order,
    };
  });

  return cleanedResults;
};
