import client from '../../../communication/client';
import { GET_ALL_EXPERT_SEARCH_RESULTS_BY_SEARCH } from './query';
import {
  DELETE_EXPERT_SEARCH_RESULT,
  ADD_MANUALLY_EXPERT_SEARCH_RESULT,
} from './mutation';
import { cleanExpertSearchResults } from '../utils';

export const fetchExpertSearchResultsBySearch = async (searchId: string) => {
  const response = await client.query({
    query: GET_ALL_EXPERT_SEARCH_RESULTS_BY_SEARCH,
    variables: {
      searchId,
    },
    fetchPolicy: 'network-only',
  });

  const {
    experts,
    results,
  } = response.data.expertSearchResult_getBySearch;

  return {
    experts,
    results: cleanExpertSearchResults(results),
  };
};

export const deleteExpertSearchResult = async (expertSearchResultId: string) => {
  const response = await client.mutate({
    mutation: DELETE_EXPERT_SEARCH_RESULT,
    variables: {
      expertSearchResultId,
    },
  });

  return response.data.expertSearchResult_delete as boolean;
};

export const addManuallyExpertSearchResult = async (idealExpertIds: string[], expertSearchId: string) => {
  const response = await client.mutate({
    mutation: ADD_MANUALLY_EXPERT_SEARCH_RESULT,
    variables: {
      idealExpertIds,
      expertSearchId,
    },
  });

  return response.data.expertSearchResult_addManually;
};
