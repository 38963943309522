import React from 'react';

import {
  StyledContainer,
  StyledCaseName,
  StyledProjectCode,
  StyledWarning,
  StyledInformationIcon,
} from './style';

interface IProps {
  caseName: string | null;
}

const CourtCaseHeader: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      <StyledCaseName caseName={props.caseName} />
      <StyledProjectCode projectCode={null} />
      <StyledWarning />
      <StyledInformationIcon />
    </StyledContainer>
  );
};

export default CourtCaseHeader;
