import styled from 'styled-components';

export const StyledContainer = styled.div`

  width: 100%;
  margin: 0;
  padding: 0;   
  flex: 1 1 0;  
  display: flex;
  flex-flow: column nowrap;  
  overflow-y: hidden;  
`;

export const StyledList = styled.ul`  
  margin: 0;
  padding: 0;
  width: 100%; 
  flex: 1 1 0;
  list-style: none;
  overflow-y: scroll;    
`;

export const StyledNoResults = styled.li`
  width: 100%;
  font-weight: bold;
  color: #000;
  font-size: 15px;
`;
