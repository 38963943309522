import styled from 'styled-components';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Button from '@material-ui/core/Button';

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const StyledContentTitle = styled.h2`
  color: #34525d;
  font-size: 17px;
  font-weight: bold;
  font-family: Cairo; 
  text-align: center;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const StyledDownloadListButton = styled(Button)`

  && {
    display: block;
    background-color: #8DC63F;
    color: white;
    border-radius: 50%;
    text-transform: none;
    font-size: 12px;
    font-family: Cairo;
    font-weight: bold;
    padding: 0;
    min-width: 30px;
  }
`;

export const StyledDownloadIcon = styled(SaveAltIcon)`
&& {
    color: white;
  }

`;

export const StyledDownloadLink = styled.a`
  text-decoration: none;
  visibility: hidden;
  width: 0;  
`;
