import styled from 'styled-components';

interface IContainerProps {
  $isSelected: boolean;
}

export const StyledContainer = styled.li<IContainerProps>`

  padding: 0;
  margin: 0;
  width: 100%;
  height: 49px; 
  margin-bottom: 2px;
  background-color: ${(props:IContainerProps) => props.$isSelected ? '#F7FFED' : 'white'};

  &:hover {
    background-color: #E9F5DA;
    cursor: pointer;
  }

`;

export const StyledInnerContainer = styled.div`

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

`;

export const StyledTitle = styled.h4`

  padding: 0;
  margin: 0;
  flex: 0 0 150px;
  font-size: 10px;
  font-weight: bold;
  font-family: Cairo;
  color: black;
  padding-left: 10px;
`;

export const StyledFilingLink = styled.a`

  flex: 0 0 60px;  
  padding: 0;
  margin: 0;
  text-decoration: underline;
  color: #8DC63F;
  font-family: Cairo;
  font-size: 8px;
  font-weight: bold;
  text-align: left;

`;
