import React from 'react';
import styled from 'styled-components';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

interface IProps {
  styles?: {
    maxWidth?: string;
    margin?: string;
    searchIconSize?: string;
    closeIconSize?: string;
  };
  value: string;
}

export const useCustomSearchStyles = makeStyles<Theme, IProps>({
  container: {
    width: '100%',
    display: 'flex',
    maxWidth: ({ styles }) => styles?.maxWidth || '250px',
    margin: ({ styles }) => styles?.margin || '0 30px 0 0',
  },
  input: {
    fontWeight: 500,
    fontFamily: 'Cairo',
    color: '#000',
    width: '100%',
    backgroundColor: 'white',
    margin: '0',
    padding: '0px 0px 0px 12px',
    fontSize: '14px',
    border: ({ value }) => value ? '1px solid #8DC63F' : '1px solid #304F5A',
    '&:hover': {
      borderColor: '#8DC63F',
    },
    '&::placeholder': {
      color: '#e5e5e5',
    },
  },
});

interface IButtonContainer {
  $isActiveSearch?: boolean;
}

export const ButtonContainer = styled.div<IButtonContainer>`
  height: 38px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: ${(props: IButtonContainer) => props.$isActiveSearch ? '38px' : '76px'};
`;

interface ISearchButton {
  $clearVisible?: boolean;
  $isActiveSearch?: boolean;
}

const StyledSearchButton = styled(Button)<ISearchButton>`
  && {
    background-color: transparent;
    display: ${(props:ISearchButton) => props.$isActiveSearch && props.$clearVisible ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    border-radius: 0;
    padding: 0 10px;
    min-width: 0;
    height: 25px;
    line-height: 25px;
  }

  svg + && {
    border-style: solid;
    border-left-width: 1px;
    border-left-color: ${(props:ISearchButton) => props.$clearVisible ? '#304F5A' : 'transparent'};
  }
`;

const StyledSearchButtonIcon = styled(SearchIcon)<IIconProps>`

  && {
    color: #8DC63F;
    font-size: ${(props:IIconProps) => props.$iconSize ? props.$iconSize : '20px'};
  }
  
`;

interface ISearchButtonProps {
  $iconSize?: string;
  $clearVisible?: boolean;
  $isActiveSearch?: boolean;
  disabled: boolean;
}

export const SearchButton: React.FC<ISearchButtonProps> = (props: ISearchButtonProps) => {
  return (
    <StyledSearchButton
      $isActiveSearch={props.$isActiveSearch}
      $clearVisible={props.$clearVisible}
      type="submit"
      disabled={props.disabled}
    >
      <StyledSearchButtonIcon $iconSize={props.$iconSize} />
    </StyledSearchButton>
  );
};

const StyledDecorativeIconBorder = styled.span`  
  flex: 0 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  margin: 10px 10px 10px 20px;    
  border: 1px solid lightgrey;
  border-radius: 50%;
`;

interface IIconProps {
  $iconSize?: string;
  // eslint-disable-next-line
  $iconVisible?: boolean;
  // eslint-disable-next-line
  $isActiveSearch?: boolean;
}

const StyledDecorativeIcon = styled(SearchIcon)<IIconProps>`

  && {    
    color: lightgrey;
    font-size: ${(props:IIconProps) => props.$iconSize ? props.$iconSize : '16px'};
  }
`;

export const DecorativeIcon: React.FC<IIconProps> = (props: IIconProps) => {
  return (
    <StyledDecorativeIconBorder>
      <StyledDecorativeIcon $iconSize={props.$iconSize} />
    </StyledDecorativeIconBorder>
  );
};

export const StyledDeleteIcon = styled(ClearIcon)<IIconProps>`
  && {
    display: ${(props:IIconProps) => props.$iconVisible ? 'flex' : 'none'};
    cursor: pointer;
    color: ${(props:IIconProps) => props.$iconVisible && props.$isActiveSearch ? '#8DC63F' : '#757575'};
    font-size: ${(props:IIconProps) => props.$iconSize ? props.$iconSize : '20px'};
    margin-right: 8px;
  }
`;
