import React, {
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref: React.RefObject<HTMLElement>, deps: any[], callback: () => void) {
  useEffect(() => {
    /**
    * Alert if clicked on outside of element
    */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [deps]);
}

export function useResize(ref: React.RefObject<HTMLElement>) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([ref.current?.getBoundingClientRect().width ?? 0, ref.current?.offsetHeight ?? 0]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}
