import styled from 'styled-components';

export const StyledContainer = styled.div`
  min-width: 40px;
  background-color: #304F5A;
  padding: 10px 0px 10px 10px;
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  flex-grow: 0.5;
`;

export const StyledTitle = styled.h2`
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  line-height: 1.2rem;

  @media(max-width: 1600px) {
    font-size: 0.9rem;
  }
`;

export const StyledInput = styled.input`
  padding: 10px 0px 10px 10px;
  min-width: 40px;
  flex-grow: 0.5;
  background-color: #304F5A;
  border: 1px solid white;
  color: white;
  font-weight: bold;

  &:focus-visible {
    outline: none;
    border: 2px solid white;
  }
`;
