import styled from 'styled-components';

export const StyledMainContainer = styled.main`

  width: 100%;  
  margin-top: 8vh;  
`;

export const StyledFlexContainer = styled.div`

  width: 100%;  
  display: flex;
  height: 92vh;
  overflow: hidden;
  /* overflow: auto; */
  /* overflow-y: scroll;
  overflow-x: hidden; */
`;
