import styled from 'styled-components';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export const StyledContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: 20px;
  height: inherit;
`;

export const StyledListContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 85%;
  overflow-y: scroll;  
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledExpertPositionContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #9F9F9F;  
  margin-bottom: 10px;
  padding: 15px 0;
`;

export const StyledExpertPositionLabel = styled.label`
  display: block;
  margin: 0;
  font-size: 12px;
  color: #000000;
  font-weight: 700;
`;

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  && {
    color: #8DC63F;
  }
  &:hover {
    cursor: pointer;
  }
`;
