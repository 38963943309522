import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import CustomTag from '../../../../../components/customTag';
import CustomAutocomplete from '../../../../../components/customAutocomplete';
import * as expertSearchUtils from '../../../../../entities/expertSearch/utils';
import {
  StyledContainer,
  StyledInfoIcon,
  StyledLabel,
  searchExpertiseStyles,
  StyledTagContainer,
} from './style';

interface IProps {
  isSelectedSearchEditable: boolean;
  setEditedExpertSearch: (expertise: ExpertSearchEditType | null) => void;
  selectedExpertSearches: ExpertSearchType[];
  selectedExpertSearchId: string | null;
  editedExpertSearch: ExpertSearchEditType | null;
  handleExpertiseTagDeletion: (expertiseId: string) => void;
  expertises: ExpertiseType[];
}

const ExpertiseTags: React.FC<IProps> = (props: IProps) => {
  const [editedExpertSearchId, setEditedExpertSearchId] = useState('');

  const getTags = () => {
    if (props.editedExpertSearch) {
      return props.editedExpertSearch.expertiseIds;
    }
    const searchObject: ExpertSearchType = expertSearchUtils.getExpertSearchObject(
      props.selectedExpertSearchId, props.selectedExpertSearches,
    );
    return searchObject ? searchObject.expertiseIds : [];
  };

  const displayTags = () => {
    const selectedExpertiseIds = getTags();
    const selectedExpertiseList = selectedExpertiseIds.map((expertiseId: string) => {
      return props.expertises.find((item: ExpertiseType) => item._id === expertiseId);
    });

    if (selectedExpertiseList.length === 0 && !props.isSelectedSearchEditable) {
      return (
        <div>
          <h3 style={{ margin: '6px 0px 0px 0px', fontWeight: 'lighter' }}>No info added</h3>
        </div>
      );
    }
    const thresholdOfWords = 5; // Threshold of expertiseTypes allowed
    const remainingExpertiseTags = selectedExpertiseList.length - thresholdOfWords;
    return (
      <>
        {selectedExpertiseList.map((expertiseObject: ExpertiseType | undefined, idx: number) => {
          const {
            _id: id,
            name,
          } = expertiseObject as ExpertiseType;
          return (idx < thresholdOfWords && (
            <CustomTag
              key={id}
              title={name}
              handleDeleteIconClick={props.isSelectedSearchEditable ? () => props.handleExpertiseTagDeletion(id) : null}
              styles={{
                backgroundColor: '#D7D7D7',
                borderRadius: 0,
                color: 'black',
                margin: '3px 2px 3px 2px',
                padding: '0',
                fontSize: 10,
                fontFamily: 'Cairo',
              }}
            />
          )
          );
        })}
        {remainingExpertiseTags > 0 && (
          <CustomTag
            title={`+${remainingExpertiseTags}`}
            handleDeleteIconClick={null}
            styles={{
              backgroundColor: '#828282',
              borderRadius: 0,
              color: 'white',
              margin: '3px 2px 3px 2px',
              padding: '0',
              fontSize: 12,
              fontFamily: 'Cairo',
              fontWeight: 'bold',
            }}
          />
        )}
      </>
    );
  };

  const handleExpertiseSelectionChange = async (expertiseId: string | null) => {
    if (!expertiseId) {
      return;
    }
    setEditedExpertSearchId('');
    const selectedExpertiseIds : string[] = [...getTags().filter((tag) => tag !== expertiseId), expertiseId];
    const newEditedExpertSearch : ExpertSearchEditType = {
      ...props.editedExpertSearch as ExpertSearchEditType,
      expertiseIds: selectedExpertiseIds,
    };
    props.setEditedExpertSearch(newEditedExpertSearch);
  };

  return (
    <StyledContainer>
      <StyledLabel>Expertises:</StyledLabel>
      <Tooltip title="Expertises based on experts publications and patents">
        <StyledInfoIcon />
      </Tooltip>
      {props.isSelectedSearchEditable && (
        <div style={{ flexShrink: 0 }}>
          <CustomAutocomplete
            optionsList={props.expertises.filter(
              (
                (item) => !props.editedExpertSearch?.expertiseIds.find(
                  (id) => item._id === id,
                )
              ),
            )}
            optionKeyName="_id"
            optionLabelName="name"
            entityName="Expertise"
            handleChange={handleExpertiseSelectionChange}
            placeholder="Select one expertise"
            selectedValue={editedExpertSearchId}
            inputStyles={searchExpertiseStyles.input}
            listContainerStyles={searchExpertiseStyles.listContainer}
            listItemsStyles={searchExpertiseStyles.listItems}
          />
        </div>
      )}
      <StyledTagContainer>
        {displayTags()}
      </StyledTagContainer>
    </StyledContainer>
  );
};

export default ExpertiseTags;
