import { gql } from '@apollo/client';

export const GET_EXPERTISES = gql`
    query GetExpertises {
        expertise_getAll {
            _id
            expertTypeId
            name 
            description               
        }
    }
`;
