import { gql } from '@apollo/client';

export const GET_FILING_DISPUTE_VALUE_BY_FILING = gql`
  query GetFilingDisputeValueByFiling($companyId: ID!, $filingId: ID!) {
    filingDisputeValue_getByFiling(companyId: $companyId, filingId: $filingId) {
      companyId
      filingId
      value
    }
  }
`;

export const GET_FILING_DISPUTE_VALUES_BY_FILINGS = gql`
  query GetFilingDisputeValuesByFilings($input: [FilingDisputeInput]!) {
    filingDisputeValues_getByFilings(input: $input) {
      companyId
      filingId
      value
    }
  }
`;
