import React from 'react';
import { Select } from '@material-ui/core';

import {
  StyledContainer,
  StyledTitle,
  useStyles,
  StyledFormControl,
  StyledMenuItem,
  StyledValue,
} from './style';

interface IProps {
  positionValue: 'CONSULTING' | 'TESTIFYING';
  handlePositionChange: (positionValue: 'CONSULTING' | 'TESTIFYING') => void;
  editedExpertSearch: ExpertSearchEditType | null;
  $isEditing: boolean;
}

const ExpertPosition: React.FC<IProps> = (props: IProps) => {
  const classes: any = useStyles();

  const menuProps: any = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const handleOptionChange = (e: any) => {
    props.handlePositionChange(e.target.value);
  };

  return (
    <StyledContainer $isEditing={props.$isEditing}>
      <StyledTitle>Position:</StyledTitle>
      {!props.editedExpertSearch ? (
        <StyledValue data-testid="expert-search-position-value">{props.positionValue?.toLowerCase() ?? ''}</StyledValue>
      ) : (
        <StyledFormControl>
          <Select
            disabled={!props.editedExpertSearch}
            disableUnderline
            value={props.positionValue}
            onChange={handleOptionChange}
            classes={{ root: classes.select, icon: classes.icon, disabled: classes.disabled }}
            MenuProps={menuProps}
            data-testid="expert-search-position-select"
            renderValue={(value: any) => {
              const newValue = value.toLowerCase();
              return <span style={{ textTransform: 'capitalize' }}>{newValue}</span>;
            }}
          >
            {['consulting', 'testifying'].map((item: string) => {
              return (
                <StyledMenuItem key={item} value={item.toUpperCase()}>{item}</StyledMenuItem>
              );
            })}
          </Select>
        </StyledFormControl>
      )}
    </StyledContainer>

  );
};

export default ExpertPosition;
