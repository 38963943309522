/* eslint-disable */
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import EditIcon from '@material-ui/icons/Edit';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

export const StyledContainer = styled.div<ContainerProps>`  
  min-height: 300px;
  padding: 0;  
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: ${(props) => props.$isEditing ? '0' : '20px'};
  border-bottom: 8px solid #8DC63F;
  position: relative;
  margin-top: ${(props) => props.$isOpen ? '0' : '-260px'};
  transition: all .2s linear;  
  background-color: ${(props) => props.$isOpen ? '#ffffff' : '#ffffff00'};
`;

export const StyledSubContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  opacity: ${(props) => props.$isOpen ? '1' : '0'};
  transition: all .1s ease-in-out;
`;

export const StyledSectionTitle = styled.h2`
  display: inline-flex;
  padding: 0;  
  color: #000000;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0px;
`;

export const StyledSection = styled(Grid)`
  margin-bottom: 15px;
`;

export const StyledInputContainer = styled.div`
  background-color: white;
  border: none;  
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const inputStyles = {
  boxShadow: 'none',
  backgroundColor: 'white',
  border: '1px solid gray',
  textColor: '#757575',
  fontSize: 12,
  fontFamily: 'Cairo, Verdana, Geneva, Tahoma, sans-serif',
  focusTextColor: '#757575',
  padding: '5px 10px',
  focusBackgroundColor: 'white',
  focusBorder: '1px solid gray',
  disabledBackgroundColor: 'white',
  disabledTextColor: '#757575',
  selectionBackgroundColor: '#668793',
  selectionTextColor: 'white',
  lineHeight: 14,
};

export const StyledSaveButton = styled(Button)`

  && {
    background-color: ${(props) => props.color === 'primary' ? '#8DC63F' : '#6B9D27'};
    color: white;    
    text-transform: none;
    padding: 5px 60px; 
    border-radius: 0;
    font-family: Cairo;
    font-weight: bold;
    font-size: 12px;
    min-width: 199.86px;

    &:last-of-type {
      margin-left: 8px;
    }

    &:disabled {
      background-color: #F0F0F0;
      color: #C7C7C7;
    }
  }
`;

export const StyledPanelButtonContainer = styled.span`
  position: absolute;
  bottom: -1px;
  left: 50%;
  padding: 0;
  margin: 0;
  margin-left: -65px;
  display: block;
  background-color: #8DC63F;
  width: 130px;
  height: 20px; 
  display: flex;
  justify-content: center;
  align-items: center; 
  &:hover {
    cursor: pointer;
  }
`;

interface ContainerProps {
  $isOpen: boolean;
  $isEditing?: boolean;
}

export const StyledDoubleArrowIcon = styled(DoubleArrowIcon)<ContainerProps>`

  && {
    color: white;
    margin: 0;
    margin-top: 5px;
    font-size: 20px;
    transform: ${(props) => props.$isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'};
  }  
  

`;

export const StyledPanelClosedTitle = styled.h3<ContainerProps>`
  margin: 0;
  padding: 0;  
  color: #757575;
  font-size: 15px;
  font-weight: bold;
  width: 100%;  
  padding: 10px 20px;
  display: ${(props) => props.$isOpen ? 'none' : 'block'};
  transition: all .2s linear;
`;

export const StyledIconButton = styled(IconButton)`
  && {    
    padding: 4px;
    color: black;
    margin-left: 8px;

  }

  &:hover{
    cursor: pointer;
    background-color: #0000004d;
  }

`;

export const StyledEditIcon = styled(EditIcon)`

  color: #8DC63F;


  &:hover {
    cursor: pointer;
  }
`;

export const StyledLabel = styled.h3`
  font-weight: bold;
  margin: 0;
`;

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  && {
    color: #757575;
    margin-top: 2px;
  }
`;

export const StyledSectionSub = styled(Grid)`
  display: flex; 
  flex-wrap: no-wrap;
`;

export const StyledExpertDescriptionSubContainer = styled.div`
  max-width: 755px;
  max-height: 70px;
  overflow-y: auto;
  word-break: break-word;
`;

export const StyledExpertDescriptionLabel = styled.h3`
  margin: 0px;
  font-weight: lighter;
`;
