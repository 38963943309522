import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;  
  background-color: white;  
  transition: all .2s linear;

  &:hover {    
    box-shadow: '0px 2px 3px rgba(0, 0, 0, 0.25)';
    margin-bottom: 2px;
  }
`;

export const StyledFlexContainer = styled.div`  
  width: 100%;
  display: flex; 
`;

export const StyledExpandableArea = styled.div`
  width: 100%;
  height: 80px;
  transition: all .2s linear;  
  background-color: white;
`;
