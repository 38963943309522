import React, { useState } from 'react';

import {
  StyledContainer,
  StyledLoadingSpinner,
  StyledListContainer,
} from './style';

import GridHeader from './header';
import GridList from './list';

import { EnumSortOrder } from './types';

interface IProps {
  dataTestIdPrefix: string;
  isLoading: boolean;
  data: any[];
  columns: {
    headerProps: {
      label: string;
      width: string;
    };
    cellRenderer: (
      item: any,
      setRowOpen: (value: boolean) => void,
      isRowOpen: boolean,
    ) => JSX.Element;
    compareTo: (a: any, b: any) => number;
  }[];
  rowCollapser?: (
    item: any,
    isRowOpen: boolean
  ) => JSX.Element;
  getDataIdentifier: (dataItem: any) => string;
}

const ExpandableGrid: React.FC<IProps> = (props: IProps) => {
  const [sortOrder, setSortOrder] = useState<EnumSortOrder>(EnumSortOrder.ASC);
  const [sortedColumnLabel, setSortedColumnLabel] = useState<string | null>(null);

  const handleSort = (labelName: string) => {
    if (sortOrder === EnumSortOrder.ASC) {
      setSortOrder(EnumSortOrder.DESC);
    } else {
      setSortOrder(EnumSortOrder.ASC);
    }
    setSortedColumnLabel(labelName);
  };

  const getSortedData = () => {
    if (!sortedColumnLabel) return props.data;
    const selectedColumn = props.columns.find((column) => column.headerProps.label === sortedColumnLabel);
    if (!selectedColumn) return props.data;

    if (sortOrder === EnumSortOrder.ASC) return [...props.data].sort(selectedColumn!.compareTo);

    return [...props.data].sort((a: any, b: any) => selectedColumn!.compareTo(a, b) * -1);
  };

  const sortedData = getSortedData();

  const displayContent = () => {
    if (props.isLoading && props.data.length === 0) return <StyledLoadingSpinner />;

    return (
      <GridList
        data={sortedData}
        dataTestIdPrefix={props.dataTestIdPrefix}
        columns={props.columns}
        rowCollapser={props.rowCollapser}
        getDataIdentifier={props.getDataIdentifier}
        isLoading={props.isLoading}
      />
    );
  };

  return (
    <StyledContainer>
      <GridHeader
        columns={props.columns}
        handleSort={handleSort}
        sortOrder={sortOrder}
        sortedColumnLabel={sortedColumnLabel}
      />
      <StyledListContainer isLoading={props.isLoading && props.data.length === 0}>
        {displayContent()}
      </StyledListContainer>
    </StyledContainer>
  );
};

export default ExpandableGrid;
