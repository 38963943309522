import styled from 'styled-components';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const colorPrimary = '#8DC63F';
const colorSecondary = '#6B9D27';
const colorDisabled = '#D7D7D7';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 750px;
  align-items: center;
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  background-color: ${colorPrimary};
  gap: 16px;
  color: white;
  padding: 20px 0px 20px 20px;
  align-items: center;
  box-sizing: border-box;
`;

export const StyledTitleHeader = styled.h2`
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

export const StyledContent = styled.div`
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
`;

export const StyledPersonAddIcon = styled(PersonAddIcon)`
  font-size: 1.7rem;
`;

export const StyledText = styled.h4`
  font-weight: 700;
  font-size: 12px;
  margin: 0;
  padding: 0;
`;

export const StyledSpan = styled.span`
  font-weight: initial;
`;

export const StyledSubContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  height: 100%;
`;

interface IPagination {
  hasPagination?: boolean;
}
export const StyledButtonsContainer = styled.div<IPagination>`
  display: flex;
  justify-content: flex-end;
  color: white;
  gap: 4px;
  width: ${(props) => props.hasPagination ? '50%' : '100%'};
`;

export const StyledButton = styled(Button)`
  && {
    background-color: ${(props) => {
    if (props.color === 'primary') return colorPrimary;
    if (props.color === 'secondary') return colorSecondary;
    return colorDisabled;
  }};
  padding: 4px 48px;
  font-size: 12px;
  text-transform: unset;
  border-radius: 0px;
  }

  &:disabled {
    color: inherit;
  }

`;

export const StyledButtonPaginationContainer = styled.div`
  display: flex;
`;

const LoadingWithSpinner = styled.div`
  width: 100%;
  height: 348px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 200000;
  opacity: 1;
  margin: 12px 0px;
`;

const CenterContainer = styled.div`
  width: inherit;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;  
`;

const BoldTitle = styled.h3`
  color:#304F5A;
  font-size: 14px;
  font-family: Cairo;
  font-weight: bold;
  text-align: center;
  padding: 0;
  margin: 0;
`;

const NoBoldTitle = styled.h3`
  color:#304F5A;
  font-size: 14px;
  font-family: Cairo;
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: 600;
`;

export const StyledLoadingSpinner: React.FC = () => (
  <LoadingWithSpinner>
    <CenterContainer>
      <CircularProgress style={{ color: colorPrimary }} />
      <BoldTitle>Loading results</BoldTitle>
    </CenterContainer>
  </LoadingWithSpinner>
);

export const StyledNotFound: React.FC = () => (
  <LoadingWithSpinner>
    <CenterContainer>
      <BoldTitle>
        No results
      </BoldTitle>
      <NoBoldTitle>
        Change your parameters or start a new search
      </NoBoldTitle>
    </CenterContainer>

  </LoadingWithSpinner>
);
