import React from 'react';

import { LabelWrap } from './styles';
import labelIcon from '../../assets/svg/labelIcon.svg';

interface IProps {
  labelName: string;
  link: string;
}

const DataSourceLabel: React.FC<IProps> = (props: IProps) => {
  return (
    <LabelWrap>
      <img src={labelIcon} alt="Icon" />
      <p>
        Powered by
        <a href={props.link} target="_blank" rel="noreferrer" style={{ marginLeft: '5px' }}>
          {props.labelName}
        </a>
      </p>
    </LabelWrap>
  );
};

export default DataSourceLabel;
