import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  MenuItem,
} from '@material-ui/core';

interface IEditable {
  $isEditing: boolean;
}
export const StyledContainer = styled.div<IEditable>`
  flex: 1 1 0;  
  min-width: 100px;
  background-color: white;
  padding-left: 50px;
  color: white;  
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: ${(props) => props.$isEditing ? '36px' : '0px'};
`;

export const StyledTitle = styled.h2`
  padding: 0;
  margin: 0;  
  font-size: 1rem;
  font-weight: normal;  
  text-align: right;
  margin-right: 5px;
  color: black;
`;

export const StyledFormControl = styled(FormControl)`
  && {
    width: 100px; 
    background: 'white',     
  }  

  & > div.MuiOutlinedInput-root {   
      border-radius: 25px; 
      background: 'white',
  }    
    
`;

export const useStyles = makeStyles({
  select: {
    minWidth: 55,
    background: 'white',
    color: 'black',
    border: '1px solid black',
    borderRadius: 0,
    paddingLeft: 0,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 0,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    fontSize: '0.9rem',
    textAlign: 'center',
    fontFamily: 'Cairo, Verdana, Geneva, Tahoma, sans-serif',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    '&:focus': {
      background: 'white',
      borderRadius: 0,
      border: '1px solid black',
    },
  },
  disabled: {
    background: '#304F5A',
    color: 'white',
    boxShadow: 'none',
    border: 'none',
  },
  icon: {
    color: 'black',
    marginTop: 1,
    flexShrink: 0,
    userSelect: 'none',
    pointerEvents: 'none',
    fontSize: 22,
  },
  paper: {
    borderRadius: 0,
    marginTop: 8,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    background: 'white',
    '& li': {
      paddingTop: 12,
      paddingBottom: 12,
      fontSize: 12,
      color: '#707070',
      fontFamily: 'Cairo, Verdana, Geneva, Tahoma, sans-serif',
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    '& li:hover': {
      background: '#F0F0F0',
    },
    '& li.Mui-selected': {
      color: '#707070',
      background: '#F0F0F0',
    },
    '& li.Mui-selected:hover': {
      background: '#F0F0F0',
    },
  },
});

export const StyledMenuItem = styled(MenuItem)`
  font-weight: bold;
  text-transform: capitalize;
  color: black;
`;

export const StyledValue = styled.h2`
  padding: 0;
  margin: 0;  
  font-size: 1rem;
  font-weight: bold;  
  text-align: right;
  margin-right: 5px;
  color: black;
  text-transform: capitalize;
`;
