import React, { useContext, useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import { ProjectContext, ProjectContextType } from '../../context/projectContext';
import { ExpertiseContext, ExpertiseContextType } from '../../context/expertisesContext';
import { ExpertContext, ExpertContextType } from '../../context/expertContext';
import { EditedExpertSearchContext, EditedExpertSearchContextType } from '../../context/editedExpertSearch';

import ErrorComponent from '../../components/errorComponent';
import Header from './header';

import * as expertSearchService from '../../entities/expertSearch/service';

import {
  StyledContainer,
  StyledProjectInfoContainer,
} from './style';

import Alert from '../../components/alert';

import * as expertShortlistedService from '../../entities/expertShortlisted/service';
import * as expertFavoriteService from '../../entities/expertFavorite/service';

import ExpertSearchContent from './expertSearchContent';
import ProjectContent from './projectContent';

const ProjectView = () => {
  const params = useParams<any>();
  const history = useHistory();
  const client = useApolloClient();
  const editedExpertSearchState = useContext<EditedExpertSearchContextType>(EditedExpertSearchContext);

  const projectState = useContext<ProjectContextType>(ProjectContext);
  const expertiseState = useContext<ExpertiseContextType>(ExpertiseContext);
  const expertState = useContext<ExpertContextType>(ExpertContext);

  const [isEditingProject, setIsEditingProject] = useState(false);
  const [valueAboutProject, setValueAboutProject] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [$isOpen, setIsOpen] = useState(true);
  const [isUpdatingSearch, setIsUpdatingSearch] = useState(false);

  const editedExpertSeachState = useContext<EditedExpertSearchContextType>(EditedExpertSearchContext);

  const project = projectState.projects.find((p: ProjectType) => p._id === projectState.selectedProjectId);

  const fetchData = async () => {
    try {
      const expertSearchList = await expertSearchService.fetchExpertSearches(client, params.selectedProjectId!);
      if (expertSearchList) projectState.setSelectedExpertSearches(expertSearchList);

      const expertsShortlisted = await expertShortlistedService.fetchExpertsShortlisted(
        params.selectedProjectId!,
      );

      const expertsFavorites = await expertFavoriteService.fetchExpertsFavorites();

      expertState.setExpertFavorites(expertsFavorites.favorites);
      expertState.setExpertsShortlisted(expertsShortlisted.shortlist);
      expertState.setExperts(expertsShortlisted.experts);

      return {
        expertSearchList: expertSearchList ?? [],
        expertsShortlisted,
        expertsFavorites,
      };
    } catch (e) {
      return {
        expertSearchList: [],
      };
    }
  };

  useEffect(() => {
    const isValidProject = projectState.projects.some(
      (_project: any) => _project._id === params.selectedProjectId,
    );
    if (!params.selectedProjectId || !isValidProject) {
      history.push('/experts');
      return;
    }

    if (params.selectedProjectId !== projectState.selectedProjectId) {
      projectState.setSelectedProjectId(params.selectedProjectId ?? null);
    }
    const getFetchData = async () => {
      const { expertSearchList } = await fetchData();

      const isInProject = expertSearchList.some(
        (expertSearchId: any) => expertSearchId._id === params.selectedSearchId,
      );

      if (!isInProject) return;
      if (params.selectedSearchId === projectState.selectedExpertSearchId) {
        return;
      }
      projectState.setSelectedExpertSearchId(params.selectedSearchId);
    };
    getFetchData();
  }, []);

  useEffect(() => {
    fetchData();
    setValueAboutProject(project?.about || '');
  }, [projectState.selectedProjectId]);
  const isASearchSelected = !!projectState.selectedExpertSearchId;
  const isSelectedSearchEditable = !!editedExpertSeachState.editedExpertSearch;

  const handleShowSuccess = (message: string) => {
    setSuccessMessage(message);

    setTimeout(() => {
      setSuccessMessage(null);
    }, 3000);
  };

  const updateSearch = async (updatedSearch: ExpertSearchType) => {
    try {
      const response = await expertSearchService.updateExpertSearch(client, {
        _id: updatedSearch._id,
        projectId: updatedSearch.projectId,
        expertiseIds: updatedSearch.expertiseIds,
        idealExpertIds: updatedSearch.idealExpertIds,
        description: updatedSearch.description,
        position: updatedSearch.position,
        name: updatedSearch.name,
      });
      const updatedExpertSeachList = projectState.selectedExpertSearches.map((s) => {
        if (s._id === updatedSearch._id) return updatedSearch;
        return s;
      });
      projectState.setSelectedExpertSearches(updatedExpertSeachList);
      projectState.setSelectedExpertSearchId(updatedSearch._id);
      editedExpertSearchState.setEditedExpertSearch(response.search);

      handleShowSuccess('Changes saved');
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const executeSearch = async (executedSearch: ExpertSearchType) => {
    setIsUpdatingSearch(true);
    try {
      const response = await expertSearchService.executeExpertSearch(client, {
        _id: executedSearch._id,
        projectId: executedSearch.projectId,
        expertiseIds: executedSearch.expertiseIds,
        idealExpertIds: executedSearch.idealExpertIds,
        description: executedSearch.description,
        position: executedSearch.position,
        name: executedSearch.name,
      });
      expertState.setExperts(response.experts);

      projectState.setSelectedExpertSearchResults(response.results);
      projectState.setSelectedExpertSearchId(response.search._id);

      const updatedExpertSeachList = projectState.selectedExpertSearches.map((s) => {
        if (s._id === executedSearch._id) return executedSearch;
        return s;
      });
      projectState.setSelectedExpertSearches(updatedExpertSeachList);
      editedExpertSearchState.setEditedExpertSearch(null);

      handleShowSuccess('Changes saved');
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setIsUpdatingSearch(false);
    }
  };

  const changeInputValue = (value: string) => {
    setIsEditingProject(true);
    setValueAboutProject(value);
  };

  if (errorMessage) {
    return (
      <ErrorComponent title={`ERROR: ${errorMessage}`} />
    );
  }

  const displayProjectInfo = () => {
    if (params.selectedProjectId !== projectState.selectedProjectId) {
      return null;
    }
    if (!project) return null;

    return (
      <StyledProjectInfoContainer
        showSearch={isASearchSelected || isSelectedSearchEditable}
        onClick={() => projectState.setUpdateProject(true)}
      >
        <Header
          projectName={project.name}
          projectCode={project.projectCode}
          isSelectedSearchEditable={isSelectedSearchEditable}
          isASearchSelected={isASearchSelected}
          selectedExpertSearches={projectState.selectedExpertSearches}
          selectedExpertSearchId={projectState.selectedExpertSearchId}
          setSelectedExpertSearchId={projectState.setSelectedExpertSearchId}
          setEditedExpertSearch={editedExpertSeachState.setEditedExpertSearch}
          updateSearch={updateSearch}
        />
        {
          (isASearchSelected || isSelectedSearchEditable)
            ? (
              <ExpertSearchContent
                expertises={expertiseState.expertises}
                isSelectedSearchEditable={isSelectedSearchEditable}
                $isOpen={$isOpen}
                setIsOpen={setIsOpen}
                updateSearch={updateSearch}
                executeSearch={executeSearch}
                isUpdatingSearch={isUpdatingSearch}
                setIsUpdatingSearch={setIsUpdatingSearch}
              />
            )
            : (
              <ProjectContent
                project={project}
                valueAboutProject={valueAboutProject}
                changeInputValue={changeInputValue}
                updateProject={projectState.updateProject}
                setUpdateProject={projectState.setUpdateProject}
                handleShowSuccess={handleShowSuccess}
                isEditingProject={isEditingProject}
                setIsEditingProject={setIsEditingProject}
              />
            )
        }
      </StyledProjectInfoContainer>
    );
  };

  return (
    <StyledContainer>
      {displayProjectInfo()}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ transform: 'translateY(70px)', zIndex: 9999999999 }}
      >
        <Alert
          severity="success"
          data-testid="success-message-alert"
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ transform: 'translateY(70px)', zIndex: 9999999999 }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default ProjectView;
