import styled from 'styled-components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const StyledContainer = styled.div`
  display: flex; 
  flex-direction: column;
`;

export const StyledContainerInfo = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 50px 50px 50px; 
  gap: 0px 0px; 
  grid-template-areas: 
  ". . ."
  ". . ."
  ". . ."; 
  grid-area: body;
  padding: 0;  
  padding-left: 35px;
  padding-right: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 30px 0px;
  height: 150px;
`;

interface IFlexSectionProps {
  margin?: string;
}

export const StyledFlexSection = styled.div<IFlexSectionProps>`
  box-sizing: border-box;
  width: 100%;
  display: flex;  
  justify-content: space-between;
  align-items: flex-start; 
  margin: ${(props) => props.margin ? props.margin : '0'};    
`;

interface IColProps {
  width?: string;
  paddingRight?: string;
}

export const StyledSectionCol = styled.div<IColProps>`
  box-sizing: border-box;
  flex: ${(props) => props.width ? `0 1 ${props.width}` : '0 1 47%'};
  padding-right: ${(props) => props.paddingRight ? props.paddingRight : '0'};
`;

export const StyledSectionTitle = styled.h3`
  margin: 0;
  padding: 0;  
  color: #9F9F9F;
  font-size: 14px;
  font-weight: bold;
  width: 270px;
  border-bottom: 1px solid #9F9F9F;
  margin-bottom: 15px;
  padding: 10px 0;
`;

export const StyledSection = styled.div`

  width: 100%;
  
`;

export const inputStyles = {
  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15);',
  backgroundColor: 'white',
  border: 'none',
  textColor: '#757575',
  width: '96%',
  fontSize: 12,
  fontWeight: 'bold',
  fontFamily: 'Cairo, Verdana, Geneva, Tahoma, sans-serif',
  focusBorder: 'none',
  focusTextColor: '#757575',
  padding: '5px 10px',
  focusBackgroundColor: 'white',
  disabledBackgroundColor: 'white',
  disabledTextColor: '#757575',
  selectionBackgroundColor: '#668793',
  selectionTextColor: 'white',
  textAlign: 'center',
};

export const StyledSectionGrid = styled.div`

`;

export const StyledProjectTitle = styled.h5`
  font-weight: 600;
  margin: 0;
  font-size: 0.7rem;
`;

export const StyledAboutTitle = styled.h5`
  font-weight: bold;
  margin: 0;
  font-size: 0.7rem;
`;

export const StyledProjectValue = styled.h3`
  margin: 0;
  padding: 0;
  color: black;
  font-size: 0.9rem;
  font-family: Cairo, Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
`;

export const StyledContainerCustomInput = styled.div`
  padding-left: 35px;
  padding-right: 35px;
`;

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  && {
    color: #8DC63F;
    margin-top: 2px;
  }
`;

export const StyledProjectStatusContainer = styled.div`
  display: flex;
  gap: 36px;
`;
