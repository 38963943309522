import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { ExpertContext, ExpertContextType } from '../../../../../context/expertContext';
import { ProjectContext, ProjectContextType } from '../../../../../context/projectContext';
import { SidebarsContext, SidebarsContextType } from '../../../../../context/sidebarsContext';

import {
  StyledContainer,
  StyledList,
} from './style';

import ExpertRow from '../../../../../components/expertRow';

import * as expertShortListedService from '../../../../../entities/expertShortlisted/service';
import * as expertFavoriteService from '../../../../../entities/expertFavorite/service';
import { EditedExpertSearchContext, EditedExpertSearchContextType } from '../../../../../context/editedExpertSearch';
import MenuResult from './menuResult';

interface IProps {
  items: ExpertSearchResultType[];
  handleDelete: (expertSearchResultId: string) => void;
  getExpert: (expertId: string) => ExpertType | null;
}

const ResultList: React.FC<IProps> = (props: IProps) => {
  // TODO: NOT USE CONTEXT HERE, GET VALUE FROM PROPS
  const expertState = useContext<ExpertContextType>(ExpertContext);
  const projectState = useContext<ProjectContextType>(ProjectContext);
  const sidebarsState = useContext<SidebarsContextType>(SidebarsContext);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const editedExpertSeachState = useContext<EditedExpertSearchContextType>(EditedExpertSearchContext);

  const history = useHistory();

  const sortedList = _.orderBy([...props.items], ['order'], ['asc']);

  const handleShortListExpert = async (expertId: string) => {
    if (isExpertShortlisted(expertId)) {
      await expertShortListedService.removeExpertShortlisted(
        expertId,
        projectState.selectedExpertSearchId!,
      );
    } else {
      await expertShortListedService.addExpertShortlisted(
        expertId,
        projectState.selectedExpertSearchId!,
      );
    }
    const newExpertsShortlisted = await expertShortListedService.fetchExpertsShortlisted(
      projectState.selectedProjectId!,
    );

    const {
      shortlist: shortListedResults,
      experts: expertResults,
    } = newExpertsShortlisted;

    expertState.setExpertsShortlisted(shortListedResults);
    expertState.setExperts(expertResults);
  };

  const isExpertShortlisted = (expertId: string) => {
    const expertsShortListed = expertState.expertsShortlisted;

    const localExpertShortlisted = expertsShortListed.find((item: ExpertShortlistedType) => {
      return item.expertId === expertId && item.searchId === projectState.selectedExpertSearchId;
    });

    return !!localExpertShortlisted;
  };

  const isExpertFavorite = (expertId: string) => {
    const localExpertFavorite = expertState.expertFavorites.find((item: ExpertFavoriteType) => {
      return item.expertId === expertId;
    });

    return !!localExpertFavorite;
  };

  const handleFavoriteExpert = async (expertId: string) => {
    if (isExpertFavorite(expertId)) {
      await expertFavoriteService.removeExpertFavorite(expertId);

      const newExpertFavorites = expertState.expertFavorites.filter(
        (item: ExpertFavoriteType) => item.expertId !== expertId,
      );
      expertState.setExpertFavorites(newExpertFavorites);
    } else {
      const newExpertFavorite = await expertFavoriteService.addExpertFavorite(expertId);
      expertState.setExpertFavorites([...expertState.expertFavorites, newExpertFavorite]);
    }
  };

  const handleExpertSelected = (expertId: string) => {
    projectState.setSelectedProjectId(null);
    editedExpertSeachState.setEditedExpertSearch(null);
    expertState.setExpertsShortlisted([]);
    sidebarsState.setIsOpenRightSidebar(false);
    sidebarsState.setIsOpenLeftSidebar(false);
    const path = `/experts/${expertId}`;

    history.push(path);
  };

  const handleCheckedList = (expertId: string, value: boolean) => {
    if (value) {
      return setCheckedList(checkedList.concat(expertId));
    }
    return setCheckedList(checkedList.filter((c) => c !== expertId));
  };

  return (
    <StyledContainer>
      <StyledList>
        {
          sortedList.map((item: ExpertSearchResultType) => {
            const expert = props.getExpert(item.expertId);
            if (!expert) return null;
            return (
              <ExpertRow
                key={item._id}
                item={expert}
                handleExpertSelected={handleExpertSelected}
                view="center"
                rightIcon={(
                  <MenuResult
                    handleDelete={props.handleDelete}
                    item={item}
                    isExpertFavorite={isExpertFavorite}
                    isExpertShortlisted={isExpertShortlisted}
                    handleFavoriteExpert={handleFavoriteExpert}
                    handleShortListExpert={handleShortListExpert}
                    handleCheckedList={handleCheckedList}
                  />
                )}
              />
            );
          })
        }
      </StyledList>
    </StyledContainer>
  );
};

export default ResultList;
