import styled from 'styled-components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;  
  height: 45px;
  gap: 8px;
`;

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  && {
    color: #757575;
    margin-top: 2px;
  }
`;

export const StyledLabel = styled.h3`
    font-weight: bold;
    margin: 0px;
`;

export const StyledAddExpertIcon = styled(AddCircleIcon)`

  && {
    color: white;
    margin-right: 12px;
  }
`;

export const StyledAddExpertButton = styled(Button)`

  &:hover {
    cursor: pointer;
  }
  
  && {
    background-color: #8DC63F;
    color: white;
    border-radius: 0;
    text-transform: none;
    font-size: 12px;
    font-family: Cairo;
    font-weight: bold;
    width: 250px; 
    height: 38px; 
    padding: 5px 10px; 
    border: 1px solid;
  }
`;

export const StyledIdealContainer = styled.div`
  align-self: end;
  margin-top: 4px;
  max-height: 78px;
  overflow-y: auto;
  display: flex;
  flex-flow: wrap;
  max-width: 35vw;
`;
