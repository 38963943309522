import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background: #E5E5E5;
`;

export const MainContainer = styled.div`
  height: 100%;
  overflow: scroll;
`;

export const StyledExpertInfoContainer = styled.div`
  flex: 1 1 0;
  width: 100%;
  margin: 10px 0;
  padding: 5px;
  display: flex;
  overflow: auto;
  background: #E5E5E5;
`;

export const ExpertDetails = styled.div`
  position: relative;
  width: 38%;
  margin: 2px;
`;
export const EducationDetails = styled.div`
  position: relative;
  width: 30%;
  margin: 2px;
  background: #FFFFFF;
`;
export const ProjectDetails = styled.div`
  width: 30%;
  margin: 2px;
  position: relative;
  background: #FFFFFF;
`;

export const SideHeading = styled.div`
  font-weight: bold;
  font-size: 1.1em;
  color: #8dc63f;
  margin: 5px;
`;

export const NameWrap = styled.div`
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #757575;
  background: #ffffff;
`;

export const Name = styled.div`
  font-size: 2em;
  font-weight: bold;
  color: #757575;
`;

export const HeadField = styled.div`
  width: 60%;
  font-weight: bold;
  padding: 5px;
`;

export const Subject = styled.div`
  padding: 5px 25px;
  font-weight: bold;
  text-align: center;
  background: #F0F0F0;
`;

export const BioWrap = styled.div`
  background: white;
  height: 250px;
  padding: 5px;
`;

export const BioPara = styled.div`
  margin: 5px;
  height: 180px;
  font-size: 1.1em;
  color: #757575;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

export const Button = styled.div`
  width: 46%;
  background: #8DC63F;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-align: center;
  font-size: 1.3em;
  color: white;
  font-weight: bold;

  && img{
    width: 15px;
    height: 15px;
  }
`;

export const BioLink = styled.a`
  float: right;
  font-size: 1.3em;
  font-weight: bold;
  color: #8DC63F;
  text-decoration: none;
`;

export const StyledSvgImg = styled.img`

  && {
    width: 20px;
    height: 10px;
  }
`;

export const ListWrap = styled.div`
  background: #F0F0F0;
  margin: 10px;
  padding: 5px 2px;
`;

export const UL = styled.ul`
  padding-inline-start: 10px;
`;

export const NotesWrap = styled.textarea`
  width: 90%;
  height: 150px;
  margin: 15px;
  background: #F0F0F0;
  border: none;
  font-size: 1.2em;
  text-align: center;

  &::placeholder {
    color: #c4c4c4;
    font-style: normal;
    font-weight: bold;
    font-size: 0.85em;
    text-align: center;
    line-height: 150px;
  }
`;

export const Li = styled.li`
  font-size: 1.1em;
  list-style-position: inside;
`;

export const LSN = styled(Li)`
  list-style-type: none;
`;

export const Border = styled.div`
  width: 85px;
  margin: 3px 0;
  border-bottom: 0.5px solid #757575;
`;

export const Div = styled.div`
  width: 90%;
  height: 34px;
  margin: 5px;
  font-weight: bold;
  font-size: 1.1em;
  color: #000000;
  background: #F0F0F0;
  
  p{
    padding: 5px;
    margin-left: 5px;
  }
`;

export const ManagerInfo = styled.div`
  width: 100%;
  background: #ffffff;
  margin: 5px 0;
  padding: 10px 0;
`;

export const InfoField = styled.div`
  padding: 5px;
`;

export const ExpertiseWrap = styled.div`
  width: 90%;
  background: #F0F0F0;
  display: flex;
  margin: 5px;
`;

export const ExpertiseItem = styled.div`
  width: 25%;
  padding: 5px;
  margin:0 2px;
  background: #304f5a;
  color: white;
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
`;

export const ItemWrap = styled.span`
  display: block;
  padding: 10px;
`;
