import React, { ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { StyledMainContainer, StyledFlexContainer } from './style';
import Navbar from './navbar';
import LeftSideBar from './leftSideBar';
import RightSideBar from './rightSideBar';
import MainScreen from './mainScreen';

import { SidebarsContext, SidebarsContextType } from '../../context/sidebarsContext';
import { ExpertContext, ExpertContextType } from '../../context/expertContext';
import { ProjectContext, ProjectContextType } from '../../context/projectContext';

import { useAuth0 } from '../../react-auth0-spa';
import { CourtCaseContext, CourtCaseContextType } from '../../context/courtCaseContext';

interface IProps {
  children: ReactNode;
}

const AppLayout: React.FC<IProps> = ({ children }: IProps) => {
  const sidebarsState = useContext<SidebarsContextType>(SidebarsContext);
  const projectState = useContext<ProjectContextType>(ProjectContext);
  const courtCaseState = useContext<CourtCaseContextType>(CourtCaseContext);
  const expertState = useContext<ExpertContextType>(ExpertContext);

  const location = useLocation();

  const shouldDisplayLeftSidebar = () => {
    const displayRoutes = ['/experts', '/projects', '/prospector'];

    return displayRoutes.some((item: string) => location.pathname.includes(item));
  };

  const shouldDisplayRightSidebar = () => {
    const displayRoutes = ['/experts', '/projects', '/prospector'];

    if (displayRoutes.some((item: string) => location.pathname.includes(item))) return true;

    return false;
  };

  const { isAuthenticated } = useAuth0();

  return (
    <StyledMainContainer>
      <Navbar />
      <StyledFlexContainer>
        {isAuthenticated && shouldDisplayLeftSidebar()
          && (
          <LeftSideBar
            isOpen={sidebarsState.isLeftSidebarOpen}
            setIsOpen={sidebarsState.setIsOpenLeftSidebar}
            projects={projectState.projects}
            selectedProjectId={projectState.selectedProjectId}
            setSelectedProjectId={projectState.setSelectedProjectId}
            selectedExpertSearches={projectState.selectedExpertSearches}
            setSelectedExpertSearches={projectState.setSelectedExpertSearches}
            selectedExpertSearchId={projectState.selectedExpertSearchId}
            setSelectedExpertSearchId={projectState.setSelectedExpertSearchId}
            setSelectedExpertSearchResults={projectState.setSelectedExpertSearchResults}
            setSelectedCourtCaseId={courtCaseState.setSelectedCourtCaseId}
            setUpdateProject={projectState.setUpdateProject}
            expertsShortlisted={expertState.expertsShortlisted}
            setExpertsShortlisted={expertState.setExpertsShortlisted}
          />
          )}
        <MainScreen>
          {children}
        </MainScreen>
        {isAuthenticated && shouldDisplayRightSidebar()
          && (
            <RightSideBar
              isOpen={sidebarsState.isRightSidebarOpen}
              setIsOpen={sidebarsState.setIsOpenRightSidebar}
              selectedProjectId={projectState.selectedProjectId}
              selectedExpertSearches={projectState.selectedExpertSearches}
              setUpdateProject={projectState.setUpdateProject}
            />
          )}
      </StyledFlexContainer>
    </StyledMainContainer>
  );
};

export default AppLayout;
