import React from 'react';
import ExpandableGrid from '../../../../components/expandableGrid';

import CellRenderer from './cellRenderer';

import RowCollapser from './rowCollapser';

interface IProps {
  courtCases: CourtCaseType[];
  isLoading: boolean;
  setSelectedCourtCaseId: (id: string | null) => void;
}

const CourtCasesTable: React.FC<IProps> = (props: IProps) => {
  const setSelectedCourtCaseId = props.setSelectedCourtCaseId;

  const columns = [
    {
      headerProps: {
        label: 'Case Name',
        width: '40%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="caseName"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          setSelectedCourtCaseId={setSelectedCourtCaseId}
          fieldName="caseName"
          styleProps={{
            width: '40%',
            padding: '20px 10px 20px 30px',
            textAlign: 'left',
            textColor: 'black',
            fontSize: '12px',
            fontWeight: 'normal',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        if (a.caseName < b.caseName) return -1;

        if (a.caseName > b.caseName) return 1;

        return 0;
      },
    },
    {
      headerProps: {
        label: 'Law Firms',
        width: '20%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="firm"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          setSelectedCourtCaseId={setSelectedCourtCaseId}
          fieldName="firm"
          styleProps={{
            width: '20%',
            padding: '20px 10px 20px 10px',
            textAlign: 'center',
            textColor: 'black',
            fontSize: '10px',
            fontWeight: 'normal',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        if (a.firm < b.firm) return -1;

        if (a.firm > b.firm) return 1;

        return 0;
      },
    },
    {
      headerProps: {
        label: 'Court District',
        width: '25%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="court"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          setSelectedCourtCaseId={setSelectedCourtCaseId}
          fieldName="court"
          styleProps={{
            width: '25%',
            padding: '20px 10px 20px 10px',
            textAlign: 'center',
            textColor: 'black',
            fontSize: '10px',
            fontWeight: 'normal',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        if (a.court < b.court) return -1;

        if (a.court > b.court) return 1;

        return 0;
      },
    },
    {
      headerProps: {
        label: 'Filed Date',
        width: '15%',
      },
      cellRenderer: (
        item: any,
        setRowOpen: (value: boolean) => void,
        isRowOpen: boolean,
      ) => (
        <CellRenderer
          key="dateFiled"
          item={item}
          setRowOpen={setRowOpen}
          isRowOpen={isRowOpen}
          setSelectedCourtCaseId={setSelectedCourtCaseId}
          fieldName="dateFiled"
          styleProps={{
            width: '15%',
            padding: '20px 10px 20px 10px',
            textAlign: 'center',
            textColor: 'black',
            fontSize: '10px',
            fontWeight: 'normal',
          }}
        />
      ),
      compareTo: (a: any, b: any) => {
        if (!a || !b) return 0;
        if (a.dateFiled < b.dateFiled) return -1;

        if (a.dateFiled > b.dateFiled) return 1;

        return 0;
      },
    },
  ];

  return (
    <ExpandableGrid
      dataTestIdPrefix="propects-courtCases"
      isLoading={props.isLoading}
      data={props.courtCases}
      getDataIdentifier={(dataItem: CourtCaseType) => `${dataItem.caseName}`}
      columns={columns}
      rowCollapser={(
        dataItem: any,
        isRowOpen: boolean,
      ) => (
        <RowCollapser
          dataItem={dataItem}
          isRowOpen={isRowOpen}
        />
      )}
    />
  );
};

export default CourtCasesTable;
