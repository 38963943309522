import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import {
  StyledFlexColumn,
  StyledRowTitle,
  StyledCaseName,
  StyledArrowIcon,
  StyledGotoIcon,
  StyledLink,
} from './style';

interface ICellRendererProps {
  item: CourtCaseType;
  setRowOpen: (value: boolean) => void;
  isRowOpen: boolean;
  setSelectedCourtCaseId: (id: string | null) => void;
  fieldName: string;
  styleProps: {
    width: string;
    padding: string;
    textAlign: string;
    textColor: string;
    fontSize: string;
    fontWeight: string;
  }
}

const CellRenderer: React.FC<ICellRendererProps> = (props: ICellRendererProps) => {
  const toggleOpenHandler = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (props.isRowOpen) return props.setRowOpen(false);

    return props.setRowOpen(true);
  };

  // eslint-disable-next-line
  const expandArrowTestId = `propects-courtCases-expandablerGrid-arrow-${props.item.caseName}`;

  const firmsValue = (props.item.firm || []).join(', ');

  const handleCaseNameClick = () => {
    props.setSelectedCourtCaseId(props.item.caseName);
  };

  const displayCell = () => {
    switch (props.fieldName) {
      case 'caseName':
        return (
          <StyledFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            padding={props.styleProps.padding}
          >
            <StyledCaseName
              textAlign={props.styleProps.textAlign}
              textColor={props.styleProps.textColor}
              fontSize={props.styleProps.fontSize}
              fontWeight={props.styleProps.fontWeight}
              fontFamily="Cairo"
              onClick={handleCaseNameClick}
            >
              {props.item[props.fieldName]}
            </StyledCaseName>
            <StyledArrowIcon
              $isOpen={props.isRowOpen}
              onClick={toggleOpenHandler}
              data-testid={expandArrowTestId}
            />
            <Tooltip title="Go to Court Listener" placement="top" arrow>
              <StyledLink href={`https://www.courtlistener.com${props.item?.absolute_url || ''}`} target="_blank">
                <StyledGotoIcon />
              </StyledLink>
            </Tooltip>
          </StyledFlexColumn>
        );

        break;

      case 'firm':
        return (
          <StyledFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            padding={props.styleProps.padding}
          >
            <StyledRowTitle
              textAlign={props.styleProps.textAlign}
              textColor={props.styleProps.textColor}
              fontSize={props.styleProps.fontSize}
              fontWeight={props.styleProps.fontWeight}
              fontFamily="Cairo"
            >
              {firmsValue}
            </StyledRowTitle>
          </StyledFlexColumn>
        );

        break;

      case 'court':
        return (
          <StyledFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            padding={props.styleProps.padding}
          >
            <StyledRowTitle
              textAlign={props.styleProps.textAlign}
              textColor={props.styleProps.textColor}
              fontSize={props.styleProps.fontSize}
              fontWeight={props.styleProps.fontWeight}
              fontFamily="Cairo"
            >
              {firmsValue}
            </StyledRowTitle>
          </StyledFlexColumn>
        );

        break;

      case 'dateFiled':
        return (
          <StyledFlexColumn
            key={props.fieldName}
            width={props.styleProps.width}
            padding={props.styleProps.padding}
          >
            <StyledRowTitle
              textAlign={props.styleProps.textAlign}
              textColor={props.styleProps.textColor}
              fontSize={props.styleProps.fontSize}
              fontWeight={props.styleProps.fontWeight}
              fontFamily="Cairo"
            >
              {props.item[props.fieldName]?.substring(0, 10) || '-'}
            </StyledRowTitle>
          </StyledFlexColumn>
        );

        break;

      default:

        return null;
    }
  };

  return (
    <>
      {displayCell()}
    </>
  );
};

export default CellRenderer;
