import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

import {
  StyledContainer,
  StyledFormControl,
  StyledDecorativeIcon,
  useStyles,
  StyledMenuItemContent,
} from './style';

import * as secCompanyFeedsUtils from '../../../../../entities/secCompanyFeed/utils';

interface IProps {
  selectedValue: EnumFilingTypeOption;
  handleChange: (value: EnumFilingTypeOption) => void;
  disabled: boolean;
  secCompanyFeeds: SecCompanyFeedType[];
}

enum EnumFilingTypeOption { PRIMARY, EXHIBIT, ALL }

const FilingTypeSelect: React.FC<IProps> = (props: IProps) => {
  const classes: any = useStyles(props);

  const menuProps: any = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const filingsList = secCompanyFeedsUtils.getFilingsFromSecCompanyFeeds(props.secCompanyFeeds);

  const getPrimary = () => {
    const primaryFilingsType = filingsList.filter((item: DisplayFilingType) => item.filingType === 'primary');

    return primaryFilingsType.length;
  };

  const getExhibit = () => {
    const exhibitFilingsType = filingsList.filter((item: DisplayFilingType) => item.filingType === 'exhibit');

    return exhibitFilingsType.length;
  };

  const statusMap = new Map([
    [EnumFilingTypeOption.PRIMARY, 'Primary'],
    [EnumFilingTypeOption.EXHIBIT, 'Exhibit'],
    [EnumFilingTypeOption.ALL, 'All'],
  ]);

  return (
    <StyledContainer>
      <StyledFormControl>
        <Select
          disableUnderline
          data-testid="prospects-secFilings-searchBar-filingTypeSelect"
          value={props.selectedValue}
          onChange={(e: any) => props.handleChange(e.target.value)}
          classes={{ root: classes.select, icon: classes.icon }}
          MenuProps={menuProps}
          disabled={props.disabled}
          renderValue={(value: any) => {
            if (value === EnumFilingTypeOption.ALL) return <span>All Types</span>;

            return <span>{statusMap.get(value)}</span>;
          }}
        >
          <MenuItem value={EnumFilingTypeOption.PRIMARY}>
            <StyledMenuItemContent
              $title="Primary"
              $count={getPrimary()}
              $backColor="white"
              $dataTestId="prospects-secFilings-searchBar-filingTypeSelect-primary"
            />
          </MenuItem>
          <MenuItem value={EnumFilingTypeOption.EXHIBIT}>
            <StyledMenuItemContent
              $title="Exhibit"
              $count={getExhibit()}
              $backColor="white"
              $dataTestId="prospects-secFilings-searchBar-filingTypeSelect-exhibit"
            />
          </MenuItem>
          <MenuItem
            value={EnumFilingTypeOption.ALL}
          >
            <StyledMenuItemContent
              $title="All Types"
              $count={filingsList.length}
              $backColor="white"
              $dataTestId="prospects-secFilings-searchBar-filingTypeSelect-allTypes"
            />
          </MenuItem>
        </Select>
      </StyledFormControl>
      <StyledDecorativeIcon $disabled={props.disabled} />
    </StyledContainer>
  );
};

export default FilingTypeSelect;
