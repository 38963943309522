import React, { useContext } from 'react';

import {
  StyledContainer,
  StyledInnerContainer,
  StyledTitle,
  StyledFilingLink,
} from './style';

import { SidebarsContext, SidebarsContextType } from '../../../../../../../context/sidebarsContext';

interface IProps {
  filingStatus: FilingStatusType;
  filings: DisplayFilingType[];
  selectedTrackedCase: FilingStatusType | null;
  setSelectedTrackedCase: (filingStatus: FilingStatusType | null) => void;
}

const TrackedCasesItem: React.FC<IProps> = (props: IProps) => {
  const sidebarsState = useContext<SidebarsContextType>(SidebarsContext);

  const getCompanyName = () => {
    const filing = props.filings.find((item: DisplayFilingType) => item.companyId === props.filingStatus.companyId);

    if (!filing) return '-';
    return filing.company_display_name;
  };

  const getFilingTitle = () => {
    const filing = props.filings.find((item: DisplayFilingType) => {
      return (item.companyId === props.filingStatus.companyId) && (item.filingId === props.filingStatus.filingId);
    });

    if (!filing) return '';

    return filing?.filingForm;
  };

  const isSelected = () => {
    if (!props.selectedTrackedCase) return false;

    if (
      (props.selectedTrackedCase.companyId === props.filingStatus.companyId)
      && (props.selectedTrackedCase.filingId === props.filingStatus.filingId)
    ) {
      return true;
    }

    return false;
  };

  const handleContainerClick = () => {
    props.setSelectedTrackedCase(props.filingStatus);
    sidebarsState.setIsOpenLeftSidebar(false);
  };

  const getFilingUrl = () => {
    const filing = props.filings.find((item: DisplayFilingType) => {
      return (item.companyId === props.filingStatus.companyId) && (item.filingId === props.filingStatus.filingId);
    });

    if (!filing) return '';

    return filing?.filingUrl;
  };

  return (
    <StyledContainer onClick={handleContainerClick} $isSelected={isSelected()}>
      <StyledInnerContainer>
        <StyledTitle>{getCompanyName()}</StyledTitle>
        <StyledFilingLink href={getFilingUrl()} target="_blank">{getFilingTitle()}</StyledFilingLink>
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default TrackedCasesItem;
