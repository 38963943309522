import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const StyledHeaderContainer = styled.div`
  grid-area: header;
  height: fit-content;
  max-height: 120px;
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
`;

export const StyledBasicInfo = styled.div`
  padding: 20px;
  display: flex;
  min-width: 100px;
  max-width: 350px;
  justify-content: flex-start;
  align-itmes: center;
  cursor: pointer;
  gap: 16px;

  @media(max-width: 1600px) {
    min-width: 70px;
  }
`;

export const StyledProjectName = styled.h3`
margin: 0;
padding: 0;
color: white;
font-size: 0.9rem;
font-family: Cairo, Verdana, Geneva, Tahoma, sans-serif;
line-height: 1.2rem;
font-weight: bold;

&::selection {
  background-color: #E5E5E5;
  color: #668793;
}

  @media(max-width: 1600px) {
    font-size: 0.8rem;
  }
`;

export const StyledProjectCode = styled.h5`
margin: 0;
padding: 0;
color: white;
font-weight: normal;
font-size: 0.9rem;
line-height: 1.2rem;
&::selection {
  background-color: #E5E5E5;
  color: #668793;
}

  @media(max-width: 1600px) {
    font-size: 0.8rem;
  }
`;

export const StyledBasicInfoContainer = styled.div`

  flex: 1 1 0;  
  min-width: 405px;
  background-color: #304F5A;  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  
`;

export const StyledDivider = styled(Divider)`
  && {
    border: solid 1px white;
    height: 1rem;
  }
`;

export const StyledArrow = styled(ArrowForwardIosIcon)`

  && {
    font-size: 14px;
    color: white;
  }

`;
