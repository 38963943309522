import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

import {
  StyledContainer,
  StyledFormControl,
  StyledDecorativeIcon,
  useStyles,
  StyledMenuItemContent,
} from './style';

import * as secCompanyFeedsUtils from '../../../../../entities/secCompanyFeed/utils';

interface IProps {
  selectedValue: EnumStatusOption;
  handleChange: (value: EnumStatusOption) => void;
  disabled: boolean;
  secCompanyFeeds: SecCompanyFeedType[];
  filingStatuses: FilingStatusType[];
}

enum EnumStatusOption { TRACKED, REJECTED, NONE, ALL }

const StatusSelect: React.FC<IProps> = (props: IProps) => {
  const classes: any = useStyles(props);

  const menuProps: any = {
    classes: {
      paper: classes.paper,
      list: classes.list,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };

  const filingsList = secCompanyFeedsUtils.getFilingsFromSecCompanyFeeds(props.secCompanyFeeds);

  const trackedFilingStatuses = props.filingStatuses.filter((item: FilingStatusType) => item.status === 'TRACKED');
  const rejectedFilingStatuses = props.filingStatuses.filter((item: FilingStatusType) => item.status === 'REJECTED');
  const noneFilingStatuses = props.filingStatuses.filter((item: FilingStatusType) => item.status === 'RAW');

  const statusMap = new Map([
    [EnumStatusOption.TRACKED, 'Tracked'],
    [EnumStatusOption.REJECTED, 'Rejected'],
    [EnumStatusOption.NONE, 'None'],
    [EnumStatusOption.ALL, 'All'],
  ]);

  return (
    <StyledContainer>
      <StyledFormControl>
        <Select
          disableUnderline
          data-testid="prospects-secFilings-searchBar-statusSelect"
          value={props.selectedValue}
          onChange={(e: any) => props.handleChange(e.target.value)}
          classes={{ root: classes.select, icon: classes.icon }}
          MenuProps={menuProps}
          disabled={props.disabled}
          renderValue={(value: any) => {
            if (value === EnumStatusOption.ALL) return <span>All Statuses</span>;

            return <span>{statusMap.get(value)}</span>;
          }}
        >
          <MenuItem value={EnumStatusOption.TRACKED}>
            <StyledMenuItemContent
              $title="Tracked"
              $count={trackedFilingStatuses.length}
              $backColor="#F7FFED"
              $dataTestId="prospects-secFilings-searchBar-statusSelect-trackedStatus"
            />
          </MenuItem>
          <MenuItem value={EnumStatusOption.REJECTED}>
            <StyledMenuItemContent
              $title="Rejected"
              $count={rejectedFilingStatuses.length}
              $backColor="#F0F0F0"
              $dataTestId="prospects-secFilings-searchBar-statusSelect-rejectedStatus"
            />
          </MenuItem>
          <MenuItem value={EnumStatusOption.NONE}>
            <StyledMenuItemContent
              $title="None"
              $count={noneFilingStatuses.length}
              $backColor="white"
              $dataTestId="prospects-secFilings-searchBar-statusSelect-noneStatus"
            />
          </MenuItem>
          <MenuItem
            value={EnumStatusOption.ALL}
          >
            <StyledMenuItemContent
              $title="All Statuses"
              $count={filingsList.length}
              $backColor="white"
              $dataTestId="prospects-secFilings-searchBar-statusSelect-allStatus"
            />
          </MenuItem>
        </Select>
      </StyledFormControl>
      <StyledDecorativeIcon $disabled={props.disabled} />
    </StyledContainer>
  );
};

export default StatusSelect;
