import React from 'react';

import {
  StyledContainer,
} from './style';

import {
  StyledLoadingSpinner,
} from '../style';

import GridItem from '../item';

interface IProps {
  data: any[];
  dataTestIdPrefix: string;
  columns: {
    headerProps: {
      label: string;
      width: string;
    };
    cellRenderer: (
      item: any,
      setRowOpen: (value: boolean) => void,
      isRowOpen: boolean,
    ) => JSX.Element;
    compareTo: (a: any, b: any) => void;
  }[];
  rowCollapser?: (
    item: any,
    isRowOpen: boolean
  ) => JSX.Element;
  getDataIdentifier: (dataItem: any) => string;
  isLoading: boolean;
}

const GridList: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer isLoading={props.isLoading}>
      {props.isLoading && <StyledLoadingSpinner />}
      {
        props.data.map((dataItem: any) => {
          return (
            <GridItem
              key={props.getDataIdentifier(dataItem)}
              dataItem={dataItem}
              columns={props.columns}
              rowCollapser={props.rowCollapser}
              dataTestIdPrefix={props.dataTestIdPrefix}
              getDataIdentifier={props.getDataIdentifier}
            />
          );
        })
      }
    </StyledContainer>
  );
};

export default GridList;
