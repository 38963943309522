import React from 'react';

import {
  StyledContainer,
} from './style';

import TrackedCasesItem from './trackedCaseItem';

interface IProps {
  filings: DisplayFilingType[];
  sortedFilingStatuses: FilingStatusType[];
  selectedTrackedCase: FilingStatusType | null;
  setSelectedTrackedCase: (filingStatus: FilingStatusType | null) => void;
}

const TrackedCasesList: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledContainer>
      {props.sortedFilingStatuses.map((filingStatus: FilingStatusType) => {
        return (
          <TrackedCasesItem
            key={`${filingStatus.companyId}-${filingStatus.filingId}`}
            filingStatus={filingStatus}
            filings={props.filings}
            selectedTrackedCase={props.selectedTrackedCase}
            setSelectedTrackedCase={props.setSelectedTrackedCase}
          />
        );
      })}
    </StyledContainer>
  );
};

export default TrackedCasesList;
