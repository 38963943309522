import React from 'react';

import {
  StyledContainer,
  StyledCourtCaseInfoContainer,
} from './style';

import CourtCaseHeader from './courtCaseHeader';

import CourtCaseBody from './courtCaseBody';

import CourtCaseFooter from './courtCaseFooter';

interface IProps {
  courtCase: CourtCaseType | null;
}

const CourtCaseView: React.FC<IProps> = (props: IProps) => {
  const displayCourtCaseInfo = () => {
    return (
      <StyledCourtCaseInfoContainer>
        <CourtCaseHeader
          caseName={props.courtCase?.caseName || 'N/A'}
        />
        <CourtCaseBody courtCase={props.courtCase} />
        <CourtCaseFooter />
      </StyledCourtCaseInfoContainer>
    );
  };

  return (
    <StyledContainer>
      {displayCourtCaseInfo()}
    </StyledContainer>
  );
};

export default CourtCaseView;
