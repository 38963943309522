import React from 'react';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Button from '@material-ui/core/Button';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const StyledContainer = styled.div`
  grid-area: header; 
  height: fit-content;   
  max-height: 100px;
  padding: 10px 0px;  
  display: flex;
  justify-content: flex-start;         
`;

const StyledCaseNameContainer = styled.div`

  flex: 2 2 45%;    
  background-color: #9F9F9F;
  margin-right: 3px;  
  padding: 10px 5px 10px 15px;
`;

const StyledCaseNameLabel = styled.h6`

  padding: 0;
  margin: 0;
  width: 100%;
  color: white;
  font-size: 8px;
  font-weight: normal;
  font-family: Cairo;
  text-align: left;
`;

const StyledCaseNameDetail = styled.h4`
  padding: 0;
  margin: 0;
  width: 100%;
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: Cairo;
  text-align: left;
  line-height: 16px;
`;

interface ICaseNameProps {
  caseName: string | null;
}

export const StyledCaseName: React.FC<ICaseNameProps> = (props: ICaseNameProps) => {
  return (
    <StyledCaseNameContainer>
      <StyledCaseNameLabel>Court Case Name:</StyledCaseNameLabel>
      <StyledCaseNameDetail>{props.caseName || 'N/A'}</StyledCaseNameDetail>
    </StyledCaseNameContainer>
  );
};

const StyledProjectCodeContainer = styled.div`

  flex: .8 .8 120px;  
  background-color: #9F9F9F;
  margin-right: 3px; 
  padding: 10px 0 10px 15px; 
  position: relative;
`;

const StyledProjectCodeLabel = styled.h6`

  padding: 0;
  margin: 0;
  width: 100%;
  color: white;
  font-size: 8px;
  font-weight: normal;
  font-family: Cairo;
  text-align: left;
`;

const StyledProjectCodeDetail = styled.h4`
  padding: 0;
  margin: 0;
  width: 100%;
  color: white;
  font-size: 12px;
  font-weight: bold;
  font-family: Cairo;
  text-align: left;
  line-height: 16px;
`;

const StyledProjectEditIcon = styled(EditIcon)`

  && {
    color: white;
    position: absolute;
    top: 50%;
    margin-top: -9px;
    right: 10px;  
    font-size: 18px;  
  }

  &:hover {
    cursor: pointer;
  }

`;

interface IProjectCodeProps {
  projectCode: string | null;
}

export const StyledProjectCode: React.FC<IProjectCodeProps> = (props: IProjectCodeProps) => {
  return (
    <StyledProjectCodeContainer>
      <StyledProjectCodeLabel>Project Code:</StyledProjectCodeLabel>
      <StyledProjectCodeDetail>{props.projectCode || 'N/A'}</StyledProjectCodeDetail>
      <StyledProjectEditIcon />
    </StyledProjectCodeContainer>
  );
};

const StyledWarningContainer = styled.div`

  flex: 0 0 280px;  
  background-color:  #FFCACA;
  margin-right: 15px; 
  padding: 10px 10px 10px 10px;   
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledWarningIcon = styled(ReportProblemOutlinedIcon)`

  && {
    flex: 0 0 20px;
    color: black;        
    font-size: 24px;  
  }
`;

const StyledWarningDetail = styled.h4`
  flex: 0 0 120px;
  padding: 0;
  margin: 0;  
  color: black;
  font-size: 10px;
  font-weight: normal;
  font-family: Cairo;
  text-align: left;
  line-height: 14px;
`;

const StyledSalesForceButton = styled(Button)`

  && {
    flex: 0 0 100px;
    background-color: #00A1E0;
    padding: 5px 0;
    font-size: 10px;
    font-weight: normal;
    font-family: Cairo;
    color: white;
    text-transform: none;
  }
`;

export const StyledWarning: React.FC = () => {
  return (
    <StyledWarningContainer>
      <StyledWarningIcon />
      <StyledWarningDetail>
        Convert this prospect into a SalesForce Opportunity and update the project code
      </StyledWarningDetail>
      <StyledSalesForceButton>Go to salesForce</StyledSalesForceButton>
    </StyledWarningContainer>
  );
};

export const StyledInformationIcon = styled(InfoOutlinedIcon)`

  && {
    flex: 0 0 30px;
    color: #757575;        
    font-size: 30px;  
    margin-right: 10px;
    align-self: center;
  }
`;
