import React, { useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import _ from 'lodash';

import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';

import { ProjectContext, ProjectContextType } from '../../context/projectContext';
import { ExpertContext, ExpertContextType } from '../../context/expertContext';
import { SidebarsContext, SidebarsContextType } from '../../context/sidebarsContext';
import { EditedExpertSearchContext, EditedExpertSearchContextType } from '../../context/editedExpertSearch';

import * as ExpertFavoriteService from '../../entities/expertFavorite/service';
import { fetchExpertSearchResultsBySearch } from '../../entities/expertSearchResult/service';
import * as expertSearchService from '../../entities/expertSearch/service';

import ExpertSearchCard from './expertSearchCard';
import Alert from '../../components/alert';

import {
  StyledContainer,
  StyledGuideContainer,
  StyledHeading,
  StyledSubheading,
  StyledNoResults,
} from './style';

interface ExpertSearchByProjectProps {
  project: ProjectType | undefined;
  expertSearches: ExpertSearchType[];
}

const Experts = () => {
  const client = useApolloClient();

  const expertState = useContext<ExpertContextType>(ExpertContext);
  const projectState = useContext<ProjectContextType>(ProjectContext);
  const sidebarsState = useContext<SidebarsContextType>(SidebarsContext);
  const editedExpertSearchState = useContext<EditedExpertSearchContextType>(EditedExpertSearchContext);

  const [expertSearches, setExpertSearches] = React.useState<ExpertSearchType[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleShowError = (message: string) => {
    setErrorMessage(message);

    setTimeout(() => {
      setErrorMessage(null);
    }, 2000);
  };

  useEffect(() => {
    const fetchFavorites = async () => {
      const expertFavorites = await ExpertFavoriteService.fetchExpertsFavorites();

      expertState.setExperts(expertFavorites.experts);
      expertState.setExpertFavorites(expertFavorites.favorites);
    };

    const fetchAllExpertSearches = async () => {
      const allExpertSearches = await expertSearchService.fetchAllExpertSearches(client);
      setExpertSearches(allExpertSearches);
    };

    fetchFavorites();
    fetchAllExpertSearches();
  }, []);

  const groupExpertSearchesByProject = (): ExpertSearchByProjectProps[] => {
    const searches = _.chain(expertSearches)
      .groupBy('projectId')
      .map((value, key) => ({
        project: _.find(projectState.projects, ['_id', key]),
        expertSearches: value,
      }))
      .value()
      .filter((s) => !!s.project);
    return searches;
  };

  const expertSearchByProject: ExpertSearchByProjectProps[] = groupExpertSearchesByProject();

  const handleProjectClick = (projectId: string | undefined) => {
    if (!projectId) {
      return;
    }

    projectState.setSelectedProjectId(projectId);

    try {
      sidebarsState.setIsOpenRightSidebar(false);
    } catch (e) {
      handleShowError(`ERROR: ${e.message}`);
    }
  };

  const handleExpertSearchClick = async (expertSearchId: string) => {
    try {
      const searchResults = await fetchExpertSearchResultsBySearch(expertSearchId);

      expertState.setExperts(searchResults.experts);

      projectState.setSelectedExpertSearchResults(searchResults.results);

      editedExpertSearchState.setEditedExpertSearch(null);

      projectState.setSelectedExpertSearchId(expertSearchId);
    } catch (e) {
      handleShowError(`ERROR: ${e.message}`);
    }
  };

  return (
    <StyledContainer>
      <StyledGuideContainer>
        <StyledHeading variant="h3">Welcome to Grid</StyledHeading>
        <Grid container>
          <Grid item xs>
            <StyledSubheading variant="h4" gutterBottom>Recent Searches</StyledSubheading>
            <Grid container spacing={4}>
              {expertSearchByProject?.length
                ? expertSearchByProject.map((expertSearch) => (
                  <Grid item xs={12} sm={6} key={expertSearch.project?._id}>
                    <ExpertSearchCard
                      project={expertSearch.project}
                      onProjectClick={handleProjectClick}
                      expertSearches={expertSearch.expertSearches}
                      onExpertSearchClick={handleExpertSearchClick}
                    />
                  </Grid>
                ))
                : (
                  <Grid item xs>
                    <StyledNoResults paragraph>No recent searches found!</StyledNoResults>
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
      </StyledGuideContainer>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ transform: 'translateY(70px)', zIndex: 9999999999 }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default Experts;
