import React from 'react';

import {
  StyledFlexDocketNumberSection,
  StyledSectionCol,
  StyledSectionTitle,
  inputStyles,
} from '../style';

import CustomInput from '../../../../../../components/customInput';

interface IProps {
  jurisdictionType: string;
}

const SectionJurisdictionType: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledFlexDocketNumberSection>
      <StyledSectionCol width="100%">
        <StyledSectionTitle>Jurisdiction Type</StyledSectionTitle>
        <CustomInput
          defaultValue={props.jurisdictionType || ''}
          disabled
          styles={inputStyles}
        />
      </StyledSectionCol>
    </StyledFlexDocketNumberSection>
  );
};

export default SectionJurisdictionType;
