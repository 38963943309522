import styled from 'styled-components';

export const StyledContainer = styled.div`
  
  width: 100%;
  padding: 20px 0 30px 0; 
  display: flex;
  flex-flow: row nowrap;  
  justify-content: space-between;  
  align-items: center;
  height: auto;   
  
`;

export const StyledLeftPanelContainer = styled.div`

  flex: 0 1 350px;
  width: auto;
  margin-bottom: 0; 
   
`;

export const StyledRightPanelContainer = styled.div`

  flex: 1 1 0;
  width: auto;  
  max-width: 600px;  
  display: flex;
  justify-content: flex-end;
  align-items: center;  
  
`;
