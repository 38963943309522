import React from 'react';
import {
  ManagerInfo,
  InfoField,
  SideHeading,
  ExpertiseWrap,
  ExpertiseItem,
  Div,
} from './style';

interface IProps {
  selectedExpert: ExpertType;
}

const ManagerInformation = (props: IProps) => {
  return (
    <ManagerInfo>
      <InfoField>
        <SideHeading>Expertise</SideHeading>
        <ExpertiseWrap>
          {props.selectedExpert.expertises.length > 0 ? props.selectedExpert.expertises.map((expertise:string) => {
            return (
              <ExpertiseItem>
                {' '}
                {expertise}
              </ExpertiseItem>
            );
          }) : <ExpertiseItem> - </ExpertiseItem>}
        </ExpertiseWrap>
      </InfoField>
      <InfoField>
        <SideHeading>Relationship Manager (ETA)</SideHeading>
        <Div>
          <p>{props.selectedExpert.relationship_manager_eta || '-'}</p>
        </Div>
      </InfoField>
      <InfoField>
        <SideHeading>Relationship Manager (Strategy)</SideHeading>
        <Div>
          <p>{props.selectedExpert.relationship_manager_strategy || '-'}</p>
        </Div>
      </InfoField>
      <InfoField>
        <SideHeading>Supporting Relationship Manager</SideHeading>
        <Div>
          <p>{props.selectedExpert.relationship_manager_support || '-'}</p>
        </Div>
      </InfoField>
      <InfoField>
        <SideHeading>Location</SideHeading>
        <Div>
          <p>{props.selectedExpert.location || '-'}</p>
        </Div>
      </InfoField>
      <InfoField>
        <SideHeading>Rate</SideHeading>
        <Div>
          <p>{props.selectedExpert.rate ? `$ ${props.selectedExpert.rate} USD` : '-'}</p>
        </Div>
      </InfoField>
    </ManagerInfo>
  );
};

export default ManagerInformation;
