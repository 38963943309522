import styled from 'styled-components';

export const LabelWrap = styled.div`
    height: 25px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: 5px;

    img{
        width: 12px;
        height: 12px;
        margin: 0px 5px;
    }

    p{
        color: #304F5A;
        font-size: 12px;
        font-weight: bold;

        a{
            color: #304F5A;
        }
    }

`;
