import React from 'react';

import {
  StyledAttorneySection,
  StyledSectionTitle,
  StyledAttorneyList,
  StyledAttorneyItemContainer,
  StyledAttorneyItemSpan,
} from '../style';

interface IProps {
  attorney: string[];
}

const SectionAttorney: React.FC<IProps> = (props: IProps) => {
  return (
    <StyledAttorneySection>
      <StyledSectionTitle>Attorney</StyledSectionTitle>
      <StyledAttorneyList>
        {props.attorney.map((item: string) => {
          return (
            <StyledAttorneyItemContainer key={item}>
              <StyledAttorneyItemSpan>{item}</StyledAttorneyItemSpan>
            </StyledAttorneyItemContainer>
          );
        })}
      </StyledAttorneyList>
    </StyledAttorneySection>
  );
};

export default SectionAttorney;
