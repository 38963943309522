import React, { useState } from 'react';
import Fuse from 'fuse.js';

import {
  StyledContainer,
  StyledResultsCount,
} from './style';

import SearchBar from './searchBar';

import CourtCasesTable from './courtCasesTable';

enum EnumPeriodOption { LAST24H, LAST7D, LAST14D, LAST30D, LAST90D }

interface IProps {
  courtCases: CourtCaseType[];
  isLoadingCourtCases: boolean;
  setSelectedCourtCaseId: (id: string | null) => void;
}

const Patents: React.FC<IProps> = (props: IProps) => {
  const [filterByCaseName, setFilterByCaseName] = useState<string>('');
  const [selectedPeriod, setSelectedPeriod] = useState<EnumPeriodOption>(EnumPeriodOption.LAST30D);

  const onChangeSelectedPeriod = async (newSelectedPeriod: EnumPeriodOption) => {
    setSelectedPeriod(newSelectedPeriod);
  };

  const compareRows = (a: CourtCaseType, b: CourtCaseType) => {
    if (a.caseName < b.caseName) return -1;
    if (a.caseName > b.caseName) return 1;
    return 0;
  };

  const filterCourtCasesGlobally = (courtCases: CourtCaseType[]) => {
    if (!filterByCaseName) return courtCases;

    const fuseOptions = {
      keys: [
        'caseName',
        'firm',
        'court',
        'dateFiled',
      ],
      includeScore: true,
      shouldSort: true,
      threshold: 0,
    };
    const fuse = new Fuse(courtCases, fuseOptions);

    const fuseResults = fuse.search(filterByCaseName);

    const filingResults = fuseResults.map((fuseResult: any) => {
      return fuseResult.item;
    });

    return filingResults;
  };

  const getCompareToDate = () => {
    const now = new Date();
    if (selectedPeriod === EnumPeriodOption.LAST24H) return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 1);
    if (selectedPeriod === EnumPeriodOption.LAST7D) return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 7);
    if (selectedPeriod === EnumPeriodOption.LAST14D) return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 14);
    if (selectedPeriod === EnumPeriodOption.LAST30D) return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 30);
    if (selectedPeriod === EnumPeriodOption.LAST90D) return new Date(now.getTime() - 1000 * 60 * 60 * 24 * 90);
    return now;
  };

  const compareToDate = getCompareToDate();
  compareToDate.setHours(0, 0, 0, 0);

  const filteredCourtCases = props.courtCases.filter((c) => {
    if (!c.dateFiled) return true;
    return new Date(c.dateFiled) > compareToDate;
  });
  const filteredByStringSearchCourtCases = [...filterCourtCasesGlobally(filteredCourtCases)].sort(compareRows);

  return (
    <StyledContainer>
      <SearchBar
        selectedPeriod={selectedPeriod}
        onChangeSelectedPeriod={onChangeSelectedPeriod}
        filterByCaseName={filterByCaseName}
        setFilterByCaseName={setFilterByCaseName}
      />
      <StyledResultsCount
        data-testid="prospects-courtCases-resultsCount"
      >
        {`Results Found: ${props.isLoadingCourtCases ? '-' : filteredByStringSearchCourtCases.length}`}
      </StyledResultsCount>
      <CourtCasesTable
        courtCases={filteredByStringSearchCourtCases}
        isLoading={props.isLoadingCourtCases}
        setSelectedCourtCaseId={props.setSelectedCourtCaseId}
      />
    </StyledContainer>
  );
};

export default Patents;
