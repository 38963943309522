/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import useStyles from './style';

interface ModalWrapperProps {
  isModalOpen: boolean,
  handleModal: () => void,
  children: React.ReactNode,
}

const ModalWrapper = (props: ModalWrapperProps) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={props.isModalOpen}
      onClose={props.handleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.isModalOpen}>
        <div className={classes.paper}>
          {props.children}
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalWrapper;
