import React, { useRef, useContext } from 'react';
import _ from 'lodash';
import {
  StyledContainer,
  StyledInnerContainer,
} from './style';

import ExpertsList from './expertsList';

import { ProjectContext, ProjectContextType } from '../../../../../context/projectContext';

import * as expertShortListedService from '../../../../../entities/expertShortlisted/service';
import * as dateFunctions from '../../../../../utils/dateFunctions';
import * as stringFunctions from '../../../../../utils/stringFunctions';
import HeaderExperts from '../headerExperts';

interface IProps {
  isOpen: boolean;
  setExpertsShortlisted: (expertsShortlisted: ExpertShortlistedType[]) => void;
  shortlistedExpertTypes: ExpertTypeType[]
  expertsShortlisted: ExpertShortlistedType[]
  getExpert: (expertId: string) => ExpertType | null
}

const ShortlistedExperts: React.FC<IProps> = (props: IProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  const projectState = useContext<ProjectContextType>(ProjectContext);

  const shortlistedExpertTypes = props.shortlistedExpertTypes;

  const selectedProject = projectState.projects.find(
    (project: ProjectType) => project._id === projectState.selectedProjectId,
  );

  const projectName = selectedProject?.name || '';

  const projectCode = selectedProject?.projectCode || '';

  const handleUnshortListExpert = async (expertId: string, searchId: string) => {
    await expertShortListedService.removeExpertShortlisted(
      expertId,
      searchId,
    );

    const newExpertsShortlisted = await expertShortListedService.fetchExpertsShortlisted(
      projectState.selectedProjectId!,
    );

    props.setExpertsShortlisted(newExpertsShortlisted.shortlist);
  };

  const displayTypes = () => {
    const expertsShortlistedFilteredByExpertSearch = props.expertsShortlisted.filter(
      (x) => projectState.selectedExpertSearchId === x.searchId,
    ).map((expert) => {
      const order = projectState.selectedExpertSearchResults.find(
        (expertResult) => expertResult.expertId === expert.expertId,
      );

      return { ...expert, order: order?.order };
    });
      // const expertsCount = sortedExpertShortlisted.length;

    const sortedExpertsShortlisted = _.orderBy(expertsShortlistedFilteredByExpertSearch, ['order'], ['asc']);
    return (
      <ExpertsList
        expertsShortlisted={sortedExpertsShortlisted}
        getExpert={props.getExpert}
        handleUnshortListExpert={handleUnshortListExpert}
      />
    );
  };

  const createCSVContent = async () => {
    const expertsShortlistedFilteredByExpertSearch = props.expertsShortlisted.filter(
      (x) => projectState.selectedExpertSearchId === x.searchId,
    );
    const csvContent = expertsShortlistedFilteredByExpertSearch.reduce(
      (acc2: string, shortListedExpert: ExpertShortlistedType) => {
        const expert = props.getExpert(shortListedExpert.expertId);

        const expertName = expert
          ? stringFunctions.escapeSpecialChars(`${expert.firstName} ${expert.lastName}`)
          : '';

        const bioLink = expert?.bio_url
          ? stringFunctions.escapeSpecialChars(expert?.bio_url) : 'null';

        const projectNameCleaned = stringFunctions.escapeSpecialChars(projectName);

        // eslint-disable-next-line
          return `${acc2}${projectNameCleaned}\t${expertName}\t${bioLink}\n`;
      }, 'Project\tExpert Name\tBio Link\n',
    );
    return csvContent;
  };

  const handleDownloadButton = async () => {
    if (!linkRef.current) return;

    const csvContent = await createCSVContent();

    linkRef.current.href = `data:text/csv;charset=utf-8,${encodeURI(csvContent)}`;

    const formattedDate = dateFunctions.getFormattedDateStringYMD(new Date());

    linkRef.current.download = `grid_expert_shortlist_${projectCode}_${formattedDate}.csv`;

    linkRef.current.click();
  };

  return (
    <StyledContainer $isOpen={props.isOpen}>
      <HeaderExperts
        linkRef={linkRef}
        handleDownloadButton={handleDownloadButton}
        title="Shortlisted Experts"
        length={shortlistedExpertTypes.length}
      />
      <StyledInnerContainer>
        {
          shortlistedExpertTypes.length > 0 && displayTypes()
        }
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default ShortlistedExperts;
