import React from 'react';
import {
  LSN, UL, Border, ListWrap,
} from './style';

function SubjectMatterTags(props: any) {
  return (
    <ListWrap>
      <UL>
        {props.items.length > 0
          ? props.items.map((item: any) => (
            <LSN key={item}>
              <span>{item}</span>
              <Border />
            </LSN>
          )) : <LSN>-</LSN>}
      </UL>
    </ListWrap>
  );
}

export default SubjectMatterTags;
