import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ShareIcon from '@material-ui/icons/Share';
import LinkIcon from '@material-ui/icons/Link';

interface IProps {
  $isOpen: boolean;
}

export const StyledContainer = styled.div<IProps>`
  width: 100%;
  background-color: #E5E5E5;
  height: 100%;
  display: ${(props) => props.$isOpen ? 'block' : 'none'}; 
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const StyledContentTitle = styled.h2`
  color: #9F9F9F;
  font-size: 17px;
  font-weight: bold;
  font-family: Cairo; 
  text-align: center;
`;

export const StyledInnerContainer = styled.div`
  height: 90%;
  width: 90%;
  margin: 0 auto;
  padding-left: 20px;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-height: 702px) {
    max-height: 60%;
  }
  @media only screen and (min-height: 702px) {
    max-height: 70%;
  }
  @media only screen and (min-height: 1200px) {
    max-height: 80%;
  }
  @media only screen and (min-height: 1755px) {
    max-height: 85%;
  }
`;

export const StyledButton = styled(Button)`

  && {
    background-color: #8DC63F;
    color: white;        
    border-radius: 0;
    text-transform: none;
    font-size: 10px;
    font-family: Cairo;
    font-weight: bold;
    border-radius: 50%;
    min-width: 30px;
  }
`;

export const StyledDownloadIcon = styled(SaveAltIcon)`

&& {
    color: white;
  }

`;

export const StyledShareIcon = styled(ShareIcon)`

&& {
    color: white;
  }

`;

export const StyledLoading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledLoadingTitle = styled.h4`
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: bold;
  font-family: Cairo;
  color: black;  
`;

export const StyledTooltipContainer = styled.div`
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
`;

export const StyledTooltipSpan = styled.span`
  display: block;
  padding: 0;
  margin: 0;
  font-size: 10px;
  font-weight: normal;
  font-family: Cairo;
  color: white;
  padding: 5px 10px;
`;

export const StyledTooltipIcon = styled(LinkIcon)`
  && {
    color: white;
  }
`;

export const StyledDownloadLink = styled.a`
  text-decoration: none;
  visibility: hidden;
  width: 0;  
`;
