import React from 'react';

import {
  StyledContainer,
  StyledInnerContainer,
} from './style';

import ExpertsList from './expertsList';

import * as ExpertFavoriteService from '../../../../../entities/expertFavorite/service';
import HeaderExperts from '../headerExperts';

interface IProps {
  isOpen: boolean;
  expertFavorites: ExpertFavoriteType[];
  setExpertFavorites: (expertFavorites: ExpertFavoriteType[]) => void;
  getExpert: (expertId: string) => ExpertType | null;
}

const FavoriteExperts: React.FC<IProps> = (props: IProps) => {
  const handleRemoveFavoriteExpert = async (expertId: string) => {
    await ExpertFavoriteService.removeExpertFavorite(
      expertId,
    );

    const newFavoriteExperts = await ExpertFavoriteService.fetchExpertsFavorites();

    props.setExpertFavorites(newFavoriteExperts.favorites);
  };

  return (
    <StyledContainer $isOpen={props.isOpen}>
      <HeaderExperts
        title="Favorite Experts"
        length={props.expertFavorites.length}
      />
      <StyledInnerContainer>
        {
          props.expertFavorites.length > 0
          && (
          <ExpertsList
            expertFavorites={props.expertFavorites}
            getExpert={props.getExpert}
            handleRemoveFavoriteExpert={handleRemoveFavoriteExpert}
          />
          )
        }
      </StyledInnerContainer>
    </StyledContainer>
  );
};

export default FavoriteExperts;
