import styled from 'styled-components';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { darken } from 'polished';

interface IProps {
  $isOpen: boolean;
  disabled?: boolean;
  $selected?: boolean;
}

export const StyledContainer = styled.div<IProps>`
  width: 100%;
  background-color: #E5E5E5;
  height: 100%;
`;

export const StyledToolbar = styled.div<IProps>`
  width: ${(props) => props.$isOpen ? '100%' : '82px'};  
  display: flex;
  flex-flow: ${(props) => props.$isOpen ? 'row nowrap' : 'column nowrap'};
`;

const getBackground = (props: IProps) => {
  if (props.disabled) return darken(0.03, '#F0F0F0');

  if (props.$selected) return '#757575';

  return '#D7D7D7';
};

export const StyledButtonContainer = styled.span<IProps>`
  position: relative;
  flex: ${(props) => props.$isOpen ? '1 1 82px' : '0 0 82px'};
  display: flex;
  height: 82px;
  justify-content: center;
  align-items: center;
  background-color: ${(props: IProps) => getBackground(props)};
  margin-right: ${(props) => props.$isOpen ? '3px' : '0'};
  margin-bottom: ${(props) => props.$isOpen ? '0' : '3px'};
  transition: all .2s linear;

  &:hover {
    cursor: ${(props) => props.disabled ? 'default' : 'pointer'};
  }
`;

export const StyledStarIcon = styled(StarBorderIcon)<IIconProps>`

&& {
    font-size: 34px;
    color: ${(props: IIconProps) => getColor(props)};  
  }
`;

interface IIconProps {
  disabled? : boolean;
  $selected?: boolean;
}

const getColor = (props: IIconProps) => {
  if (props.disabled) return '#C7C7C7';

  if (props.$selected) return 'white';

  return '#757575';
};

export const StyledShortListIcon = styled(BookmarkBorderIcon)<IIconProps>`

  && {
    font-size: 34px;
    color: ${(props: IIconProps) => getColor(props)};  
  }
`;

interface IArrowIcon {
  $visible: boolean;
}

export const StyledArrowDown = styled.img`

  width: 9px;
  height: 9px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -4.5px;
  display: ${(props: IArrowIcon) => props.$visible ? 'block' : 'none'};
`;
