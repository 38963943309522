export const cleanExpertsShortlisted = (expertsShortlisted: any[]) => {
  const cleanedResults = expertsShortlisted.map((result: any) => {
    const {
      _id: id,
      searchId,
      expertId,
    } = result;

    return {
      _id: id,
      searchId,
      expertId,
    };
  });

  return cleanedResults;
};
