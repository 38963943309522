import React, { useState } from 'react';

export interface ExpertContextType {
  getExpert: (expertId: string) => ExpertType | null;
  setExperts: (expert: ExpertType[]) => void;
  expertsShortlisted: ExpertShortlistedType[];
  setExpertsShortlisted: (expertsShortlisted: ExpertShortlistedType[]) => void;
  expertFavorites: ExpertFavoriteType[];
  setExpertFavorites: (expertFavorites: ExpertFavoriteType[]) => void;
}

export const ExpertContext = React.createContext<ExpertContextType>({
  getExpert: () => null,
  setExperts: () => null,
  expertsShortlisted: [],
  setExpertsShortlisted: () => null,
  expertFavorites: [],
  setExpertFavorites: () => null,
});

interface IProps {
  children: React.ReactNode;
}

const ExpertContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [expertsMap, setExpertsMap] = useState<Map<string, ExpertType>>(new Map<string, ExpertType>());
  const [expertsShortlisted, setExpertsShortlisted] = useState<ExpertShortlistedType[]>([]);
  const [expertFavorites, setExpertFavorites] = useState<ExpertFavoriteType[]>([]);

  const getExpert = (expertId: string) => {
    if (!expertsMap.has(expertId)) return null;

    return expertsMap.get(expertId) as ExpertType;
  };

  const setExperts = (experts: ExpertType[]) => {
    experts.forEach((expert: ExpertType) => {
      expertsMap.set(expert._id, expert);
    });
    setExpertsMap(new Map(expertsMap));
  };

  const contextValue = {
    getExpert,
    setExperts,
    expertsShortlisted,
    setExpertsShortlisted,
    expertFavorites,
    setExpertFavorites,
  };

  return (
    <ExpertContext.Provider value={contextValue}>
      {children}
    </ExpertContext.Provider>
  );
};

export default ExpertContextProvider;
