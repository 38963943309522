import styled from 'styled-components';

export const StyledContainer = styled.ul`

  padding: 0;
  margin: 0;
  margin-left: 10px;
  list-style-type: none;
  width: 80%;
  background-color: #E5E5E5;
`;

export const StyledLabelIcon = styled.img`

  && {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 12px;
    height: 15px;    
    margin-top: -6px;
  }
    
  &:hover {
    cursor: pointer;
  }

`;
