import React from 'react';

import {
  StyledHeaderContainer,
  StyledBasicInfo,
  StyledProjectName,
  StyledProjectCode,
  StyledBasicInfoContainer,
  StyledDivider,
  StyledArrow,
} from './style';

import ExpertType from './expertType';

interface ProjectHeaderProps {
  projectName: string | null;
  projectCode: string | null;
  isSelectedSearchEditable: boolean;
  isASearchSelected: boolean;
  selectedExpertSearches: ExpertSearchType[];
  selectedExpertSearchId: string | null;
  setSelectedExpertSearchId: (id: string | null) => void;
  setEditedExpertSearch: (expertSearch: ExpertSearchEditType | null) => void;
  updateSearch: (expertSearch: ExpertSearchType) => void;
}

const ProjectHeader: React.FC<ProjectHeaderProps> = (props: ProjectHeaderProps) => {
  const getSelectedExpertSearch : () => ExpertSearchType | null = () => {
    if (!props.selectedExpertSearchId) {
      return null;
    }
    const searchObject = props.selectedExpertSearches.find((search: ExpertSearchType) => {
      return search._id === props.selectedExpertSearchId;
    }) ?? null;
    return searchObject;
  };
  const expertSearch = getSelectedExpertSearch();

  const getProjectCode = (originalCode: string | null, projectName: string | null) => {
    if (!originalCode) return '- Empty -';
    if (projectName === '- Empty -') return originalCode;

    const nameIndex = originalCode.indexOf(projectName!);

    if (nameIndex === -1) return originalCode;

    return originalCode.slice(0, nameIndex);
  };

  const handleBasicInfoClick = () => {
    props.setEditedExpertSearch(null);
    props.setSelectedExpertSearchId(null);
  };

  return (
    <StyledHeaderContainer>
      <StyledBasicInfoContainer>
        <StyledBasicInfo
          onClick={handleBasicInfoClick}
        >
          <StyledProjectName>{props.projectName}</StyledProjectName>
          <StyledDivider />
          <StyledProjectCode>{getProjectCode(props.projectCode, props.projectName)}</StyledProjectCode>
        </StyledBasicInfo>
        {
          (props.isASearchSelected || props.isSelectedSearchEditable)
            ? (
              <>
                <StyledArrow />
                <ExpertType
                  isSelectedSearchEditable={props.isSelectedSearchEditable}
                  updateSearch={props.updateSearch}
                  expertSearch={expertSearch}
                />
              </>
            ) : null
        }
      </StyledBasicInfoContainer>
    </StyledHeaderContainer>
  );
};

export default ProjectHeader;
