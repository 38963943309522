import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';

interface NavProps{
  selected: boolean
}

export const StyledContainer = styled.div`

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;  
  background-color: #8DC63F; 
  height: 8vh;
  
`;

export const StyledAppBar = styled(AppBar)`

  && {
    background-color: #8DC63F;    
    height: 8vh;
  }
  
`;

export const StyledToolBar = styled(Toolbar)`

  && {
    display: flex;
    justify-content: space-between;
    align-items: center;    
    height: 8vh; 
    min-height: 60px;
  }


`;

export const PageNavWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 50px;
  font-size: 1.1rem;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
`;

export const NavItem = styled.div<NavProps>`
  display: flex;
  margin: 0px 10px;

  img{
    margin-right: 5px;
  }
  &:hover{
    cursor: pointer;
    box-sizing: content-box;
    border-bottom: 2px solid white;
  }
  
  ${(props) => props.selected && `
      border-bottom: 2px solid white;  
  `};
`;
