import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
  StyledContainer,
  StyledBasicInfoContainer,
  StyledBasicInfo,
  StyledTitleContainer,
  StyledArrow,
  StyledExpertName,
  StyledTitle,
  BackButton,
} from './style';
import goBack from '../../../assets/svg/backArr.svg';

import { SidebarsContext, SidebarsContextType } from '../../../context/sidebarsContext';

interface IProps {
  expert: ExpertType;
}

const ExpertHeader: React.FC<IProps> = (props: IProps) => {
  const sidebarsState = useContext<SidebarsContextType>(SidebarsContext);
  const history = useHistory();
  const backHandler = () => {
    sidebarsState.setIsOpenLeftSidebar(true);
    history.goBack();
  };
  return (
    <StyledContainer>
      <BackButton onClick={backHandler}>
        <img src={goBack} alt="back" />
        <span>Back</span>
      </BackButton>
      <StyledBasicInfoContainer>
        <StyledBasicInfo>
          <StyledExpertName>
            {`${props.expert.firstName} ${props.expert.lastName}`}
          </StyledExpertName>
        </StyledBasicInfo>
        <StyledTitleContainer>
          <StyledArrow />
          <StyledTitle>Expert&apos;s Profile</StyledTitle>
        </StyledTitleContainer>
      </StyledBasicInfoContainer>
    </StyledContainer>
  );
};

export default ExpertHeader;
