/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Fuse from 'fuse.js';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import {
  StyledSection,
  StyledListContainer,
  StyledListItem,
  StyledNoResultsItem,
} from './style';
import CustomSearch from '../customSearch';

interface IProps {
  optionsList: any[];
  optionKeyName: string;
  optionLabelName: string;
  entityName: string;
  handleChange: (keyValue: string | null) => void;
  placeholder?: string;
  inputStyles?: {
    maxWidth?: string;
    margin?: string;
    searchIconSize?: string;
    closeIconSize?: string;
  };
  listContainerStyles?: {
    width?: string;
    maxHeight?: string;
    backgroundColor?: string;
    borderRadius?: string;
    boxShadow?: string;
    fontFamily?: string;
    fontSize?: number;
  };
  listItemsStyles?: {
    padding?: string;
    margin?: string;
    textColor?: string;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    hoverTextColor?: string;
    firstChildPaddingTop?: string;
    firstChildPaddingBottom?: string;
    borderBottom?: string;
    width?: string;
  };
  selectedValue?: string | null;
  isDisabled?: boolean;
  scrollbarPosition?: string;
  dataTestId?: string;
}

const CustomAutocomplete = ({
  optionsList,
  optionKeyName,
  optionLabelName,
  entityName,
  handleChange,
  placeholder,
  inputStyles,
  listContainerStyles,
  listItemsStyles,
  selectedValue,
  isDisabled = false,
  scrollbarPosition = 'right',
  dataTestId
}: IProps) => {

  const [inputText, setInputText] = useState<string>('');
  const [openList, setOpenList] = useState(false);

  const getEntityName = (entityList: any[], keyValue: string | null | undefined) => {
    if (!entityList || entityList.length === 0) return `${entityName} Not Found`;
    const entity = entityList.find((entityObj: any) => entityObj[optionKeyName] === keyValue);
    if (!entity) return `${entityName} Not Found`;
    return entity[optionLabelName] as string;
  };

  const getSelectedValue = (optionsLst: any[], keyValue: string | null | undefined) => {
    if (!optionsLst || !keyValue) return '';
    return getEntityName(optionsLst, keyValue);
  };

  const inputRef = useRef<any>();
  useEffect(() => {
    setInputText(getSelectedValue(optionsList, selectedValue));
  }, [selectedValue, optionsList]);

  useEffect(() => {
    inputRef?.current?.focus();
    inputRef?.current?.select();
  }, [isDisabled]);

  const handleInputTextChange = (value: any) => {
    setOpenList(true);
    setInputText(value);
  };

  const handleInputKeyPress = (e: any) => {
    if (e.key.toLowerCase() === 'escape') {
      setOpenList(false);
      setInputText(getSelectedValue(optionsList, selectedValue));
    }
  };

  const handleListItemClick = (id: string) => {
    setOpenList(false);
    handleChange(id);
  };

  const handleClearIconClick = () => {
    setOpenList(false);
    setInputText('');
    inputRef?.current?.focus();
    inputRef?.current?.select();
    handleChange(null);
  };

  const compare = (a: any, b: any) => {
      
    if (a[optionLabelName].toLowerCase() > b[optionLabelName].toLowerCase()) return 1;

    if (a[optionLabelName].toLowerCase() < b[optionLabelName].toLowerCase()) return -1;

    return 0;
  };
  
  const getFilteredOptions = (inputString: string) => {
    if (!inputString) {
      return optionsList.filter((item, index) => index < 500).sort(compare);
    }

    const fuseOptions = {
      keys: [optionLabelName],
      includeScore: true,
      shouldSort: true,
      ignoreFieldNorm: true,
    };
    
    const fuse = new Fuse(optionsList, fuseOptions);

    const fuseResults = fuse.search(inputString);
        
    const newFuseResults = fuseResults.map((result: any) => result.item)

    return newFuseResults;
  };
  
  const renderOptionsList = () => {
    const filteredList = getFilteredOptions(inputText);

    if (!filteredList.length) {
      return (
        <StyledNoResultsItem>
          No results found
        </StyledNoResultsItem>
      )
    }

    return filteredList.map((item: any) => {
      return (
        <StyledListItem
          key={item[optionKeyName]}
          onClick={() => handleListItemClick(item[optionKeyName])}
          listItemsStyles={listItemsStyles}
          scrollbarPosition={scrollbarPosition}  
        >
          {item[optionLabelName]}
        </StyledListItem>
      );
    });
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenList(false)}>
      <StyledSection
        width={inputStyles?.maxWidth}
        margin={inputStyles?.margin}
      >
        <CustomSearch
          value={inputText}
          placeholder={placeholder}
          handleClear={handleClearIconClick}
          handleChange={handleInputTextChange}
          handleClick={() => setOpenList(true)}
          styles={{ ...inputStyles, maxWidth: '100%', margin: '0' }}
          data-testid={`autocomplete-${dataTestId}`}
        />
        <StyledListContainer
          openList={openList}
          onKeyDown={handleInputKeyPress}
          listContainerStyles={listContainerStyles}   
          scrollbarPosition={scrollbarPosition}
          className="input-options"
        >
          {renderOptionsList()}
        </StyledListContainer>   
      </StyledSection>
    </ClickAwayListener>
  );
}

export default CustomAutocomplete;
