import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;  
`;

export const StyledGuideContainer = styled.div`
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
`;

export const StyledHeading = styled(Typography)`
  && {
    font-weight: bold;
    font-family: Cairo;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    martin-top: 1rem;
  }
`;

export const StyledSubheading = styled(Typography)`
  && {
    font-weight: bold;
    font-family: Cairo;
    font-size: 15px;
  }
`;

export const StyledNoResults = styled(Typography)`
  && {
    font-family: Cairo;
    font-size: 15px;
    color: #000;
    margin-top: 1rem;
  }
`;
