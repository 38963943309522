import React, { useState } from 'react';

export interface ProspectorContextType {
  selectedOption: EnumProspectOption;
  setSelectedOption: (selectedOption: EnumProspectOption) => void;
  isPatentsDropDownOpen: boolean,
  setIsPatentsDropDownOpen: (isPatentsDropdownOpen: boolean) => void
}

export enum EnumProspectOption { TRANSFER_PRICING, PATENTS, OTHER_IP, CLASS_ACTION }

export const ProspectorContext = React.createContext<ProspectorContextType>({
  selectedOption: EnumProspectOption.TRANSFER_PRICING,
  setSelectedOption: (selectedOption: EnumProspectOption) => selectedOption,
  isPatentsDropDownOpen: false,
  setIsPatentsDropDownOpen: (isPatentsDropdownOpen: boolean) => isPatentsDropdownOpen,
});

interface IProps {
  children: React.ReactNode;
}

const ProspectorContextProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<EnumProspectOption>(EnumProspectOption.TRANSFER_PRICING);
  const [isPatentsDropDownOpen, setIsPatentsDropDownOpen] = useState<boolean>(false);

  const contextValue = {
    selectedOption,
    setSelectedOption,
    isPatentsDropDownOpen,
    setIsPatentsDropDownOpen,
  };

  return (
    <ProspectorContext.Provider value={contextValue}>
      {children}
    </ProspectorContext.Provider>
  );
};

export default ProspectorContextProvider;
